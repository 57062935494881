import {
  Button,
  CardContent,
  FormControl,
  Grid,
  OutlinedInput,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import { TeleKardiaAPI } from 'Axios/axios';
import { getRegion, getToken } from 'auth/AuthUtilities';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handletoggleHeader } from 'store/actions';
import { headerText } from '../constant/Constant';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';

const Root = styled(Grid)(({ theme }) => ({
  width: '100%',
  '& .MuiCardContent-root': {
    border: '1px solid lightgray !important',
    borderRadius: '5px !important',
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    maxWidth: '50px',
    maxHeight: '40px',
  },
}));
const NotificationSettings = () => {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    adminDNDTimePeriodInHrs: '',
    adminInDND: false,

    providerDNDTimePeriodInHrs: '',
    providerInDND: false,

    providerFirstReminderInMins: '',
    providerFirstReminderEnabled: true,

    providerSecondReminderInMins: '',
    providerSecondReminderEnabled: true,
  });

  const teamId = useSelector((state) => state.user.team.id);
  const userId = useSelector((state) => state.user.profile.id);

  useEffect(() => {
    dispatch(handletoggleHeader(headerText.TAB4, true));
    getNotificationSettings();
  }, []);

  const handleChange = (e) => {
    setInitialValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };

  const getNotificationSettings = () => {
    const regionToken= getRegion();
    regionToken && regionToken ==="us" && TeleKardiaAPI.get(`api/v1/teams/${teamId}/admin/${userId}/notification-settings`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setInitialValues(res.data.result);
      })
      .catch((err) => console.log(err));
  };

  const submitChange = () => {
    const regionToken= getRegion()
    let data = {
      ...initialValues,
      teamId,
    };
    regionToken  && regionToken ==="us" && TeleKardiaAPI.post(`api/v1/teams/${teamId}/admin/${userId}/notification-settings`, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  return (
    <SectionMain
      headerElement={{
        headerText: 'Notification Settings',
        // helperText: '* Required',
      }}
      bodyElement={[
        {
          childElements: (
            <Root container spacing={1}>
              <Grid item xs={6}>
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary" gutterBottom>
                      Role: Admin
                    </Typography>
                    <Switch
                      checked={initialValues.adminInDND}
                      name="adminInDND"
                      onChange={handleChange}
                      size="small"
                    />
                  </div>
                  <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
                    Type: Do not disturb
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }} color="text.secondary">
                    Notifications will be silenced before this time period
                  </Typography>
                  <hr />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <FormControl
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      <OutlinedInput
                        value={initialValues.adminDNDTimePeriodInHrs}
                        name={'adminDNDTimePeriodInHrs'}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Typography sx={{ m: 1 }} variant="body1" color="text.secondary">
                      hours prior to appointment
                    </Typography>
                  </div>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary" gutterBottom>
                      Role: Provider
                    </Typography>
                    <Switch
                      checked={initialValues.providerInDND}
                      name="providerInDND"
                      onChange={handleChange}
                      size="small"
                    />
                  </div>
                  <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
                    Type: Do not disturb
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }} color="text.secondary">
                    Notifications will be silenced before this time period
                  </Typography>
                  <hr />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <FormControl
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      <OutlinedInput
                        value={initialValues.providerDNDTimePeriodInHrs}
                        name="providerDNDTimePeriodInHrs"
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Typography sx={{ m: 1 }} variant="body1" color="text.secondary">
                      hours prior to appointment
                    </Typography>
                  </div>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary" gutterBottom>
                      Role: Provider
                    </Typography>
                    <Switch
                      checked={initialValues.providerFirstReminderEnabled}
                      name="providerFirstReminderEnabled"
                      onChange={handleChange}
                      size="small"
                    />
                  </div>
                  <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
                    Type: Upcoming appointment Reminder 1
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }} color="text.secondary">
                    A reminder will be sent at this time
                  </Typography>
                  <hr />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <FormControl
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      <OutlinedInput
                        value={initialValues.providerFirstReminderInMins}
                        name="providerFirstReminderInMins"
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Typography sx={{ m: 1 }} variant="body1" color="text.secondary">
                      minutes prior to appointment
                    </Typography>
                  </div>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary" gutterBottom>
                      Role: Provider
                    </Typography>
                    <Switch
                      checked={initialValues.providerSecondReminderEnabled}
                      name="providerSecondReminderEnabled"
                      onChange={handleChange}
                      size="small"
                    />
                  </div>
                  <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
                    Type: Upcoming appointment Reminder 2
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }} color="text.secondary">
                    A reminder will be sent at this time
                  </Typography>
                  <hr />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <FormControl
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      <OutlinedInput
                        value={initialValues.providerSecondReminderInMins}
                        name="providerSecondReminderInMins"
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Typography sx={{ m: 1 }} variant="body1" color="text.secondary">
                      minutes prior to appointment
                    </Typography>
                  </div>
                </CardContent>
              </Grid>
            </Root>
          ),
        },
      ]}
      footerElement={{
        buttonInputProps: (
          <Button variant="contained" onClick={submitChange}>
            Save Change
          </Button>
        ),
      }}
    />
  );
};

export default NotificationSettings;
