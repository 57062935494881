import React from 'react';
import Patientinformationcustom from './Patientinformationcustom';
import { PATIENT_DETAILS, kgToLbs } from './PreviewreportConst';
import { Box } from '@mui/material';
import { getAge } from 'utilities/Utilities';

/**
 * PreviewPatientInformation Component
 * This component displays Patient Details,including
 * SEX, AGE, BMI,HEIGHT,WEIGHT
 * @param {reportDetails} param0 
 * @returns respective patient information
 */

const PreviewPatientInformation = ({ reportDetails }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
      <Patientinformationcustom
        headertitle={PATIENT_DETAILS.SEX}
        title={reportDetails?.patient?.gender?.charAt(0)}
      />
      <Patientinformationcustom
        headertitle={PATIENT_DETAILS.AGE}
        title={getAge(reportDetails?.patient?.dob)}
      />
      <Patientinformationcustom
        headertitle={PATIENT_DETAILS.BMI}
        title={reportDetails?.patient?.bmi ? reportDetails?.patient?.bmi : '--'}
      />
      <Patientinformationcustom
        headertitle={PATIENT_DETAILS.HEIGHT}
        title={reportDetails?.patient?.height ? reportDetails?.patient?.height.toFixed(2) : '--'}
      />
      <Patientinformationcustom
        headertitle={PATIENT_DETAILS.WEIGHT}
        title={reportDetails?.patient?.weight ? kgToLbs(reportDetails?.patient?.weight) : '--'}
      />
    </Box>
  );
};

export default PreviewPatientInformation;
