import React from 'react';
import { Typography } from '@mui/material';


  export const SectionHeader = ({ title, textSize ,margin }) => {
    return (
        <Typography  variant="body1" style={{ fontSize: textSize, fontWeight: "bold", margin:margin }}>
     {title}
   </Typography>
    );
  };

  export const ContentDetails=({headertitle, textSize , textcolor})=>{
    return(
        <Typography variant='h2' style={{ fontSize: textSize, color:textcolor }}>
        {headertitle}
      </Typography>
    )
  }

  export const ColourCodes={
      lightGrey:'1px solid #B5BCC1',
      DarkShadeCyan:'#536063'

  }


 