import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router";

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import axios from "axios";
import Skeleton from "@mui/lab/Skeleton";
import { SectionMain } from "layouts/ScreenLayout/sections/SectionMain";
import History from "./History";

import { GoAPI, GoAPIEU } from "Axios/axios";
import * as API from "Services/API/actions";
import { getTimezoneName } from "utilities/Utilities";
import TeamAlert from "components/Shared/Alert/TeamAlert";
import {
  AppointmentStatusTypes,
  sessionCompletedMethod,
} from "utilities/Constants";
import { getRegion, getToken } from "auth/AuthUtilities";
import {
  getEKGAlgorithmDeterminationDisplayText,
  getEKGAlgorithmDeterminationStackFilter,
} from "constants/recordingMaps";
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from "constants/app";
import ThisSessionEcg from "./ThisSessionEcg";
import {
  getPatientFileData,
  setInitialPatientFileData,
} from "../Metrics/PatientFile/module";
import { getPatient, needToBustCache } from "store/reducers/app";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SectionHeader from "layouts/ScreenLayout/sections/SectionHeader";
import BloodPressureGraph from "../Metrics/PatientFile/BloodPressureGraph";
import BlooPressureRecordingDetails from "../Metrics/PatientFile/BlooPressureRecordingDetails";
import CoachThisSession from "./CoachThisSession";
import DoctorThisSession from "./DoctorThisSession";
import {
  Call_Status,
  HeaderDetails,
  Patient_Status,
} from "./ThisSessionConstants";


//Styling Section
const SessionContainer = styled("div")(({ theme }) => ({
  fontFamily: "Work sans",
  width: "100%",
  "& .MuiFormControl-root": {
    margin: "10px 0",
  },
  "& .MuiTypography-body1": {
    fontWeight: "500 !important",
    fontFamily: "Work Sans !important",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px !important",
  },
  "& .MuiTypography-body2": {
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "10px",
    fontWeight: "400 !important",
    fontFamily: "Work Sans !important",
    fontSize: "14px !important",
  },
  "& .MuiSelect-root": {
    fontFamily: "Work Sans",
  },
  "& .MuiSelect-select": {
    "& :focus": {
      backgroundColor: "white",
    },
  },
  "& .Mui-selected": {
    backgroundColor: "#F4FAF8 !important",
    // '&:hover': {
    //   backgroundColor: 'green !important',
    // },
  },
  "& .MuiMenuItem-root": {
    fontFamily: "Work Sans !important",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #484850",
  },

  // theme.spacing(2)
}));

function ThisSession({
  setIsCompleted,
  setPreviewReportModalOpen,
  isCompleted,
  setReportSent,
  reportSent,
  patient: patientDataSource,

  selectedBloodPressureRecordingId,
  bloodPressureRecordingIds,
  refreshClicked,
  // patients,
  dataLastUpdated,
  AvgNumverrecordings,

  
}) {
  const { memberId, appointmentId, postSession} = useParams();
  const history = useHistory();
 

  //==== ====== local state ============//
  const [data, setData] = useState({});
  const [ekgData, setEKGData] = useState(null);
  const [loading, setloading] = useState(false);
  const [patientData, setpatientData] = useState(null);
  const [isAdminDisabled, setIsAdminDisabled] = useState(false);
  const [state, setState] = useState({
    status: 0,
    method: 0,
    ekgInterpretationId: "",
    ekgInterpretation: "",
    ekgInterpretationText: "",
    providerNotes: "",
    consultationSummary: "",
    recommendedAction: "",
    followup: "",
  });
  const [showAlert, setShowAlert] = useState({
    data: "",
    error: false,
  });

  // ========= local state end =========//

  //==== ====== redux state ============//
  const { isCallOver } = useSelector((state) => state.adhocCall);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const permissions = useSelector((state) => {
    return state.user.profile.permissions;
  });
  // const Totalrecordings = useSelector((state)=>)
  // const patientDetails = useSelector((state) => state.appointments.patientDetails);
  const patientsdatainformation = useSelector(
    (state) => state.ekgReview.patientInfo,
  );
  const patients = useSelector((state) => state.app.dataSources.patients);
  const bloodPressures = useSelector(
    (state) => state.app.dataSources.bloodPressures,
  );
  const selectedPatientId = useSelector(
    (state) => state.patientFile.selectedPatientId,
  );

  const totalNumberofRecordings=useSelector( (state) => state.app.dataSources.number_of_records)
  const avgSystolicRecordings= useSelector((state) => state.app.dataSources.AvgSys)
  const avgDialosticRecordings= useSelector((state) => state.app.dataSources.AvgDys)
  const crpReportId=useSelector((state)=> state.app.dataSources.crpReportIds)
  const deviceTypes= useSelector((state)=> state.app.dataSources.deviceTypes)
  const location = useLocation();
  const dispatch = useDispatch();
  const isregionupdated= useSelector((state) => state.user.isregionupdated)
  const regionToken=getRegion()

  //==== ====== redux state end ============//

  let totalRecordings= totalNumberofRecordings?.data?.existingrecordingsById && Object.values(totalNumberofRecordings?.data?.existingrecordingsById)[0].avgrecordings;
  let totalSysValue=avgSystolicRecordings?.data?.existingSystolicById && Object.values(avgSystolicRecordings?.data?.existingSystolicById)[0].avgSysValue;
  let totalDysValue=avgDialosticRecordings?.data?.existingDialosticById && Object.values(avgDialosticRecordings?.data?.existingDialosticById)[0].avgDysValue;
  const aiDeterminationDetails = getEKGAlgorithmDeterminationDisplayText(
    patientsdatainformation.interpretation,
    patientsdatainformation.algorithmPackage,
    patientsdatainformation.heartRate,
  );
  const interpretation = getEKGAlgorithmDeterminationStackFilter(
    patientsdatainformation.interpretation,
    patientsdatainformation.algorithmPackage,
    patientsdatainformation.heartRate,
  );

  const width = 1180 - 20 * 2;


 


  useEffect(() => {
    loadAction();
  }, [isCallOver]);

  const loadAction = useCallback(async () => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    axios
      .get(
        `${
          process.env.REACT_APP_TELEKARDIA_API_BASE
        }api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
      .then((res) => {
        setpatientData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Triggring the patientdetails 
    (selectedPatientId && patientData?.patient?.userType) && dispatch(getPatient(selectedPatientId,patientData?.patient))
  }, [selectedPatientId,patientData]);

  useEffect(() => {
    if (patientData != null) {
      setIsAdminDisabled(
        permissions?.isAdmin &&
          (patientData.patientAppointment?.appointmentStatusId ==
            AppointmentStatusTypes.Scheduled ||
            patientData.patientAppointment?.appointmentStatusId ==
              AppointmentStatusTypes.MemberInWaitingRoom ||
            patientData.patientAppointment?.appointmentStatusId ==
              AppointmentStatusTypes.InProgress ||
            patientData.patientAppointment?.appointmentStatusId ==
              AppointmentStatusTypes.Encounter_Summary_Preparation),
      );
      setState((prev) => ({
        ...prev,
        status: patientData.patientAppointment?.appointmentStatusId,
        method:
          patientData.patientAppointment?.appointmentStatusId ==
          AppointmentStatusTypes.Completed
            ? sessionCompletedMethod[
                patientData.patientAppointment?.appointmentCompletionMethod
              ]
            : patientData.patientAppointment?.appointmentStatusId ==
                AppointmentStatusTypes.closed &&
              patientData.patientAppointment?.closedReasonType,
      }));
      setData(patientData);

      //trigger loadEkg api call only user is a doctor type and has ekg attached to the session.
      if (
        patientData?.ekgResults &&
        patientData?.ekgResults?.length > 0 &&
        patientData?.ekgResults[0]?.ekgRecordId !== ""
      )
        loadEkgAction(patientData?.ekgResults[0]?.ekgRecordId);
      if (
        patientData?.patientAppointment?.appointmentStatusId ==
        AppointmentStatusTypes.Encounter_Summary_Preparation
      )
        setReportSent(false);
    }
    API.getAppointmentNotes(teamId, clinicianId, appointmentId).subscribe(
      (res) => {
        if (res.data.appointmentNote) {
          setState((prev) => ({
            ...prev,
            providerNotes: res.data.appointmentNote,
          }));
        }
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error),
    );
  }, [patientData]);

  const loadEkgAction = useCallback((recordingid) => {
    setloading(true);
    let apiInstance= (regionToken === "us" || isregionupdated === "us" )? GoAPI : GoAPIEU;
    let samps = apiInstance.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = apiInstance.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
          setEKGData({ ...responses[0].data, ...responses[1].data });
          setloading(false);
        }),
      )
      .catch((error) => {
        console.log(error);
        setEKGData(null);
        setloading(false);
      });
  }, []);

  // all get call for form fields
  useEffect(() => {
    dispatch(setInitialPatientFileData(memberId, location));
    dispatch(getPatientFileData(needToBustCache(dataLastUpdated), memberId));
    let getHeartConditions,
      getHeartId,
      getDuration,
      summaryNotes,
      recommendedAction;
    if (appointmentId != " ") {
      getHeartConditions = API.heartConditions().subscribe(
        (res) => {
          if (res.data.result != null) {
            let conditions = [
              {
                id: 0,
                heartCondition: "Select EKG Interpretation",
                heartConditionAdditionalText: "",
              },
              ...res.data.result,
            ];
            setState((prev) => ({
              ...prev,
              ekgInterpretation: conditions,
            }));
          }
          // let heartConditions = res.data.result.sort(function (a, b) {
          //   return a.id - b.id;
          // });
        },
        (error) => {
          console.log(error);
        },
      );
      getDuration = API.getdurationText(
        teamId,
        clinicianId,
        appointmentId,
      ).subscribe(
        (res) => {
          res.data?.result !== null
            ? setState((prev) => ({
                ...prev,
                followup: res.data.result,
              }))
            : setState((prev) => ({
                ...prev,
                followup: "No Follow-up",
              }));
        },
        (error) => {
          console.log(error);
        },
      );
      summaryNotes = API.getconsultationSummaryNotes(
        teamId,
        clinicianId,
        appointmentId,
      ).subscribe(
        (res) => {
          res.data.result !== null &&
            setState((prev) => ({
              ...prev,
              consultationSummary: res.data.result,
            }));
        },
        (error) => console.log(error),
      );
      recommendedAction = API.gettreatmentPlan(
        teamId,
        clinicianId,
        appointmentId,
      ).subscribe(
        (res) => {
          res.data.result !== null &&
            setState((prev) => ({
              ...prev,
              recommendedAction: res.data.result,
            }));
        },
        (error) => console.log(error),
      );
      getHeartId = API.getheartConditionId(
        teamId,
        clinicianId,
        appointmentId,
      ).subscribe(
        (res) => {
          res.data.result !== null
            ? setState((prev) => ({
                ...prev,
                ekgInterpretationId: res.data.result,
              }))
            : setState((prev) => ({
                ...prev,
                ekgInterpretationId: 0,
              }));
        },
        (error) => console.log(error),
      );
    }
    return () => {
      if (getHeartConditions) getHeartConditions.unsubscribe();
      else if (getHeartId) getHeartId.unsubscribe();
      else if (getDuration) getDuration.unsubscribe();
      else if (summaryNotes) summaryNotes.unsubscribe();
      else if (recommendedAction) recommendedAction.unsubscribe();
    };
  }, [appointmentId]);

  //ekg interpretation get value rendering logic
  useEffect(() => {
    if (state.ekgInterpretation != "" && state.ekgInterpretationId != "") {
      if (state.ekgInterpretationId == 23) {
        getOverReadReportMethod();
      } else {
        setState((prev) => ({
          ...prev,
          ekgInterpretationText: state.ekgInterpretation.filter(
            (item) => state.ekgInterpretationId === item.id,
          )[0]["heartConditionAdditionalText"],
        }));
      }
    }
  }, [state.ekgInterpretationId, state.ekgInterpretation]);

  useEffect(() => {
    if (userType === AUTH_TYPE.DOCTOR) {
      if (
        state.consultationSummary.length == 0 ||
        state.method == 0 ||
        state.method == 5 ||
        (ekgData != null && state.ekgInterpretationText === null)
      ) {
        setIsCompleted(false);
      } else setIsCompleted(true);
    } else {
      if (
        state.providerNotes.length == 0 ||
        state.method == 5 ||
        state.method == 0
      ) {
        setIsCompleted(false);
      } else setIsCompleted(true);
    }
  }, [state]);

  const getOverReadReportMethod = () => {
    API.getOverReadReport(teamId, clinicianId, appointmentId).subscribe(
      (res) => {
        if (res.data.result !== null) {
          setState((prev) => ({
            ...prev,
            ekgInterpretationText: res.data.result,
          }));
        }
      },
      (error) => console.log(error),
    );
  };

  //form field value change logic
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: event.target.value,
    }));

    if (name === "status") {
      setState((prev) => ({
        ...prev,
        method: 0,
      }));
      setIsCompleted(false);
    } else if (name === "method" && value != 0) {
      setIsCompleted(true);
    } else if (name === "ekgInterpretationId") {
      //trigger PUT api with both EKG interpretationId and its text
      saveEkgHeartId(
        value,
        state.ekgInterpretation.filter((item) => value === item.id)[0][
          "heartConditionAdditionalText"
        ],
      );
      setState((prev) => ({
        ...prev,
        ekgInterpretationText: state.ekgInterpretation.filter(
          (item) => value === item.id,
        )[0]["heartConditionAdditionalText"],
      }));
    } else if (name === "providerNotes") saveMyNotesAsync(value);
    else if (name === "recommendedAction") saveRecommendedAction(value);
    else if (name === "followup") saveFollowup(value);
    else if (name === "consultationSummary") saveConsultationSummary(value);
    else if (name === "ekgInterpretationText") saveEKGInterpretationText(value);
  };

  const ekgTextTimer = useRef(null);
  const saveEKGInterpretationText = (text) => {
    clearTimeout(ekgTextTimer.current);
    ekgTextTimer.current = setTimeout(() => {
      API.setOverReadReport(teamId, clinicianId, appointmentId, text).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
    }, 1500);
  };
  const timer1 = useRef(null);
  //save consultation summary
  const saveConsultationSummary = (note) => {
    clearTimeout(timer1.current);
    timer1.current = setTimeout(() => {
      API.setconsultationSummaryNotes(
        teamId,
        clinicianId,
        appointmentId,
        note,
      ).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
    }, 3000);
  };
  //set heartId
  const saveEkgHeartId = (ekgInterpretationId, ekgInterpretationText) => {
    API.setheartConditionId(
      teamId,
      clinicianId,
      appointmentId,
      ekgInterpretationId,
    ).subscribe(
      // API that triggers ekgInterpretationText, should be after success of ekgInterpretationId API.
      (res) =>
        res.data.success && saveEKGInterpretationText(ekgInterpretationText),
      (error) => console.log(error),
    );
  };
  //set recommendedAction
  const timer2 = useRef(null);
  const saveRecommendedAction = (note) => {
    clearTimeout(timer2.current);
    timer2.current = setTimeout(() => {
      API.settreatmentPlan(teamId, clinicianId, appointmentId, note).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
    }, 3000);
  };
  //setFollowup
  const saveFollowup = (id) => {
    API.setdurationText(teamId, clinicianId, appointmentId, id).subscribe(
      (res) => {
        // do nothing
      },
      (error) => console.log(error),
    );
  };
  //post calls of all form fields
  const timer3 = useRef(null);
  const saveMyNotesAsync = (note) => {
    clearTimeout(timer3.current);
    timer3.current = setTimeout(() => {
      API.setAppointmentNotes(
        teamId,
        clinicianId,
        appointmentId,
        note,
        // note),
      ).subscribe(
        (res) => {},
        (error) => {
          console.log("error", error);
        },
      );
    }, 3000);
  };

  //==== session report preview and session completion logic change ====//
  const previewReport = () => {
    setPreviewReportModalOpen(true);
  };

  const completeReport = () => {
    let body = {
      appointmentStatus: state.status,
      appointmentCompletionMethod: state.method,
      consultationSummaryNotes: state.consultationSummary,
      followup: state.followup,
      heartConditionId: ekgData !== null ? state.ekgInterpretationId : 23,
      overReadReportNotes: ekgData !== null ? state.ekgInterpretationText : '',
      patientId: memberId,
      treatmentPlan: state.recommendedAction,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };
    state.status == 10 && delete body.appointmentCompletionMethod;
    API.postConsultation(teamId, clinicianId, appointmentId, body).subscribe(
      (res) => {
        if (Object.keys(res.data).includes('detailed_error')) {
          setReportSent(false); //to set promt if session report is sent.
          setShowAlert({
            data: res.data.detailed_error,
            error: true,
          });
          setTimeout(() => {
            setShowAlert({ data: '', type: null });
          }, 5000);
        } else {
          setReportSent(true); //to set promt if session report is sent.
          setShowAlert({
            data: 'You’re all set” - “Session notes successfully completed',
            error: false,
          });
          setTimeout(() => {
            setShowAlert({ data: '', type: null });
            // history.goBack();
            if (userType === AUTH_TYPE.COACH)
              history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${memberId}`);
            else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${memberId}`);
          }, 5000);
        }
      },
      (error) => {
        console.error('Unable to process this request due to internal server error.', error);
        setShowAlert({
          data: 'Unable to process this request due to internal server error',
          error: true,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
      },
    );
  };

  const triggerChangeStatusAPI = () => {
    if (state.status == AppointmentStatusTypes.closed && state.method != 0) {
      let body = {
        appointmentReasonType: state.method,
        appointmentStatus: state.status,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
      };

      API.updateAppointmentStatus(
        teamId,
        clinicianId,
        appointmentId,
        body,
      ).subscribe(
        (res) => {
          if (Object.keys(res.data).includes("detailed_error")) {
            setShowAlert({
              data: res.data.detailed_error,
              error: true,
            });
            setTimeout(() => {
              setShowAlert({ data: "", type: null });
            }, 5000);
          } else {
            completeReport();
            setShowAlert({
              data: "Session status changed successfully",
              error: false,
            });
            setTimeout(() => {
              setShowAlert({ data: "", type: null });
            }, 5000);
          }
        },
        (error) => {
          setShowAlert({
            data: error,
            error: true,
          });
        },
      );
    } else completeReport();
  };

  //blood pressure data seggeration Logic
  let patient;
  if (patients && patients?.data && patients?.data?.byId[selectedPatientId]) {
    patient = patients?.data.byId[selectedPatientId];
    // cleanProfile = cleanPatientProfile(patient);
  }

  let completeBloodPressureRecordings = [];
  let bloodPressureRecordings = [];
  let selectedBloodPressureRecording;
  if (
    patient &&
    patient.bloodPressure &&
    bloodPressures.data &&
    !refreshClicked
  ) {
    selectedBloodPressureRecording = selectedBloodPressureRecordingId
      ? bloodPressures.data.byId[selectedBloodPressureRecordingId]
      : null;
    completeBloodPressureRecordings = patient.bloodPressure?.map(
      (id) => bloodPressures.data.byId[id],
    );
    // bloodPressureRecordings = bloodPressureRecordingIds.map((id) => bloodPressures.data.byId[id]);
  }

  //======= end =======//



  return (
    <SessionContainer>
      {Object.keys(data).length > 0 &&
        data?.patientAppointment?.appointmentStatusId ===
          AppointmentStatusTypes.Encounter_Summary_Preparation &&
        !reportSent &&
        postSession &&
        !permissions.isAdmin && (
          <Prompt
            when={true}
            message={() =>
              "Session Not Complete - Click “Complete Session” to finalize documentation"
            }
          />
        )}

      {/* Common Section for both Doctor and Coach */}
      {userType === AUTH_TYPE.COACH && (
        //placed the cotent in coach this session tab, This Tab is common for all the type of Coach visits
        <CoachThisSession data={data} />
      )}

      {userType === AUTH_TYPE.DOCTOR && (
        //placed the content in doctor this session tab  , This Tab is common for all the type of Doctor visits
        <DoctorThisSession data={data} />
      )}
      {/* End of Header common Section */}

      {/* This Section loads when Visittype is EKG and when EKG data is not null */}
      {loading ? (
        <>
          <Skeleton
            animation="wave"
            height={20}
            width="50%"
            style={{ margin: 20 }}
          />
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ margin: 20 }}
          />
        </>
      ) : (
        (userType === AUTH_TYPE.DOCTOR && ekgData != null && data.visitType === "EKG") &&
         (
          <ThisSessionEcg
            ekgData={ekgData}
            state={state}
            handleChange={handleChange}
            isAdminDisabled={isAdminDisabled}
            crpReportId={crpReportId}
            deviceTypes={deviceTypes}
          />
        )
      )}

      {/* This section loads when Visittype is Other, Medical,Blood, Lifestyle */}
      {loading ? (
        <>
          <Skeleton
            animation="wave"
            height={20}
            width="50%"
            style={{ margin: 20 }}
          />
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ margin: 20 }}
          />
        </>
      ) : (
        userType === AUTH_TYPE.DOCTOR &&
        (data.visitType === "OTHER" ||
          data.visitType === "MEDICAL" ||
          data.visitType === "BLOOD" ||
          data.visitType === "LIFESTYLE") && (
          <SectionMain
            bodyElement={[
              {
                childElements: (
                  <>
                    <SectionHeader
                      sx={{ padding: "16px !important" }}
                      headerText={HeaderDetails.BLODD_PRESSURE_HISTORY}
                      childElements={
                        <Stack direction={"row"} columnGap={1}>
                          <BlooPressureRecordingDetails
                            lable={"Avg BP"}
                            value={`${totalSysValue ? Math.round(totalSysValue) : '--'} / ${totalDysValue ? Math.round(totalDysValue) : '--'}`}
                          />
                        </Stack>
                      }
                    />

                    <Stack width={"inherit !important"}>
                      <BloodPressureGraph
                        completeRecordings={completeBloodPressureRecordings}
                        width={width}
                        totalRecordings={totalRecordings}
                      />
                    </Stack>
                  </>
                ),
              },
            ]}
          />
        )
      )}

      {/* This section loads when Visittype is clinician */}
      {loading ? (
        <>
          <Skeleton
            animation="wave"
            height={20}
            width="50%"
            style={{ margin: 20 }}
          />
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ margin: 20 }}
          />
        </>
      ) : (
        userType === AUTH_TYPE.DOCTOR &&
        data.visitType === "CLINICIAN" &&  (
          <History
            startDateofAppointment={
              data?.patientAppointment?.scheduledStartTime
            }
            patientId={data.patient.patientId}
            overreadreportId={data.patientAppointment.overreadResults[0].crEkgRecordId}
          />
        )
      )}

      {/* This is not sharing with patient, this is provider notes when Doctor or coach can save it */}
      {(userType === AUTH_TYPE.COACH || userType === AUTH_TYPE.DOCTOR) && (
        <SectionMain
          headerElement={{
            headerText: HeaderDetails.SESSION_NOTES,
            subheaderText: HeaderDetails.SESSION_DETAILS,
          }}
          bodyElement={[
            {
              childElements: (
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Provider Notes"
                  name="providerNotes"
                  placeholder="Add your notes for an appointment here."
                  value={state.providerNotes}
                  multiline
                  onChange={handleChange}
                  disabled={isAdminDisabled}
                  rows={4}
                  // defaultValue="Add your notes for an appointment here. "
                />
              ),
            },
          ]}
        />
      )}

      {/* Common section for Both Doctor and Coach */}
      <SectionMain
        headerElement={{
          headerText: HeaderDetails.REPORT_NOTES,
          subheaderText: HeaderDetails.REPORT_DETAILS,
          helperText: `Required*`,
        }}
        bodyElement={[
          {
            childElements: (
              <>
                <TextField
                  fullWidth
                  label="Consultation Summary"
                  name="consultationSummary"
                  placeholder="Write your consultant summary here. This will be sent to the patient"
                  value={state.consultationSummary}
                  multiline
                  rows={4}
                  disabled={isAdminDisabled}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Recommended Action"
                  name="recommendedAction"
                  placeholder="Write your recommended actions here. This will be sent to the patient"
                  value={state.recommendedAction}
                  multiline
                  onChange={handleChange}
                  disabled={isAdminDisabled}
                  rows={4}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Follow-up
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="followup"
                    value={state.followup}
                    label="Follow-up"
                    disabled={isAdminDisabled}
                    onChange={handleChange}
                  >
                    <MenuItem value={"No Follow-up"}>
                      {Call_Status.NO_FOLLOW_UP}
                    </MenuItem>
                    <MenuItem value={"3 Days"}>
                      {Call_Status.THREE_DAYS}
                    </MenuItem>
                    <MenuItem value={"1 Week"}>{Call_Status.ONE_WEEK}</MenuItem>
                    <MenuItem value={"2 Weeks"}>
                      {Call_Status.TWO_WEEK}
                    </MenuItem>
                    <MenuItem value={"1 Month"}>
                      {Call_Status.ONE_MONTH}
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            ),
          },
        ]}
      />

      {showAlert.data != "" && (
        <TeamAlert title={showAlert.data} error={showAlert.error} />
      )}

      <SectionMain
        headerElement={{
          headerText: `Session Status`,
          helperText: `Required*`,
        }}
        bodyElement={[
          {
            childElements: (
              <>
                <FormControl>
                  <RadioGroup
                    row
                    name="status"
                    value={state.status}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      disabled={
                        userType === AUTH_TYPE.DOCTOR
                          ? state.consultationSummary === "" ||
                            (((userType === AUTH_TYPE.DOCTOR &&
                              data.visitType === "EKG") ||
                              data.visitType === "") &&
                              ekgData != null &&
                              state.ekgInterpretationText === "") ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 3
                          : state.providerNotes === "" ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 3
                      }
                      value={4}
                      control={<Radio />}
                      label="Completed"
                    />
                    <FormControlLabel
                      disabled={
                        userType === AUTH_TYPE.DOCTOR
                          ? state.consultationSummary === "" ||
                            (((userType === AUTH_TYPE.DOCTOR &&
                              data.visitType === "EKG") ||
                              data.visitType === "") &&
                              ekgData != null &&
                              state.ekgInterpretationText === "") ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 3
                          : state.providerNotes === "" ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment
                              ?.appointmentStatusId == 3
                      }
                      value={10}
                      control={<Radio />}
                      label="Incomplete"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                {state.status == AppointmentStatusTypes.Completed && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Method
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="method"
                      value={state.method}
                      label="Method"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>{Call_Status.VIDEO_CALL}</MenuItem>
                      <MenuItem value={2}>{Call_Status.AUDIO_CALL}</MenuItem>
                      <MenuItem value={3}>{Call_Status.BACKUP_CALL} </MenuItem>
                      <MenuItem value={4}>{Call_Status.OTHER}</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {state.status == AppointmentStatusTypes.closed && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Method
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="method"
                      value={state.method}
                      label="Method"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>
                        {Patient_Status.PATIENT_NO_SHOW}
                      </MenuItem>
                      <MenuItem value={2}>
                        {Patient_Status.MEMBER_TECHNICAL_ISSUE}
                      </MenuItem>
                      <MenuItem value={3}>
                        {Patient_Status.PROVIDER_TECHNICAL_ISSUE}
                      </MenuItem>
                      <MenuItem value={4}>
                        {Patient_Status.MEMBER_DECLINED}
                      </MenuItem>
                      <MenuItem disabled={true} value={5}>
                        {Patient_Status.SYSTEM_CLOSED}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </>
            ),
          },
        ]}
        footerElement={{
          buttonInputProps: (
            <>
              {userType === AUTH_TYPE.DOCTOR && (
                <Button variant="outlined" 
                sx={{
                      height: "fit-content",
                      borderColor: "#6387C5",
                    }}
                disabled>
               Preview Report
              </Button>
                
              )}
              <Button
                variant="contained"
                sx={{
                  height: "fit-content",
                  backgroundColor: "#6387C5",
                }}
                disabled={
                  !isCompleted ||
                  (permissions?.isAdmin &&
                    patientData?.patientAppointment?.appointmentStatusId == 7)
                }
                onClick={triggerChangeStatusAPI}
              >
                Complete Session
              </Button>
            </>
          ),
        }}
      />
    </SessionContainer>
  );
}

export default ThisSession;
