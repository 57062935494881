// import axios from 'axios';
import { getToken } from 'auth/AuthUtilities';
import { EscalationAPI, IntakeKardiaAPI, KccGoAPI, KccGoAPIEU, TeleKardiaAPI } from '../../Axios/axios';
import { getTimezoneName } from 'utilities/Utilities';
import * as actionTypes from './actionTypes';
import { ALERT_NOTE } from 'constants/app';
import { USER_TEAM_TYPE } from 'utilities/Constants';

const getMemberProfileStart = () => ({
  type: actionTypes.GET_MP_LOADING,
});

const getMemberProfileSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_SUCCESS,
  payload: data,
});

const getMemberProfileFail = (error) => ({
  type: actionTypes.GET_MP_FAIL,
  payload: error,
});

const getMpHealthLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_LOADING,
});
const getMpHealthFail = (error) => ({
  type: actionTypes.GET_MP_HEALTH_FAIL,
  payload: error,
});
const getMpHealthSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_SUCCESS,
  payload: data,
});

//PCP- fetch data
const getMpHealthPcpLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_PCP_LOADING,
});
const getMpHealthPcpSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_SUCCESS,
  payload: data,
});
const getMpHealthPcpFail = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_FAIL,
  payload: data,
});
//PCP- fetch meta data
const getMpHealthPcpMetadataLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_PCP_METADATA_LOADING,
});
const getMpHealthPcpMetadataSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_METADATA_SUCCESS,
  payload: data,
});
const getMpHealthPcpMetadataFail = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_METADATA_FAIL,
  payload: data,
});

//PCP - update data
const getMpHealthPcpUpdateLoading = () => ({
  type: actionTypes.GET_MP_HEALTH_PCP_UPDATE_LOADING,
});
const getMpHealthPcpUpdateSuccess = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_UPDATE_SUCCESS,
  payload: data,
});
const getMpHealthPcpUpdateFail = (data) => ({
  type: actionTypes.GET_MP_HEALTH_PCP_UPDATE_FAIL,
  payload: data,
});

const getMpProgramProgressStart = () => ({
  type: actionTypes.GET_MP_PROGRAM_PP_LOADING,
});
const getMpProgramProgressSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_PP_SUCCESS,
  payload: data,
});

const getMpProgramFocusFail = (error) => ({
  type: actionTypes.GET_MP_PROGRAM_PF_FAIL,
  payload: error,
});
const getMpProgramFocusStart = () => ({
  type: actionTypes.GET_MP_PROGRAM_PF_LOADING,
});
const getMpProgramFocusSuccess = (data) => ({
  type: actionTypes.GET_MP_PROGRAM_PF_SUCCESS,
  payload: data,
});

const getMpProgramProgressFail = (error) => ({
  type: actionTypes.GET_MP_PROGRAM_PP_FAIL,
  payload: error,
});

export const getDocumentationLoading = (data) => ({
  type: actionTypes.GET_MP_DOCUMENTATION_LOADING,
  payload: data,
});

export const getDocumentationSuccess = (data) => ({
  type: actionTypes.GET_MP_DOCUMENTATION_SUCCESS,
  payload: data,
});
const getDocumentationFail = (error) => ({
  type: actionTypes.GET_MP_DOCUMENTATION_FAILURE,
  payload: error,
});

const getNotesMetadataLoading = () => ({
  type: actionTypes.NOTE_METADATA_LOADING,
  payload: null,
});
export const getNotesMetadataSuccess = (data) => ({
  type: actionTypes.NOTE_METADATA_SUCCESS,
  payload: data,
});
const getNotesMetadataFail = (error) => ({
  type: actionTypes.NOTE_METADATA_FAILURE,
  payload: error,
});
export const getNotesFilterMetadataSuccess = (data) => ({
  type: actionTypes.NOTE_FILTER_METADATA_SUCCESS,
  payload: data,
});
const getNotesFilterMetadataFail = (error) => ({
  type: actionTypes.NOTE_FILTER_METADATA_FAIL,
  payload: error,
});

// Program tab
export const getMemberProfileDetails = (participantId) => (dispatch, getState) => {
  let teamId = getState().user.team.id;
  let profileId = getState().user.profile.id;
  dispatch(getMemberProfileStart());
  return KccGoAPI.get(`/i/v1/unified/adminList/${participantId}`)
    .then((res) => {
      let resultData = res.data;
      resultData.lastRefreshed = new Date();
      if (resultData.userType === USER_TEAM_TYPE.CONSUMER) {
        TeleKardiaAPI.get(
          `/api/v1/teams/${teamId}/clinicians/${profileId}/patients/${participantId}/profile`,
        ).then((res) => {
          let consumerUserData = {};
          if (res?.data?.detailed_error) {
            dispatch(getMemberProfileFail(res?.data?.detailed_error));
            return res?.data?.detailed_error;
          } else {
            let data = res.data.result;
            consumerUserData = {
              ...data,
              participantDateOfBirth: data.dob,
              participantEmail: data.email,
              participantFirstName: data.firstName,
              gender: data.gender,
              participantLastName: data.lastName,
              location: data.location,
              ParticipantPhoneNumberVerified: true,
              ParticipantCountryCode: data?.phone.slice(0, -10),
              ParticipantPhoneNumber: data?.phone.slice(-10),
              userType: 'CONSUMER',
              lastRefreshed: new Date(),
            };
          }
          dispatch(getMemberProfileSuccess(consumerUserData));
          return res.data.result;
        });
      } else {
        dispatch(getMemberProfileSuccess(resultData));
        return resultData;
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMemberProfileFail(err.message));
    });
};

export const setCurrentWeekDetails = (currentWeek) => (dispatch, getState) => {
  const pgData = getState().memberProfile.programData;
  if (pgData) {
    dispatch(getMemberProfileSuccess({ ...pgData, currentWeek: currentWeek }));
  }
};

export const getMemberWeightDetails = (memberId) => {
  return IntakeKardiaAPI.get(
    `/i/v1/intakeCategory/heartHealthAssessment/screen/HHA-ID-Vitals/user/${memberId}/answers`,
  ).then((res) => {
    return res.data === null ? '' : res.data;
  });
};

// health details tab
export const getMemberProfileHealthDetails =
  (teamId, coachId, memberId, offsetInMinutes, timezoneValue) => (dispatch, getState) => {
    dispatch(getMpHealthLoading());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/heartHealthAssessment?timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
    )
      .then((res) => {
        let resultData = res.data;
        if (resultData.success) {
          dispatch(getMpHealthSuccess(resultData.result));
        }
      })
      .catch((err) => {
        dispatch(getMpHealthFail(err.message));
      });
  };

//pcp
export const getPCPMetadata = (memberId) => (dispatch) => {
  let consolidatedData = null;
  let retVal = [];
  dispatch(getMpHealthPcpMetadataLoading());
  IntakeKardiaAPI.get(
    `/api/v2/intake/heartHealthAssessment/HHA-ID-ProvideProviders?userId=${memberId}`,
  )
    .then((res) => {
      consolidatedData = res.data;

      if (res.status == 200) {
        IntakeKardiaAPI.get(
          `/i/v1/intakeCategory/heartHealthAssessment/screen/HHA-ID-ProvideProviders/user/${memberId}/answers`,
        ).then((res) => {
          if (res.status == 200) {
            let cellData = consolidatedData;
            //construct answer array for pcp
            //construct empty answers
            let ans = [];
            [0, 1].map((a, index) => {
              let temp = {};
              cellData.items.map((c) => {
                temp[c.key] = '';
              });
              ans.push(temp);
            });
            if (res.data) {
              let answers = res.data.answers;
              if (answers.providerTable.data && answers.providerTable.data.length > 0) {
                //overwrite any existing answers on the two answers if any
                answers.providerTable.data.map((item, index) => {
                  if (item) {
                    ans[index] = item;
                  }
                });
                ans['updatedTime'] = res.data?.answerTimings.providerTable;
              }
            }
            //store answers in redux
            dispatch(getMpHealthPcpSuccess(ans));
            //make two input sets whether answers or present or not
            [0, 1].map((a, index) => {
              cellData.items.map((c) => {
                if (a[c.key]) {
                  return;
                }
              });
              retVal.push(cellData);
              cellData = null;
              cellData = { ...consolidatedData };
              return;
            });
            dispatch(getMpHealthPcpMetadataSuccess(retVal));
          }
        });
      }
    })
    .catch((err) => {
      dispatch(getMpHealthPcpMetadataFail(err));
    });
};

export const updatePCPData = (tableKey, memberId, body) => (dispatch) => {
  dispatch(getMpHealthPcpUpdateLoading());
  IntakeKardiaAPI.post(
    `/api/v2/intake/heartHealthAssessment/HHA-ID-ProvideProviders/${tableKey}/user/${memberId}`,
    body,
  )
    .then((res) => {
      if (res.status == 200) {
        dispatch(getMpHealthPcpUpdateSuccess());
        dispatch(getPCPMetadata(memberId));
      }
    })
    .catch((err) => {
      dispatch(getMpHealthPcpUpdateFail(err));
    });
};

// program progress
export const getMemberProfilePPDetails = (weekNumber, memberId) => (dispatch, getState) => {
  dispatch(getMpProgramProgressStart());
  KccGoAPI.get(`/i/v1/kcc/programTab/${weekNumber}?userID=${memberId}`)
    .then((res) => {
      let resultData = res.data;
      if (res.status === 200) {
        dispatch(getMpProgramProgressSuccess(resultData));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMpProgramProgressFail(err.message));
    });
};

// program focus
export const getMemberProfilePFDetails =
  (teamId, coachId, memberId, offsetInMinutes) => (dispatch, getState) => {
    dispatch(getMpProgramFocusStart());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/appointments/latest?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
    )
      .then((res) => {
        let resultData = res.data;
        if (res.status === 200) {
          KccGoAPI.get(`/i/v1/kcc/heartHabits/${memberId}/latest`).then((res) => {
            let data = res.data;
            resultData.result.heartHabit = data ? data : [];
            if (res.status === 200) {
              dispatch(getMpProgramFocusSuccess(resultData.result));
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getMpProgramFocusFail(err.message));
      });
  };

//=== session logs api call ======//
export const getMemberProfileSessionLogData = (patientId, filterString) => (dispatch, getState) => {
  dispatch(getDocumentationLoading());

  const teamId = getState().user.team.id;
  const coachId = getState().user.profile.id;
  let offsetInMinutes = new Date().getTimezoneOffset();

  const endpoint = `/api/v1/teams/${teamId}/clinicians/${coachId}/note/patients/${patientId}/documentation?offsetInMinutes=${offsetInMinutes}&timeZone=${getTimezoneName()}&${filterString}`;

  TeleKardiaAPI.get(endpoint)
    .then((res) => {
      let data = res.data.result;
      dispatch(getDocumentationSuccess(data));
    })
    .catch((err) => {
      dispatch(getDocumentationFail(err.message));
    });
};

export const setDocumentationFilterAction = (data) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_DOCUMENTATION_FILTER_DATA,
    payload: data,
  });
};

export const getNotesMetadata =
  (teamId, coachId, filter = false, types = '', only = false) =>
  (dispatch) => {
    const onlyType = only ? `&typeOnly=${types}` : '';
    dispatch(getNotesMetadataLoading());
    TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${coachId}/note/metadata?filter=${filter}&typeExtra=${types}${onlyType}`,
      //filter=true ==> need filter's metadata; typeExtra ==> any extra notes type or tags: typeOnly ==> tags for that type only
    )
      .then((res) => {
        let resultData = res.data;
        !filter
          ? dispatch(getNotesMetadataSuccess(resultData.result))
          : dispatch(getNotesFilterMetadataSuccess(resultData.result));
      })
      .catch((err) => {
        !filter
          ? dispatch(getNotesMetadataFail(err.message))
          : dispatch(getNotesFilterMetadataFail(err.message));
      });
  };

export const getNote = async (teamId, coachId, noteId) => {
  return TeleKardiaAPI.get(`/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}`, {
    params: { offsetInMinutes: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
  })
    .then((res) => {
      let resultData = res.data;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};
export const getAlertNoteDetails = (teamId, coachId, noteId) => {
  return EscalationAPI.get(`api/v1/teams/${teamId}/clinicians/${coachId}/alerts/note/${noteId}`, {
    params: { offsetInMinutes: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
  })
    .then((res) => {
      let resultData = res.data.result;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};

//no-redux-actions
export const postNote = async (teamId, coachId, data, type, alertId = '') => {
  const baseUrl = type === ALERT_NOTE ? EscalationAPI : TeleKardiaAPI;
  const endpoint =
    type === ALERT_NOTE
      ? `api/v1/teams/${teamId}/clinicians/${coachId}/patients/${data.patientId}/alerts/${alertId}/note`
      : `/api/v1/teams/${teamId}/clinicians/${coachId}/note`;
  if (type === ALERT_NOTE) {
    delete data.type;
    delete data.patientId;
  }
  return baseUrl
    .post(endpoint, data, {
      params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },

      // header is added here because if call goes beyond jwt expiry time renewed token is not getting captured in App.js which resulting 401 in this api call.
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => {
      let resultData = res.data;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};

//no-redux-actions
export const editNote = async (teamId, coachId, data, noteId) => {
  return TeleKardiaAPI.put(`/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}`, data, {
    params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
  })
    .then((res) => {
      let resultData = res.data;
      return resultData;
    })
    .catch((err) => {
      return err;
    });
};

//no-redux-actions
export const postAddendum = async (teamId, coachId, data, noteId) => {
  return TeleKardiaAPI.post(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/addendum`,
    data,
    {
      params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
    },
  )
    .then((res) => {
      let resultData = res.data;

      return resultData;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//no-redux-actions
export const editAddendum = async (teamId, coachId, data, noteId, addendumId) => {
  return TeleKardiaAPI.put(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/addendum/${addendumId}`,
    data,
    {
      params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
    },
  )
    .then((res) => {
      let resultData = res.data;

      // console.log(resultData);
      return resultData;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const uploadNoteAttachment = async (teamId, coachId, noteId, file) => {
  const { type } = file;
  const uploadPayload = {
    'Content-Type': type,
    'Access-Control-Allow-Origin': '*',
    files: file,
  };
  const formData = new FormData();
  for (const key in uploadPayload) {
    if (Object.hasOwnProperty.call(uploadPayload, key)) {
      formData.append(key, uploadPayload[key]);
    }
  }

  return TeleKardiaAPI.post(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/attachment`,
    formData,
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { error: true };
    });
};

export const uploadAddendumAttachment = async (teamId, coachId, noteId, addId, file) => {
  const { type } = file;
  const uploadPayload = {
    'Content-Type': type,
    'Access-Control-Allow-Origin': '*',
    files: file,
  };
  const formData = new FormData();
  for (const key in uploadPayload) {
    if (Object.hasOwnProperty.call(uploadPayload, key)) {
      formData.append(key, uploadPayload[key]);
    }
  }

  return TeleKardiaAPI.post(
    `/api/v1/teams/${teamId}/clinicians/${coachId}/note/${noteId}/addendum/${addId}/attachment`,
    formData,
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//==== update vision statement =====//
export const updateVisionStatement = (memberId, vision) => {
  return KccGoAPI.put(`/i/v1/kcc/${memberId}/visionStatement`, vision, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
