export const initialdata = [
  {
    id: 'day1',
    label: 'Monday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
  {
    id: 'day2',
    label: 'Tuesday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
  {
    id: 'day3',
    label: 'Wednesday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
  {
    id: 'day4',
    label: 'Thursday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
  {
    id: 'day5',
    label: 'Friday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
  {
    id: 'day6',
    label: 'Saturday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
  {
    id: 'day7',
    label: 'Sunday',
    checked: false,
    startTime: new Date(),
    endTime: new Date(),
  },
];

export const dayLabel = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
};
export const dayCode = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};
export const headerText = {
  TAB1: 'My Information',
  TAB2: 'Team Members',
  TAB3: 'Team Settings',
  TAB4: 'Team Notification',
  TAB5: 'Update Password',
  TAB6: 'Add Team Member',
};
export const sectionText = {
  MY: 'My',
  TEAM_MEMBER: 'Team Member',
};

export const COMPONENT_USAGE = {
  USER_INVITE: 'USER_INVITE',
  INDIVIDUAL_PROVIDER_SETTINGS: 'INDIVIDUAL_PROVIDER_SETTINGS',
};

export const COMPONENT_POPUP_TEXTS = {
  DELETE_PROVIDER: 'Are you sure you want to delete this member?',
  DELETE_PROVIDER_SUBTEXT:
    'By deleting this member, any patients currently assigned to them will be assigned “Unassigned” for their provider.',
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


  export const COUNTRY_FULL_FORM='Canada'
 

  export const COUNTRY_CODE_US='us'