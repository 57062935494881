import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { PREVIEW_REPORT_MODAl } from './PreviewreportConst';
import { ColourCodes, ContentDetails, SectionHeader } from './PreviewreportStyling';
import { formatDate, timeFormat } from 'utilities/Utilities';


/**
 * PreviewreportClinicianDetails Component
 * This component helps in displaying 
 * Report ID , Heartrate, Clinician Notes, Determination value
 * @param {reportDetails} param0 
 * @returns 
 */

const PreviewreportClinicianDetails = ({ reportDetails }) => {
  //Data from redux
  const userCountry = useSelector((state) => state.user.profile.country);

  return (
    <>
      <Box
        sx={{
          marginBottom: '8px',
          marginTop: 'inherit',
          fontWeight: 'bold',
          borderTop: ColourCodes.lightGrey,
        }}
      >
        <SectionHeader title={PREVIEW_REPORT_MODAl.CLINICIAN_REVIEW} textSize={'14px'} margin={'8px 0px 0px 0px'} />
      </Box>
      <Box sx={{ padding: '12px 16px 12px 16px', backgroundColor: '#F3F5FA' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <ContentDetails
              headertitle={PREVIEW_REPORT_MODAl.CLINICIAN_DETERMINATION}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
            <Box sx={{fontWeight:'bold'}}>{'Primary Rhythm'}</Box>
          </Box>
          <Box>
            <Box sx={{textAlign:'end'}}>
            <ContentDetails
              headertitle={PREVIEW_REPORT_MODAl.RECORDING_DATE}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
            </Box>
           
            <Box>
              {' '}
              {reportDetails?.overreadReport?.recordedAt
                ? `${formatDate(
                    reportDetails?.overreadReport?.recordedAt,
                    userCountry,
                  )} ${timeFormat(reportDetails?.overreadReport?.recordedAt)}`
                : '--'}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            borderBottom: ColourCodes.lightGrey,
            padding: '8px',
            margin: '8px 0px 8px 0px',
          }}
        >
         { PREVIEW_REPORT_MODAl.REPORT_ID}{' '}
          {reportDetails?.overreadReport.crpReportId
            ? reportDetails?.overreadReport.crpReportId
            : '--'}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Box sx={{fontWeight:'bold'}}>{reportDetails?.overreadReport?.reportAnalysis ? reportDetails?.overreadReport?.reportAnalysis.toUpperCase() : "--"}</Box>
          <Box>{PREVIEW_REPORT_MODAl.HEART_RATE}{reportDetails?.overreadReport?.heartRate} {PREVIEW_REPORT_MODAl.BPM}</Box>
        </Box>

        <Typography style={{ marginBottom: '4px' }}>
          {PREVIEW_REPORT_MODAl.CLINICIAN_NOTES}
        </Typography>
        <Typography variant="body2">
          {reportDetails?.overreadReport?.comments
            ? reportDetails?.overreadReport?.comments
            : '--'}
        </Typography>
      </Box>
    </>
  );
};

export default PreviewreportClinicianDetails;
