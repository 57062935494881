/* eslint-disable */
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { setUnifiedPortalRegion } from 'auth/AuthUtilities';

const initialState = {
  profile: null,
  team: null,
  statusCount: {
    data: null,
    error: null,
    loading: false,
  },
  chatProfile: {},
  loading: false,
  errorMsg: null,
  // permissions: null,
  // authorizationType: null,
  isAuthenticated: false,
  login: false,
  error: [],
  isComponentMounted: false, // this state represents if any video session is ongoing.
  isregionupdated:"",
  skipIdleTimeUpdate:false
};

const getUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    errorMsg: null,
  });
};

const getUserSuccess = (state, action) => {
  // eslint-disable-next-line no-console
  if (action.payload === 'logOut')
    return updateObject(state, {
      profile: null,
      team: null,
      chatProfile: {},
    });
  else
    return updateObject(state, {
      profile: action.payload.profile,
      team: action.payload.teams[0],
      errorMsg: null,
      loading: false,
    });
};

const getMemberStatusStart = (state, action) => {
  return updateObject(state, {
    statusCount: {
      loading: true,
      data: null,
      error: false,
    },
  });
};
const getMemberStatusSuccess = (state, action) => {
  return updateObject(state, {
    statusCount: {
      loading: false,
      data: action.payload,
      error: false,
    },
  });
};
const getMemberStatusFail = (state, action) => {
  return updateObject(state, {
    statusCount: {
      loading: false,
      data: null,
      error: action.payload,
    },
  });
};
const getUserChatInfoSuccess = (state, action) => {
  // eslint-disable-next-line no-console

  return updateObject(state, {
    chatProfile: action.payload,
  });
};

const setLogin = (state, action) => {
  // eslint-disable-next-line no-console

  return updateObject(state, {
    login: action.payload,
  });
};
const getUserFail = (state, action) => {
  //const newEvent = updateObject(action.eventData, { id: action.id });
  return updateObject(state, {
    loading: false,
    errorMsg: action.error,
  });
};
const backendError = (state, action) => {
  //const newEvent = updateObject(action.eventData, { id: action.id });
  return updateObject(state, {
    loading: false,
    errorMsg: action.payload,
  });
};

const isComponentMounted = (state, action) => {
  return updateObject(state, {
    isComponentMounted: action.payload,
  });
};

const storeError = (state, action) => {
  //const newEvent = updateObject(action.eventData, { id: action.id });
  return updateObject(
    {},
    {
      error: [...state.error, action.payload],
    },
  );
};

const setAuthentication = (state, action) => {
  // eslint-disable-next-line no-console
  return updateObject(state, {
    isAuthenticated: action.payload,
  });
};

const isregionupdated = (state, action) => {
  setUnifiedPortalRegion(action.payload)
  // localStorage.setItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION , action.payload)
  return updateObject(state, {
    isregionupdated: action.payload,
  });
};


const getSkipIdleTimeUpdate = (state, action) => {
  // setUnifiedPortalRegion(action.payload)
  // localStorage.setItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION , action.payload)
  return updateObject(state, {
    skipIdleTimeUpdate: action.payload,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return getUserStart(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.GET_MEMBER_STATUS_COUNT_LOADING:
      return getMemberStatusStart(state, action);
    case actionTypes.GET_MEMBER_STATUS_COUNT_SUCCESS:
      return getMemberStatusSuccess(state, action);
    case actionTypes.GET_MEMBER_STATUS_COUNT_FAIL:
      return getMemberStatusFail(state, action);
    case actionTypes.BACKEND_DOWN:
      return backendError(state, action);
    case actionTypes.ERROR_LOGS:
      return storeError(state, action);
    case actionTypes.GET_USER_CHAT_CREDS:
      return getUserChatInfoSuccess(state, action);
    case actionTypes.SET_AUTHENTICATION:
      return setAuthentication(state, action);
    case actionTypes.SET_LOGIN:
      return setLogin(state, action);
    case actionTypes.VIDEOCALL_STARTED:
      return isComponentMounted(state, action);
    case actionTypes.GET_USER_REGION:
        return isregionupdated(state, action);
    case actionTypes.SKIP_IDLE_TIME_UPDATE:
      return getSkipIdleTimeUpdate(state, action)
    default:
      return state;
  }
};

export default reducer;
