import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain'
import React from 'react'
import { MuiTableComponent } from 'screens/common/CustomUI'
import {
  Box,
  Button,
  Typography,
  styled,
  Grid
} from '@mui/material';
import { parseName } from 'utilities/Utilities';
import { StatusColumn } from '../MemberList/components/StatusColumn';
import moment from 'moment';
import { AppointmentStatusTypes, SessionChannelTypeId } from 'utilities/Constants';
import { useSelector } from 'react-redux';
import { homeScreenButtonText } from './constants/DashBoardConstant';


export const TableRowText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Work Sans',
}));

const DoctorDashboardTable = ({ sessionData, navigate, upcomingSessionInWaitroom, videoCall, completeSession, audioCall }) => {

  //Data From Redux Store
  const isTeleconnectStarted = useSelector((state) => state.user.isComponentMounted);
  const { isFloatingWindow } = useSelector((state) => state.adhocCall);
  const isLoading = useSelector((state) => state.appointments.loading);


// Table Headings For Doctor's Todays Sessions
  const column = [
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      filterable: false,
      sortable: false,
      type: 'dateTime',
      renderCell: (params) => <TableRowText variant="body2">  {`${moment(new Date(params.row.scheduledStartTime)).format('LT')} -
          ${moment(new Date(params.row.scheduledEndTime)).format('LT')}`}</TableRowText>,
    },
    {
      field: 'name',
      headerName: 'Patient',
      width: 200,
      editable: false,
      type: 'string',
      renderCell: (params) => <TableRowText variant="body2" onClick={() =>
        navigate(
          params.row.patient.patientId,
          params.row.appointmentId,
          params.row.appointmentStatusId,
        )
      }>
        {parseName(params.row.patient.firstName, params.row.patient.lastName)}</TableRowText>,
    },
    {
      field: 'program',
      headerName: 'Program',
      width: 200,
      type: 'singleSelect',
      renderCell: (params) => (
        <TableRowText variant="body2">{params.row.program || '--'}</TableRowText>
      ),
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 200,
      type: 'singleSelect',
      renderCell: (params) => (
        <TableRowText variant="body2">
          {params?.row?.visitType || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'appointmentChannelType',
      headerName: 'Type',
      width: 200,
      type: 'singleSelect',
      renderCell: (params) => (
        <TableRowText variant="body2">
          {params?.row?.appointmentChannelType.split(' ')[0] || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'appointmentStatus',
      headerName: 'Status',
      width: 300,
      type: 'singleSelect',
      renderCell: (params) => (
        <>
          <Box style={{ marginRight: "5px" }}>
            <TableRowText variant="body2">
              {upcomingSessionInWaitroom.length > 0 &&
                upcomingSessionInWaitroom[0]?.id === params.row.id ? (
                <StatusColumn data={upcomingSessionInWaitroom[0]?.title} />
              ) : (
                <StatusColumn data={params.row.appointmentStatus} />
              )}
            </TableRowText>
          </Box>
          <Box>
            {((params.row.appointmentStatusId === AppointmentStatusTypes.MemberInWaitingRoom &&
              params.row.appointmentChannelTypeId !== SessionChannelTypeId.AS_SCHEDULED_PHONE) ||
              (upcomingSessionInWaitroom[0]?.id === params.row.id &&
                upcomingSessionInWaitroom[0]?.status ===
                AppointmentStatusTypes.MemberInWaitingRoom)) && (
                <Button
                  variant="filled"
                  color="primary"
                  style={{ backgroundColor: "#6387C5", color: "white" ,border:"none"}}
                  disabled={isTeleconnectStarted || isFloatingWindow}
                  onClick={() =>
                    videoCall(
                      params.row.appointmentId,
                      `${params.row.patient.lastName}, ${params.row.patient.firstName}`,
                    )
                  }
                >
                  {homeScreenButtonText.INITIATE_VIDEO_CALL}
                </Button>
              )}
            {params.row.appointmentStatusId === AppointmentStatusTypes.InProgress &&
              params.row.appointmentChannelTypeId !==
              SessionChannelTypeId.AS_SCHEDULED_PHONE && (
                <Button
                  variant="filled"
                  color="primary"
                  style={{ backgroundColor: "#6387C5", color: "white", border:"none" }}
                  disabled={isTeleconnectStarted || isFloatingWindow}
                  onClick={() =>
                    videoCall(
                      params.row.appointmentId,
                      params.row.patient.firstName,
                      params.row.patient.lastName,
                    )
                  }
                >
                  {homeScreenButtonText.REJOIN_VIDEO_CALL}
                </Button>
              )}
            {params.row.appointmentChannelTypeId === SessionChannelTypeId.AS_SCHEDULED_PHONE &&
              (params.row.appointmentStatusId === AppointmentStatusTypes.Scheduled ||
                params.row.appointmentStatusId === AppointmentStatusTypes.InProgress ||
                params.row.appointmentStatusId ===
                AppointmentStatusTypes.Encounter_Summary_Preparation) && (
                <Button
                  variant="filled"
                  color="primary"
                  style={{ backgroundColor: "#6387C5", color: "white",border:"none" }}
                  disabled={isTeleconnectStarted || isFloatingWindow}
                  onClick={() => audioCall(params.row.patient.patientId, params.row.appointmentId)}
                >
                  {params.row.appointmentStatusId ===
                    AppointmentStatusTypes.Encounter_Summary_Preparation
                    ? homeScreenButtonText.REJOIN_AUDIO_CALL
                    : homeScreenButtonText.INITIATE_AUDIO_CALL}
                </Button>
              )}
            {params.row.appointmentStatusId ===
              AppointmentStatusTypes.Encounter_Summary_Preparation && (
                <Button
                  variant="filled"
                  color="primary"
                  onClick={() => completeSession(params.row.patient.patientId, params.row.appointmentId)}
                  style={{ backgroundColor: "#6387C5", color: "white" ,border:"none"}}
                >
                  {homeScreenButtonText.COMPLETE_SESSION_NOTES}
                </Button>
              )}

          </Box>
        </>
      ),
    },

  ]
  return (
    <>
      <SectionMain
        headerElement={{
          headerText: 'Todays Sessions',
        }}
        bodyElement={[
          {
            childElements: (
              <>
                {!isLoading && sessionData.length === 0 ?
                  <Typography variant="body2">No session scheduled for today</Typography>
                  :
                  <Grid container>
                    <Grid item xs={12}>
                      <MuiTableComponent
                        getRowId={(row) => row.appointmentId}
                        columns={column}
                        rows={sessionData}
                      />
                    </Grid>
                  </Grid>}
              </>
            ),
          },
        ]}
      />

    </>
  )
}

export default DoctorDashboardTable
