import React from 'react';
import { Box } from '@mui/material';
import KardiaLogo from '../../assets/images/KardiaLogo.png';
import { ColourCodes, ContentDetails, SectionHeader } from './PreviewreportStyling';
import { PREVIEW_REPORT_MODAl, getHeaderName } from './PreviewreportConst';
import PreviewPatientInformation from './PreviewPatientInformation';

/**
 * PreviewReportHeaderSection Component
 * This component displays Header Details of the patient/user, including Respective Physician Details 
 * EX: Firstname lastname Details
 * @param {reportdetails} param0 
 * @returns 
 */

const PreviewReportHeaderSection = ({ reportDetails }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          borderBottom: ColourCodes.lightGrey,
        }}
      >
        <Box>
          <img
            style={{ width: '91px', height: '20px', marginRight: '8px' }}
            src={KardiaLogo}
            alt="kardialogo"
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <SectionHeader title={PREVIEW_REPORT_MODAl.AFTER_VISIT_REPORT} textSize={'12px'}  margin={'0px'}/>
          <Box style={{ textAlign: 'end' }}> {getHeaderName(reportDetails?.headerType)}</Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          borderBottom: ColourCodes.lightGrey,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
          <Box>
            <Box>
            <ContentDetails
              headertitle= {PREVIEW_REPORT_MODAl.PREPARED_FOR}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
                </Box>
            <SectionHeader
              title={`${reportDetails?.patient?.lastName}, ${reportDetails?.patient?.firstName}`}
              textSize="14px"
              margin={'0px'}
            />
          </Box>
          <Box>
            <Box>
            <ContentDetails
              headertitle=  {PREVIEW_REPORT_MODAl.REVIEWED_BY}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
               </Box>
            <SectionHeader
              title={`${reportDetails?.physician?.lastName}, ${reportDetails?.physician?.firstName}`}
              textSize={'14px'}
              margin={'0px'}
            />
          </Box>
        </Box>
        <PreviewPatientInformation reportDetails={reportDetails} />
      </Box>
    </>
  );
};

export default PreviewReportHeaderSection;
