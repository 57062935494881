import React from 'react'
import { Box } from '@mui/material'
import { ContentDetails, SectionHeader } from './PreviewreportStyling'


const Patientinformationcustom = ({headertitle ,title}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
        <ContentDetails headertitle={headertitle} textSize={"10px"} textcolor={"#4B5B68"}/>
    </Box>
    <Box>
        <SectionHeader title={title} textSize={"10px"} margin={'0px'}/>
    </Box>
</Box>
  )
}

export default Patientinformationcustom
