import React from 'react';
import { Box, Typography } from '@mui/material';
import { ColourCodes, SectionHeader } from './PreviewreportStyling';
import { PREVIEW_REPORT_MODAl } from './PreviewreportConst';

/**
 * PreviewreportModalRecomendedActions Component
 * This component helps in displaying the Recomended details to the patient by Doctor
 * @param {reportDetails} param0 
 * @returns 
 */

const PreviewreportModalRecomendedActions = ({ reportDetails }) => {
  return (
    <Box sx={{ borderBottom: ColourCodes.lightGrey, paddingBottom: '12px' }}>
      <Box sx={{ marginTop: '8px', fontWeight: 'bold' }}>
        <SectionHeader title={PREVIEW_REPORT_MODAl.RECOMENDED_ACTION} textSize={'14px'} margin={'0px'}/>
      </Box>
      <Typography style={{ marginBottom: '12px' }} variant="body2">
        {reportDetails?.patientAppointment?.recommendedAction
          ? reportDetails?.patientAppointment?.recommendedAction
          : '--'}
      </Typography>
    
      <Box
        sx={{
          height: '40px',
          backgroundColor: '#FFF3DA',
          padding: '12px 30px 12px 24px',
          marginTop: '6px',
        }}
      >
       {reportDetails?.patientAppointment?.isFollowUpRequired 
  ? `${PREVIEW_REPORT_MODAl.FOLLOW_UP_SENTENCE} ${reportDetails?.patientAppointment?.followUpDuration}` 
  : PREVIEW_REPORT_MODAl.DEFAULT_FOLLOW_UP_SENTENCE} 
 
      </Box>
    
    </Box>
  );
};

export default PreviewreportModalRecomendedActions;
