export const ECG_COMPARE_HEADER={
    REPORT_ID: 'Report ID:',
    AI_DETERMINATION:'AI Determination:',
     DATE_RECORDED:'Date Recorded:',
     COMPARE_EKG_TO:'Compare EKG to:',
     NO_RECORDINGS_FOUND:'No Recordings Found',
     CLOSE_COMPARISON:' Close Comparison'
}

export const ECG_AI_DETERMINATION_VALUES={
    NORMAL_SINUS_RYTHUM:'Normal Sinus Rhythm',
    ATRIAL_FIBRILLATION:'Atrial Fibrillation',
    POSSIBLE_ATRIAL_FIBRILATION:'Possible Atrial Fibrillation',
    UNCLASSIFIED:'Unclassified',
    NO_ANALYSIS:'No Analysis',
    UNREADABLE:'Unreadable',
    LONG:'Long',
    SHORT:'Short',
    SINUS_RYTHUM:'Sinus rythum',
    SINUS_RYTHUM_ECTOPY:'Sinus Rhythm with Supraventricular Ectopy',
    SINUS_WIDE_QRS:'Sinus Rhythm with Wide QRS',
    SINUS_PREMATURE_CONTRACTIONS:'Sinus Rhythm with Premature Ventricular Contractions',
    BRADYCARDIA:'Bradycardia',
    TACHYCARDIA:'Tachycardia'
}