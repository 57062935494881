import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Paper } from '@material-ui/core';

import DashboardTable from './DashboardTable';
import DoctorDashboardKPIs from '../../doctor/DoctorDashboardKPIs';
import CardBottomContainer from './Card/CardBottomContainer';

import { AUTH_TYPE } from 'constants/app';
import { ChatContext } from 'providers/chat';
import { useContext } from 'react';
import { Strophe } from 'strophe.js';
import { getChatUserAction, getLastMessageChatUser } from 'store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '30px',
    font: 'Work Sans !important',
  },
  paper: {
    height: 300,
    width: 'auto',
  },
  kpis: {
    padding: '20px',
  },
}));
const Dashboard = () => {
  const { closeConnection, status, setUnreadMsgCount, unreadMsgCount, fetchAllChatHistory } =
    useContext(ChatContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.chatProfile.userType);

  //==ROUTINE TO LOAD CHAT BEFOREHAND==
  const { data, inboxLoadCount } = useSelector((state) => state.chat);
  const profileId = useSelector((state) => state.user.profile.id);
  useEffect(() => {
    const length = data?.length;
    if (status === Strophe.Status.CONNECTED && !length) {
      dispatch(getChatUserAction(profileId));
    }
  }, [status]);
  useEffect(() => {
    const length = data?.length;
    if (status === Strophe.Status.CONNECTED && length > 0) {
      inboxLoadCount == 0 && dispatch(getLastMessageChatUser(fetchAllChatHistory));
      //sync unread message count with inbox list incase of any member reassign
      let chatIdKeyMap = {};
      let tempMsgCount = { ...unreadMsgCount };
      data.map((d) => {
        chatIdKeyMap[d.chatUserId] = 0;
      });
      Object.keys(tempMsgCount).map((u) => {
        if (chatIdKeyMap[u] == undefined) {
          delete tempMsgCount[u];
        } else {
          chatIdKeyMap[u] = tempMsgCount[u];
        }
      });
      setUnreadMsgCount(chatIdKeyMap);
    }
  }, [data]);

  //====END====

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* Card display here */}
          <Grid container spacing={4}>
            {userType === AUTH_TYPE.COACH ? (
              <Grid item xs={12}>
                <CardBottomContainer />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <DoctorDashboardKPIs />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Todays session table  // refresh time */} 
          {/* <Paper> */}
            <DashboardTable userType={userType} />
          {/* </Paper> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
