import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CrossIcon from "../../assets/images/Vector (3).png";
import Grid from "@mui/material/Grid";
import styles from "./EkgModal.module.css";
import moment from "moment";
import styled from "@mui/material/styles/styled";
import { fetchSamples } from "utilities/ApiUrls";
import * as Lib from "../../constants/RecordingConstants";
import { calcRowSeconds } from "components/Utilities";
import EkgRecording from "components/chart/EkgRecording";
import Loading from "components/Loading";
import { Stack } from "@mui/material";
import Patientdetailsdata from "screens/Ecgscreens/Patientdetailsdata";
import { formatDate } from "utilities/Constants";
import { dateFormatting, timeFormat } from "utilities/Utilities";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1110,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  minHeight: 812,
  background: "#F1F2F3 !important",
  borderRadius: 3,
  border: "none !important",
};

const StyledText = styled(
  Typography,
  {},
)(() => ({
  fontWeight: 400,
  fontSize: "15px",
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  letterSpacing: "0.17px",
}));

// This Modal will be pooped Up only if the user is Doctor 
const EkgModal = (props) => {
  const { ecgData } = props;
  const { crpReportIds } = props;
  const [sampleData, setSampleData] = useState(ecgData);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [crpfilteredvalues, setcrpfilteredvalue] = useState();
  let contentHeight = 650;
  let ekgHeight = contentHeight;
  const mainContentWidth = 730;
  const MIN_SECONDS = 1;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0
      ? calcRowSeconds(mainContentWidth)
      : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND;
  const calpulse = true;

  useEffect(() => {
    if (Object.keys(ecgData).length > 0) {
      getSamples(ecgData?.id);
    }
  
    // Add null checks to avoid accessing properties of undefined
    if (crpReportIds?.data) {
      if (ecgData?.id in crpReportIds?.data?.byId) {
        setcrpfilteredvalue(crpReportIds?.data?.byId[ecgData?.id]?.reportID);
      } else {
        setcrpfilteredvalue("--");
      }
    }
  }, [ecgData, crpReportIds]); 

  async function getSamples(id) {
    try {
      setLoading(true);
      let data = await fetchSamples(id);
      data ? setSampleData({ ...sampleData, [`enhanced`]: data?.data?.enhanced }) : setSampleData({ ...sampleData})
      setLoading(false);
    } catch (err) {
      setErrorMsg("No ECG Sample found");
    }
  }
  return (
    <>
      {loading ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : errorMsg !== null ? (
        <div className={styles.loader}>{errorMsg}</div>
      ) : (
        <Grid
          sx={props.close ? style : { width: "100%" }}
          elevation={4}
          style={{
            position: "relative",
            margin: props.close && "10px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
          }}
        >
          <>
            {props.close && (
              <>
                <Stack
                  direction={"row"}
                  columnGap={1}
                  style={{ padding: "11px"   }}
                
                >
                  <Patientdetailsdata
                    labledata="Heart Rate:"
                    keydata={`${ecgData?.bpm} BPM`}   
                  />

                  <Patientdetailsdata
                    labledata={"Date Recorded:"}
                    //Formatted the data for BEK-5001 
                    keydata={`${formatDate(ecgData?.recordedAt)} ${timeFormat(ecgData?.recordedAt)}`}
                  />
                  <Patientdetailsdata labledata={"Report ID:"} keydata={crpfilteredvalues} />
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <img
                      src={CrossIcon}
                      style={{ cursor: "pointer" }}
                      onClick={props.close}
                    />
                  </Grid>
                </Stack>
              </>
            )}
            {/* <div
              className={styles.recordingInfo}
              style={{ top: props.close && "100px !important" }}
            >
              <span>25 mm/s</span>
              <span>10 mm/mV</span>
            </div> */}
            <Box style={{ padding: "12px" }}>
              <EkgRecording
                height={ekgHeight}
                width={ekgWidth}
                recording={sampleData?.enhanced}
                rowSeconds={rowSeconds}
                calpulse={calpulse}
                recordingSample={sampleData?.enhanced}
                inverted={sampleData.inverted} //inverted flag would depend on recorded device thus taken from api response
                sampleData={sampleData}
                is6l={ecgData.is6l}
              />
            </Box>
          </>
        </Grid>
      )}
    </>
  );
};

export default EkgModal;


