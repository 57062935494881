import React from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';

/**
 * Table component
 * This component displays Patient Data, including
 * Patients-> Medical,Family,Social (Hostory) , Medication, Allergy and Surgery Details 
 * @param {data} param0 
 * @returns Table with Patient History
 */

const TableComponent = ({ data, cellSeparator = ':' }) => {
  return (
    <TableContainer>
      <Table
        sx={{
          border: '1px solid',
          borderColor: 'grey.400',
          borderRadius: '4px',
          marginTop: '12px',
        }}
      >
        <TableBody>
          {Object.entries(data).map(([key, value], index) => (
            <TableRow
              key={key}
              sx={{
                backgroundColor: index % 2 === 0 ? '#F3F5FA' : '#F7F7F7',
                '&:last-child td': { borderBottom: 'none' },
                '&:not(:last-child) td': {
                  borderBottom: '4px solid',
                  borderBottomColor: 'common.white',
                },
              }}
            >
              {/* First Cell: Label */}
              <TableCell
                sx={{
                  width: '30%',
                  maxWidth: '150px',
                  color: 'text.secondary',
                  textAlign: 'left',
                }}
              >
                {key}
              </TableCell>

              {/* Second Cell: Separator */}
              <TableCell
                sx={{
                  width: '1%',
                  maxWidth: '10px',
                  textAlign: 'center',
                  padding: '0 8px',
                }}
              >
                {cellSeparator}
              </TableCell>

              {/* Third Cell: Value */}
              <TableCell
                sx={{
                  textAlign: 'left',
                  textTransform: 'capitalize',
                }}
              >
                {value || '--'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;

