/* eslint-disable */
import * as API from '../../Services/API/actions';
import moment from 'moment';
//import { useDispatch } from "react-redux";
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Statistics from './statistics';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CustomComponent } from 'screens/Ecgscreens/Screens';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    '& .MuiAppBar-root': {
      borderBottom: '1px solid #BABFBD',
    },
    '& .  MuiTabs-flexContainer': {
      borderBottom: '1px solid #BABFBD',
    },
  },
  customTabRoot: {
    color: '#BABFBD',
    borderBottom: '1px solid grey !important',
    '& .Mui-selected': {
      color: '#6387C5 !important',
      borderBottom: '#6387C5 !important', // Blue
    },
  },
  customTabIndicator: {
    backgroundColor: '#6387C5',
    height: '2px',
    width: '10px'
  },
  tabRoot: {
    fontSize: '14px !important',
    textTransform: 'initial',
    // minWidth: 100,
    minWidth: 'auto',
    fontWeight: '700',
    fontFamily: ['Work Sans'].join(','),
    '&$tabSelected': {
      color: '#6387C5',
      fontWeight: '500',
      borderBottom: 'none !important',
    },
  },
});

const ScrollableTabsButtonAuto = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (

    <SectionMain
      headerElement={{
        childElements: (
          <>
            <CustomComponent text={"Session Statistics"} variant={"h6"} />
            <Tabs
              // className="custom_tab"
              classes={{
                root: classes.customTabRoot,
                indicator: classes.customTabIndicator,
              }}
              value={props.value}
              onChange={props.handleChange}
              scrollButtons="auto"
              centered
              variant="fullWidth"
              style={{ borderBottom: "1px solid grey", paddingTop: "16px" }}
            >
              <Tab label={t('DAY')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
              <Tab label={t('WEEK')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
              <Tab label={t('MONTH')} classes={{ root: classes.tabRoot }} {...a11yProps(2)} />
            </Tabs>
            <>
              <TabPanel value={props.value} index={0}>
                <Statistics stats={props.stats} label="day" />
              </TabPanel>
              <TabPanel value={props.value} index={1}>
                <Statistics stats={props.stats} label="week" />
              </TabPanel>
              <TabPanel value={props.value} index={2}>
                <Statistics stats={props.stats} label="month" />
              </TabPanel>
            </>
          </>
        )
      }}
    />


  );
};
export default ScrollableTabsButtonAuto;
