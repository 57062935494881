import * as React from 'react';
import { styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BugReportIcon from '@mui/icons-material/BugReport';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ThemeProvider } from '@material-ui/core/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { makeStyles } from '@mui/styles';
import { darkBluishGrey } from 'style/colors';
import { US_REGION } from 'constants/app';
import { CANADA_DATE_FORMAT, DATE_FORMAT } from 'utilities/Constants';

const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    '& .MuiInputBase-root': {
      // Custom styles for the input field
      // Example: Increase the font size
      height: '40px',
      fontSize: '15px',
      color: darkBluishGrey,
    },
    '& .MuiInputLabel-root': {
      fontSize: '15px',
      marginTop: '1px',
    },
    // Add more styles as needed
  },
}));

const RightArrowIcon = styled(ArrowForwardIcon)({
  backgroundColor: 'red',
});

export default function CustomDateRangePicker(props) {
  const { selectedDate, setSelectedDate, setSelectedDateFilter, disabled,userCountry } = props;
  const classes = useStyles();
  const onChangeHandler = (date) => {
    setSelectedDate(date);
    setSelectedDateFilter(1);
  };

  const dateFormat = userCountry === US_REGION ? DATE_FORMAT : CANADA_DATE_FORMAT;

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['SingleInputDateRangeField']}>
          <DateRangePicker
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{
              textField: {
                InputProps: { endAdornment: <DateRangeIcon /> },
                size: 'small',
              },
            }}
            onChange={onChangeHandler}
            className={classes.dateRangePicker}
            value={selectedDate}
            disabled={disabled}
            format={dateFormat}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}