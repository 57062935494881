import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash.get';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { DOCTOR_ROUTES } from 'constants/app';
import Authorize from '../Authorize';
import { styled } from '@mui/styles';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import { CustomComponent } from 'screens/Ecgscreens/Screens';
import { Stack } from '@mui/material';
import PageUpdated from 'components/PageUpdated';
import moment from 'moment';
import { refreshEcgInboxTable } from 'store/actions/EkgListActions';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '../../assets/images/icons/refresh-header.svg'

const classes = {
  root: `MuiDataGrid-root`,
};

const Root = styled('div')(({ theme, height }) => ({
  [`&.${classes.root}`]: {
    '& .MuiGrid-container': {
      justifyContent: "space-between"
    },
  },


}));

const AvailabilitySection = (props) => {
  const dispatch = useDispatch();
  const { availability } = props;
  // const classes = useClass();
  let history = useHistory();
  const navigate = () => {
    history.push(DOCTOR_ROUTES.TELEKARDIA_CONSULTATION);
  }; // to Navigate to settigs page for editing the time

  const refreshTime = useSelector((state) => state.ekgList?.refreshTime);

  return (
    <>
      {/* <div style={{ display: 'flex', columnGap: '16px', marginRight: '30px' }}>
          <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
            {' '}
            Last Refreshed:{' '}
            <b>
              {'  '}
              {moment(refreshTime).format('hh:mm A')}
            </b>
          </Typography>

          <RefreshIcon onClick={() => dispatch(refreshEcgInboxTable())} />
        </div> */}
      <Root className={classes.root} >
        <SectionMain sx={{ padding: "16px" }}
          headerElement={{
            childElements: (
              <>
                <CustomComponent text={"My Availablity for Today"} variant={"h6"} />
              </>
            )
          }}
          bodyElement={[
            {
              childElements: (
                <>
                  <Grid container direction="column">
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid>
                        <CustomComponent text={"Avaiable time"} variant={"body1editiedfontsize"} />
                        <Typography style={{ paddingBottom: "24px", color: "#6387C5", fontSize: "20px" }}>
                          {(Number(_get(availability, 'totalAvailableHours')) < 10
                            ? _get(availability, 'totalAvailableHours')
                            : _get(availability, 'totalAvailableHours')) +
                            '.' +
                            (Number(_get(availability, 'totalAvailableMins')) < 10
                              ? _get(availability, 'totalAvailableMins')
                              : _get(availability, 'totalAvailableMins')) || '-'}{' '}
                          Hours
                        </Typography>
                      </Grid>
                      <Grid>
                        <Button onClick={navigate} variant="contained" color="primary" style={{ boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)" , backgroundColor:"#6387C5" }}>Edit</Button>
                      </Grid>
                    </Grid>
                    <CustomComponent text={"Avaiable Slots for today"} variant={"body1editiedfontsize"} />
                    <Typography style={{ paddingTop: "12px" }}>
                      {availability.availableSlots && availability.availableSlots.map((item, index) => {
                        return (
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item key={index}>
                              <Box style={{ display: 'flex' }}>
                                <EventAvailableIcon />
                                <Typography style={{ marginLeft: '5px', marginBottom: "8px", fontFamily: 'Work Sans' }}>
                                  {`${item.slotTiming.split('-')[0]} - ${item.slotTiming.split('-')[1]}` || '-'}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item>
                              <Typography variant="body2" style={{ fontSize: "15px", fontWeight: "700" }}>
                                {(Number(_get(item, 'availableHours')) < 10
                                  ? _get(item, 'availableHours')
                                  : _get(item, 'availableHours')) +
                                  '.' +
                                  (Number(_get(item, 'availableMinutes')) < 10
                                    ? _get(item, 'availableMinutes')
                                    : _get(item, 'availableMinutes')) || '-'}{' '}
                                Hours
                              </Typography>
                            </Grid>
                          </Grid>

                        );
                      })}
                    </Typography>
                  </Grid>
                  <>
                  </>
                </>
              )
            },

          ]}
        />
      </Root>
    </>
  )
}
export default AvailabilitySection
