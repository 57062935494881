import { CrpAPI, CrpAPIEU } from 'Axios/axios';
import { COMPLETTED, INCOMMING, LOCAL_STORAGE_UNIFIED_PORTAL_REGION, PENDING, completed, incoming, pending } from 'constants/app';

/**
 * admin should not see incming review screen
 * @param {Router} history
 * @param {string} status
 * @param {int} reportId
 * @param {boolean} isAdmin
 * @returns navigates to review screen
 */
export const requestReviewScreenNavinationRoute = (history, status, reportId, isAdmin) => {
  if ((status.toUpperCase() === INCOMMING || status.toUpperCase() === PENDING) && isAdmin) {
    return;
  }
  let reportStatus = '';
  if (status.toUpperCase() === PENDING) reportStatus = pending;
  else if (status.toUpperCase() === INCOMMING) reportStatus = incoming;
  else reportStatus = completed;

  const userType = isAdmin ? '/admin' : '';
  const route = history.push(`${userType}/clinician/ecglist/review/${reportId}/${reportStatus}`);
  return route;
};
/**
 * @filter will create separate redux state for incoming, pending and completed requests
 * @reduce will transform the response in correct format.
 * @param {Array} res - ecg inbox table's api response
 * @returns transformed array of report id along with its index for back n forth request pagination
 */
export const getRequestPagination = (res, reviewStatus) => {
  return res
    ?.filter((item) => item.status === reviewStatus.toUpperCase() || reviewStatus === incoming)
    ?.reduce((acc, cur, i) => {
      return [...acc, { id: i + 1, reportId: cur?.id }];
    }, []);
};

/**
 * 
 * @param {Object} queryParams this consistutes api's query params --> 
 * {
 *  pageSize: 100,
    page: 1,
    search: '',
    timeRequestedSortOrder: 'asc',
    timeSubmittedOrder: 'desc'
    status: status,
    filters: {}
    } 
 * @returns api response
 */
export const getECGInboxTableData = (queryParams) => {
  const data = {
    operator: 'AND',
    conditions: queryParams?.filters?.length > 0 ? queryParams?.filters : null,
    children:
      queryParams.children?.length > 0
        ? [
            {
              operator: 'OR',
              conditions: queryParams?.children,
            },
          ]
        : null,
  };
  const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
  let apiInstance= regionToken === "us" ? CrpAPI :CrpAPIEU
  return apiInstance.get(
    `v2/worklist?pageSize=${queryParams.pageSize}&pageNumber=${queryParams.page}&search=${
      queryParams?.search
    }&status=${queryParams?.status}&sortBy=${queryParams?.sortBy}&sortOrder=${
      queryParams?.sortOrder
    }&muiFilter=${JSON.stringify(data)}`,
  )
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => console.log(err));
};

/**
 * on click of table row this api is triggerd, to check its status.
 * if the status is in progerss user wont be navigated to review screen rather will be shown a info popup.
 * @param {int} id report id
 * @returns checks if particular report's status.
 */
export const checkRequestStatus = (id) => {
  const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
  let apiInstance= regionToken === "us" ? CrpAPI :CrpAPIEU
  return apiInstance.get(`v1/checkStatus/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
