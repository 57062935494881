import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import ChipComponent from './ChipComponent';
import { createNote, editSessionProgramFocus } from 'store/actions/session';
import { fonts, colors, fontSizes, spacing } from '../../../style/styleSettings';
import TextInputs from 'components/mui/formElements/TextInputs';
import Loader from 'components/Shared/Loader';
import { TeleKardiaAPI } from 'Axios/axios';
import { getRegion, getToken } from 'auth/AuthUtilities';
import { AUTH_TYPE, LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';

const Root = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    fontFamily: `${fonts.SESSION} !important`,
  },
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      '& .MuiTypography-root': {
        fontWeight: 700,
        color: `${colors.SESSION_BLACK}`,
      },
    },
  },
  '& .MuiTableBody-root': {
    '& .MuiTableCell-root': {
      '& .MuiTypography-root': {
        fontWeight: 400,
        marginBottom: 'auto',
      },
    },
  },
  '& .MuiSelect-outlined': {
    border: '1px solid rgba(20, 42, 57, 0.16)',
    padding: `${spacing.SMALL}`,
  },
  '& .MuiChip-outlinedPrimary': {
    color: '#000 !important',
    border: `1px solid ${colors.SPRUCE} !important`,
  },
  '& .MuiChip-outlinedSecondary': {
    color: '#000 !important',
    border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED} !important`,
  },
  '& .MuiFormControl-root': {
    marginTop: '10px !important',
    width: '100% !important',
  },
  '& .MuiSelect-root': {
    width: '100% !important',
  },
  '& .MuiTableRow-root': {
    fontSize: `${fontSizes.BASE}`,
  },
  '& .MuiTypography-h6': {
    fontFamily: 'Work Sans !important',
    fontSize: '16px',
    textAlign: 'center',
    margin: '10px',
  },
  '& .MuiTypography-subtitle': {
    fontFamily: 'Work Sans !important',
    fontSize: '16px',
    fontWeight: '600 !important',
    color: 'rgb(0, 0, 0, 0.87)',
  },
}));

const ProgramFocusComponent = (props) => {
  const dispatch = useDispatch();

  const [validation, setValidation] = useState(false);
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const FIELDS = {
    STAGEOFCHANGE: 'stageOfChange',
    ACHIEVEMENT: 'achievement',
    PROGRAMFOCUS_CURRENT: 'current',
    PROGRAMFOCUS_TARGET: 'target',
  };
  //   handlers
  const tableEditingHandler = (id, value, typeOfHandler) => {
    value && setValidation(true);

    let dataToEditIndex = data.findIndex((d) => id === d.id);
    let tempData = [...data];
    tempData[dataToEditIndex][typeOfHandler] = value;
    setData(tempData);
  };

  const { memberId, userType } = props;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const programFocusData = useSelector((state) => state.session.programFocus);

  useEffect(() => {
    setData(programFocusData);
  }, [programFocusData]);

  const [addPFSuccess, setaddPFSuccess] = useState([]);
  useEffect(() => {
    if (data.length > 0) {
      addPFSuccess.length === data.length && createNote(teamId, clinicianId, memberId, 5);
    }
  }, [addPFSuccess]);

  const editHandler = () => {
    if (editing) {
      //save to api
      data.map((d) => {
        //make data
        const reqBody = {
          current: d[FIELDS.PROGRAMFOCUS_CURRENT],
          target: d[FIELDS.PROGRAMFOCUS_TARGET],
          stageOfChange: d[FIELDS.STAGEOFCHANGE],
          achievement: d[FIELDS.ACHIEVEMENT],
        };

        dispatch(editSessionProgramFocus(teamId, clinicianId, memberId, d.id, reqBody))
          .then((res) => setaddPFSuccess((prev) => [...prev, res.id]))
          .catch((err) => console.log(err));
      });
      setEditing((p) => !p);
    } else setEditing((p) => !p);
  };
  const cancelEdit = () => {
    setLoading(true);
    const regionToken=getRegion();
    regionToken && regionToken === "us" && TeleKardiaAPI.get(
      `api/v2/teams/${teamId}/clinicians/${clinicianId}/patients/${memberId}/programFocus`,
      // `api/v1/teams/${teamId}/clinicians/${clinicianId}/patients/${patientId}/appointments/${appointmentId}/programFocus`,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        let resultData = res.data.result;
        setData(resultData);
        setValidation(false);
        setEditing((p) => !p);
        setLoading(false);
      })
      .catch((err) => console.log('error- ', err));
  };

  return (
    <Root>
      {loading ? (
        <Loader />
      ) : (
        <Table aria-label="simple table">
          {/* <TableHead className={classes.tableHeader}>
            <TableRow hover>
              
            </TableRow>
          </TableHead> */}
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" width={'70%'}>
                    <Typography variant="subtitle">{row.focus || '--'}</Typography>
                    {!editing && (
                      <Typography
                        variant="body2"
                        style={{
                          marginBottom: '0',
                          fontSize: `${fontSizes.MEDIUM}`,
                          fontFamily: 'Work Sans',
                          color: 'rgb(0, 0, 0, 0.87)',
                        }}
                      >
                        <span
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: '14px',
                            fontFamily: 'Work Sans',
                          }}
                        >
                          Current:{' '}
                        </span>
                        {row[FIELDS.PROGRAMFOCUS_CURRENT]}
                      </Typography>
                    )}
                    {editing && (
                      <TextInputs
                        fullwidth
                        id={`input-1-${row.id}`}
                        label="Current"
                        variant="outlined"
                        value={row[FIELDS.PROGRAMFOCUS_CURRENT]}
                        onChange={(e) =>
                          tableEditingHandler(row.id, e.target.value, FIELDS.PROGRAMFOCUS_CURRENT)
                        }
                      />
                    )}
                    {!editing && (
                      <Typography
                        variant="body2"
                        style={{
                          marginBottom: '0',
                          fontSize: `${fontSizes.MEDIUM}`,
                          fontFamily: 'Work Sans',
                          color: 'rgb(0, 0, 0, 0.87)',
                        }}
                      >
                        <span
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: '14px',
                            fontFamily: 'Work Sans',
                          }}
                        >
                          Target:{' '}
                        </span>
                        {row.target}
                      </Typography>
                    )}

                    {editing && (
                      <TextInputs
                        fullwidth
                        id={`input-2-${row.id}`}
                        label="Target"
                        variant="outlined"
                        value={row[FIELDS.PROGRAMFOCUS_TARGET]}
                        onChange={(e) =>
                          tableEditingHandler(row.id, e.target.value, FIELDS.PROGRAMFOCUS_TARGET)
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {!editing ? (
                      row.stageOfChange ? (
                        <ChipComponent
                          label={row.stageOfChange}
                          color="primary"
                          variant="outlined"
                          style={{ fontSize: `${fontSizes.SMALL}` }}
                        />
                      ) : (
                        '--'
                      )
                    ) : (
                      <FormControl variant="outlined">
                        <Select
                          fullwidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={row[FIELDS.STAGEOFCHANGE] || ''}
                          onChange={(e) =>
                            tableEditingHandler(row.id, e.target.value, FIELDS.STAGEOFCHANGE)
                          }
                          placeholder="Select"
                        >
                          {/* <MenuItem value={'Precontemplation'}>Precontemplation</MenuItem> */}
                          <MenuItem value={'Contemplation'}>Contemplation</MenuItem>
                          <MenuItem value={'Preparation'}>Preparation</MenuItem>
                          <MenuItem value={'Action'}>Action</MenuItem>
                          <MenuItem value={'Maintenence'}>Maintenence</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                  <TableCell>
                    {!editing ? (
                      row.achievement ? (
                        <ChipComponent
                          label={row.achievement}
                          color="secondary"
                          variant="outlined"
                          style={{ fontSize: `${fontSizes.SMALL}` }}
                        />
                      ) : (
                        '--'
                      )
                    ) : (
                      <FormControl variant="outlined">
                        <Select
                          fullwidth
                          labelId="demo-simple-select-label2"
                          id="demo-simple-select2"
                          value={row[FIELDS.ACHIEVEMENT]}
                          onChange={(e) =>
                            tableEditingHandler(row.id, e.target.value, FIELDS.ACHIEVEMENT)
                          }
                          placeholder="Select"
                        >
                          <MenuItem value={'0%'}>0%</MenuItem>
                          <MenuItem value={'25%'}>25%</MenuItem>
                          <MenuItem value={'50%'}>50%</MenuItem>
                          <MenuItem value={'75%'}>75%</MenuItem>
                          <MenuItem value={'100%'}>100%</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {userType === AUTH_TYPE.COACH && data.length > 0 && (
          <Button
            variant="outlined"
            onClick={editing ? cancelEdit : editHandler}
            style={{ margin: '10px' }}
          >
            {editing ? 'Cancel' : 'edit program focus'}
          </Button>
        )}
        {editing && (
          <Button
            variant="contained"
            sx={{ margin: '10px', height: 'fit-content' }}
            onClick={editHandler}
            disabled={!validation}
          >
            save
          </Button>
        )}
      </div>
      {data.length === 0 && <Typography variant="h6">No data available</Typography>}
    </Root>
  );
};

export default ProgramFocusComponent;
