import React from 'react';
import { ColourCodes, ContentDetails, SectionHeader } from './PreviewreportStyling';
import { Box, Grid } from '@mui/material';
import { TemporaryBloodPressureHackedGraph } from 'components/Graphs';
import { CustomComponent } from 'screens/Ecgscreens/Screens';
import systolicimage from '../../assets/images/systolic.png';
import distolicimage from '../../assets/images/Diastolic.png';
import { BP_TYPES, PREVIEW_REPORT_BP_HEADING } from './PreviewreportConst';


/**
 * BloodPressureDetails Component
 * 
 * This component displays blood pressure details, including:
 * - Average Systolic (AVG SYS)
 * - Average Diastolic (AVG DYS)
 * - Recording Period
 * - Number of Recordings
 * 
 * @param {string} heading - The title or heading for the component.
 */

const BloodpressureDetails = ({
  headerText,
  contentheadertext1,
  contentheadertext2,
  completeRecordings,
  contentheadertext1Data,
  contentheadertext2Data,
}) => {
  return (
    <Box sx={{ borderBottom: ColourCodes.lightGrey, marginTop: '12px', paddingBottom: '12px' }}>
      <Box sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
        <SectionHeader title={headerText} textSize={'14px'} margin={'0px'}/>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <ContentDetails
              headertitle={contentheadertext1}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
            <Box>{contentheadertext1Data}</Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <ContentDetails
              headertitle={contentheadertext2}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
            <Box>{contentheadertext2Data}</Box>
          </Box>
        </Grid>
      </Grid>
      {headerText === PREVIEW_REPORT_BP_HEADING.BLODD_PRESSURE_HISTORY && (
        <>
          <Box sx={{ marginTop: '16px' }}>
            {completeRecordings && completeRecordings?.bloodPressure?.recordings && (
              <TemporaryBloodPressureHackedGraph
                completeRecordings={completeRecordings?.bloodPressure?.recordings}
              />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16' }}>
              <CustomComponent
                text={
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      backgroundColor: '#E6F2F0',
                      marginRight: '4px',
                      marginTop: '2px',
                    }}
                  >
                    {' '}
                    <img
                      src={systolicimage}
                      alt="circle icon"
                      style={{ verticalAlign: 'middle', marginRight: '5px' }}
                    />{' '}
                   {BP_TYPES.SYSTOLIC}
                  </span>
                }
              />
              <CustomComponent
                text={
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      backgroundColor: '#FBE8DD',
                      marginTop: '2px',
                    }}
                  >
                    {' '}
                    <img
                      src={distolicimage}
                      alt="circle icon"
                      style={{ verticalAlign: 'middle', marginRight: '5px' }}
                    />{' '}
                    {BP_TYPES.DIASTOLIC}
                  </span>
                }
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default BloodpressureDetails;
