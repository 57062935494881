/* eslint-disable */
import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Home from './App';
import { IntlProvider } from 'react-intl';
import withAuth from 'auth/withAuth';
import { useTranslation } from 'react-i18next';
import { OpenRoutes } from './routes/common';

//@mui premium licence setup
import { LicenseInfo } from '@mui/x-license-pro';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';
import { dispatch } from 'd3';
import { isregionupdated } from 'store/actions/user';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const App = (props) => {
  const { i18n } = useTranslation();

  function getSupportedLocales() {
    if (!process.env.REACT_APP_I18N_SUPPORTED_LOCALES) {
      return ['en'];
    }
    return process.env.REACT_APP_I18N_SUPPORTED_LOCALES.split(',');
  }

  function isSupportedLocale(locale) {
    return getSupportedLocales().includes(locale);
  }

  function getLocale() {
    const browserLocale = navigator.language;
    if (browserLocale && isSupportedLocale(browserLocale)) {
      return browserLocale;
    }

    // fallback to base locale if we have it
    if (browserLocale && browserLocale.indexOf('-') !== -1) {
      // en-US -> 'en' or 'de-DE' -> 'de'
      const baseLocale = browserLocale.split('-')[0];
      if (isSupportedLocale(baseLocale)) {
        console.log(`Using base locale: ${baseLocale}`);
        return baseLocale;
      }
    }
  }

  useEffect(() => {
    let locale = getLocale();
    i18n.changeLanguage(locale);
    }, []);

  return (
    <IntlProvider locale={getLocale()}>
      <Router history={props.history}>
        <Switch>
          {OpenRoutes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
          <Route path="/" component={withAuth(props)(Home)} />
        </Switch>
      </Router>
    </IntlProvider>
  );
};

export default App;
