import React from 'react';
import Spinner from 'components/Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EcgLoader from 'components/Shared/EcgLoader';
import { Grid, Typography } from '@material-ui/core';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import { CustomComponent } from 'screens/Ecgscreens/Screens';
import { Box} from '@mui/material';
import { Dashboard_Utility } from './DashBoardutility';

const Statistics = (props) => {
  // const classes = useStyle();
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.appointments.statsloader);
  return (
    <React.Fragment>
      {isLoading ? (
        <EcgLoader />
      ) : (
        <>
          <Box style={{ height: 'auto', margin: '20px 5px' }}>
            <Grid container spacing={2} style={{ textAlign: 'center', border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "8px", padding: "4px" }}>
              <Grid item xs={12} className="">
                <CustomComponent text={Dashboard_Utility.SESSIONCOMPLETE} variant={"h6"} />
              </Grid>
              <Grid item xs={4} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", height: "100%" }}>
                <CustomComponent text={props.stats.totalAppointments || "-"} variant={"h4"} />
                <CustomComponent text={Dashboard_Utility.TOTAL} variant={"body2lable"} />
              </Grid>
              <Grid item xs={4} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <CustomComponent text={props.stats.totalAppointmentsCompleted || "-"} variant={"h4"} />
                <CustomComponent text={Dashboard_Utility.COMPLETE} variant={"body2lable"} />
              </Grid>
              <Grid item xs={4}>
                <CustomComponent text={props.stats.totalAppointmentsCancelled || "-"} variant={"h4"} />
                <CustomComponent text={Dashboard_Utility.CANCELLED} variant={"body2lable"} />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ margin: '0px 5px' }}>
            <Grid container spacing={2} style={{ textAlign: 'center', border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "8px", padding: "4px" }}>
              <Grid item xs={12} className="">
                <CustomComponent text={Dashboard_Utility.SESSION_BY_TYPE} variant={"h6"} />
              </Grid>
              <Grid item xs={4} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <CustomComponent text={props.stats.totalConsulationGeneral || "-"} variant={"h4"} />
                <CustomComponent text={Dashboard_Utility.GENERAL} variant={"body2lable"} />
              </Grid>
              <Grid item xs={4} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <CustomComponent text={props.stats.totalConsulationDetailed || "-"} variant={"h4"} />
                <CustomComponent text={Dashboard_Utility.DETAILED} variant={"body2lable"} />
              </Grid>
              <Grid item xs={4}>
                <CustomComponent text={props.stats.totalConsulationFollowup || "-"} variant={"h4"} />
                <CustomComponent text={Dashboard_Utility.FOLLOW_UP} variant={"body2lable"} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default Statistics;
