import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { calcRowSeconds } from "components/Utilities";
import EkgRecording from "components/chart/EkgRecording";
import { fetchSamples, fetchSamplesCompareEcg } from "utilities/ApiUrls";
import * as Lib from "../../../constants/RecordingConstants";
import EcgModalTopRecordings from "./EcgModalTopRecordings";
import Patientdetailsdata from "screens/Ecgscreens/Patientdetailsdata";
import EcgHeartratedata from "screens/Ecgscreens/EcgHeartratedata";
import { formatDate, timeFormat } from "utilities/Utilities";
import { ECG_COMPARE_HEADER } from "./EcgCompareConstants";
import Plusicon from "../../../assets/images/PlusIcon.svg";
import Minusicon from "../../../assets/images/Minusicon.svg";
import FullScreenExit from "../../../assets/images/Fullscreenexit.svg";
import GreenCircle from "../../../assets/images/Greencircle.png";
import orangeCircle from "../../../assets/images/LightOragneUpdated.png";
import YelloCircle from "../../../assets/images/YellowCircle.png";
import GreyCircle from "../../../assets/images/LightGreayIcon.png";
import { getEKGAlgorithmDeterminationDisplayText } from "constants/recordingMaps";

const EcgCompareModal = ({
  topFiveRecordings,
  handleNavigation,
  patientData,
}) => {
  let contentHeight = 650;
  const mainContentWidth = 730;
  let ekgHeight = contentHeight;
  const MIN_SECONDS = 1;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0
      ? calcRowSeconds(mainContentWidth)
      : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND;
  const calpulse = true;

  // Component level States
  const [selectedId, setSelectedId] = useState();
  const [sampleData, setSampleData] = useState();
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [crpfilteredvalue, setcrpfilteredvalue] = useState();
  //Data from Redux Store
  const userCountry = useSelector((state) => state.user.profile.country);

  // Sets the ID Based on Selcted ID and Stores the respective ECG Data
  const handleClick = (item) => {
    setSelectedId(item.id);
    setSampleData(item);
    setIsBoxVisible(false);
  };

  const handleBoxclose=()=>{
    setIsBoxVisible(false);
  }

  const handleClickclose = () => {
    setIsBoxVisible(true);
  };

  useEffect(() => {
    if (patientData?.crpReportIds) {
      const recordingId = patientData?.recordings.map((item) => {
        return item.id;
      });
      if (selectedId) {
        if (selectedId in patientData.crpReportIds) {
          setcrpfilteredvalue(patientData.crpReportIds[selectedId]);
        } else {
          setcrpfilteredvalue("--");
        }
      } else {
        console.log("Recording ID is missing");
        setcrpfilteredvalue("--");
      }
    } else {
      console.log("CRP Report IDs are missing in patientData");
    }
  }, [selectedId, patientData]); // This useEffect will run whenever selectedId changes

  useEffect(() => {
    if (selectedId) {
      getSamples(selectedId);
    }
  }, [selectedId]);

  // Function Which gives the Sample Data(Recordings)
  async function getSamples(selectedId) {
    try {
      let data = selectedId && (await fetchSamplesCompareEcg(selectedId));
      data
        ? setSampleData({ ...sampleData, [`enhanced`]: data?.data?.enhanced })
        : setSampleData({ ...sampleData });
    } catch (err) {
      console.log(err);
    }
  }

  const aiDeterminationDetails =
    sampleData &&
    getEKGAlgorithmDeterminationDisplayText(
      sampleData.algorithmDetermination,
      sampleData.algorithmPackage,
      sampleData.bpm,
    );

  const getEKGImage = (aiDeterminationDetail) => {
    if (!aiDeterminationDetail) return GreyCircle; // Default image if value is missing::

    const determinationMapping = {
      ECG_AI_DETERMINATION_VALUES : GreenCircle,
      'Normal Sinus Rhythm' :GreenCircle,
      "Atrial Fibrillation": orangeCircle,
      "Possible Atrial Fibrillation": orangeCircle,
      Unclassified: YelloCircle,
      "No Analysis": GreyCircle,
      Unreadable: GreyCircle,
      Long: GreyCircle,
      Short: GreyCircle,
      'Sinus rythum' :GreyCircle,
      'Sinus Rhythm with Supraventricular Ectopy' :orangeCircle,
      'Sinus Rhythm with Wide QRS' : orangeCircle,
      'Sinus Rhythm with Premature Ventricular Contractions':orangeCircle,
      Bradycardia:orangeCircle,
      Tachycardia:orangeCircle,
      // "Irregular Rhythm": IrregularImage
    };

    return determinationMapping[aiDeterminationDetail];
  };

  return (
    <Box style={{ position: "relative" }}>
      {isBoxVisible ? (
        <Box
          sx={{
            position: "fixed",
            right: "10px",
            bottom: "0px",
            backgroundColor: "#eee",
            borderLeft: "1px solid gray",
            borderTop: "1px solid gray",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            width: "393px",
            height: "172px",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column", // Ensures child elements stack vertically
          }}
        >
          {/* Fixed Header */}
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              borderBottom: "1px solid grey",
              padding: "8px",
              flexShrink: 0,
            }}
          >
            <Box>
              {" "}
              <Plusicon />{" "}
            </Box>
            <Box> {ECG_COMPARE_HEADER.COMPARE_EKG_TO} </Box>
            <ArrowDropDownIcon onClick={handleBoxclose}
              style={{ marginLeft: "auto", borderLeft: "1px solid" }}
            />
          </Box>

          {/* Scrollable List */}
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "130px",
              padding: "8px",
              "&::-webkit-scrollbar": {
                width: "6px", // Adjust the scrollbar width
                height: "2px",
              },
            }}
          >
            <EcgModalTopRecordings
              topFiveRecordings={topFiveRecordings}
              handleClick={handleClick}
              getEKGImage={getEKGImage}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed", // Always fixed on screen
            bottom: "0px", // Distance from the bottom of the viewport :: From Bottom
            right: "25px", // Distance from the right of the viewport
            backgroundColor: "#eee",
            borderLeft: "1px solid gray",
            borderTop: "1px solid gray",
            padding: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            width: "365px", // Fixed width
            zIndex: 1000, 
          }}
        >
          <Box
            sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
            onClick={handleClickclose}
          >
            <Minusicon />
            {selectedId && <img
              style={{ width: "15px", height: "15px", marginTop: "3px" }}
              src={getEKGImage(aiDeterminationDetails)}
            />
}
            
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {aiDeterminationDetails && aiDeterminationDetails.length > 10
                ? aiDeterminationDetails?.split(" ").slice(0, 2).join(" ") +
                  "..."
                : aiDeterminationDetails}
            </Typography>

            <Typography variant="subtitle2">
              {selectedId
                ? `${formatDate(
                    topFiveRecordings.find((item) => item.id === selectedId)
                      ?.recordedAt || "",
                    userCountry,
                  )} ${timeFormat(
                    topFiveRecordings.find((item) => item.id === selectedId)
                      ?.recordedAt || "",
                  )}`
                : "No Recording Selected"}
            </Typography>
            <ArrowDropUpIcon
              style={{ marginLeft: "auto", borderLeft: "1px solid " }}
            />
          </Box>
        </Box>
      )}

      <Box
        onClick={handleNavigation}
        sx={{
          position: "fixed", // Always fixed on screen::
          bottom: "0px", // Distance from the bottom of the viewport::
          right: isBoxVisible ? "404px" : "391px", // Distance from the right, placed beside the first box::
          backgroundColor: " #eee",
          borderLeft: "1px solid grey",
          borderTop: "1px solid grey",
          padding: "10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          // width: "300px", // Fixed width
          zIndex: 1000, 
          cursor: "pointer",
          gap: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>{ECG_COMPARE_HEADER.CLOSE_COMPARISON}</Box>
        <Box>
          <FullScreenExit style={{ height: "12px", width: "12px" }} />
        </Box>
      </Box>

      {/* Display the selected ID ECG Details */}
      {selectedId && sampleData.enhanced && (
         
      
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "16px",
              padding: "12px 0px 12px 0px",
            }}
          >
            <Patientdetailsdata
              labledata={ECG_COMPARE_HEADER.REPORT_ID}
              keydata={crpfilteredvalue}
            />
            <Patientdetailsdata
              labledata={ECG_COMPARE_HEADER.AI_DETERMINATION}
              keydata={aiDeterminationDetails}
            />
            <EcgHeartratedata heartrate={sampleData.bpm} />
            <Patientdetailsdata
              labledata={ECG_COMPARE_HEADER.DATE_RECORDED}
              keydata={
                sampleData?.recordedAt
                  ? `${formatDate(sampleData?.recordedAt, userCountry)} ${timeFormat(
                      sampleData?.recordedAt,
                    )}`
                  : null
              }
            />
          </Box>

          <Box
            sx={{
              // position: 'relative',
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "6px",
              // marginRight: '14px',
            }}
          >
            <Paper
              elevation={4}
              sx={{
                position: "relative",
                // marginRight: "14px",
              }}
            >
              {sampleData.enhanced && (
             
                <EkgRecording
                  height ={400}
                  width={1500}
                  recording={sampleData?.enhanced}
                  rowSeconds={15}
                  calpulse={calpulse}
                  recordingSample={sampleData?.enhanced}
                  inverted={false} //inverted flag would depend on recorded device thus taken from api response
                  sampleData={sampleData}
                  is6l={sampleData.is6l}
                duration={sampleData && (sampleData?.enhanced?.samples?.leadI.length / sampleData.enhanced.frequency) * 1000}
                />
              )}
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EcgCompareModal;
