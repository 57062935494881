export const PREVIEW_REPORT_MODAl={
    AFTER_VISIT_REPORT:'After Visit Report',
    PREPARED_FOR:'Prepared For',
    REVIEWED_BY:'Reviewed By',
    REASON_FOR_CONSULTATION:'Reason For Consultation',
    METHOD_OF_COMMUNICATION:'Method of communication',
    EKG_REVIEW:'EKG Review ',
    CLINICIAN_NOTES:'Clinician’s Notes',
    RECOMENDED_ACTION:'Recommended Action',
    FOLLOW_UP_SENTENCE:'Cardiologist requested a follow-up consultation in',
    CONSULTATION_SUMMARY:'Consultation Summary',
    PATIENT_HISTORY:'Patient History',
   SYMPTOMS: 'Symptoms',
   CLINICIAN_REVIEW:"Clinician Review report overview",
   RESULT_NOT_FOUND:'Report result not found!!!',
   CONSULTATION_DETAILS:'Consultation Details',
   CLINICIAN_DETERMINATION:'Clinician Determination',
   RECORDING_DATE:'Recording Date :',
   REPORT_ID:'Report ID:',
   HEART_RATE:'Heart Rate:',
   BPM:'BPM',
   RECORDED_DATE:' Recorded Date:',
   DEFAULT_FOLLOW_UP_SENTENCE:'Cardiologist requested a follow-up consultation in No Follow-up'
}

export const PREVIEW_REPORT_TYPE={
    EKG:"EKG",
    CLINICIAN:"CLINICIAN",
    BLOOD:"BLOOD"
}

export const PREVIEW_REPORT_BP_HEADING={
    BLODD_PRESSURE_DETAILS:'Blood Pressure Details',
    AVERAGE_SYSTOLIC:'Average Systolic',
    AVERAGE_DIASTOLIC:'Average Diastolic',
    BLODD_PRESSURE_HISTORY:'Blood Pressure 30 Day History',
    RECORDING_PERIOD:'Recording period',
    RECORDING_TAKEN:'Recordings taken',
    HOW_TO_READ_CHART:'How do I read this chart?',
    CHART_DATA:' The above graph shows a 30-day summary of your blood pressure readings. Each circle shows the daily average for your systolic readings (green shaded zone), and each triangle represents the daily average for your diastolic readings (orange shaded zone). The daily average is determined by the sum of your total reading values divided by the number of readings. According to the American Heart Association, the systolic and diastolic range can vary for individuals based on their medical history, medication, and age. If you have any concerns or questions, please consult your physician.'
}


export const getHeaderName = (header) => {
    switch (header) {
      case "BLOOD":
        return "BP Review";
      case "EKG":
        return "EKG Review";
      case "LIFESTYLE":
         return "Lifestyle Review"
      case "CLINICIAN":
         return "Clinician Review"
      default:
        return header; // Return the original value if no match is found
    }
  };
  
  export const PATIENT_DETAILS={
    SEX:'SEX',
    AGE:'Age',
    BMI:'BMI',
    HEIGHT:'Height',
    WEIGHT:'Weight(LBS)'
  }

  export const BP_TYPES={
    SYSTOLIC:'Systolic BP',
    DIASTOLIC:' Diastolic BP'
  }


  export const PATIENT_HISTORY_TYPES = {
    MEDICAL: 'Medical History',
    MEDICATION: 'Medications',
    ALLERGY: 'Allergy',
    SURGERY: 'Surgery',
    FAMILY_HISTORY: 'Family History',
    SOCIAL_HISTORY: 'Social History',
  };

  export const PATIENT_HISTORY_KEYS_MAP = {
    'Medical History': 'medicalHistory',
    Medications: 'medications',
    Allergy: 'allergyDetails',
    Surgery: 'surgeryDetails',
    'Family History': 'familyMedicalHistory',
    'Social History': 'lifeStyleHistory',
  };

  export const kgToLbs = (kg) => {
    return Math.round(kg * 2.20462);;
  };

  

    export const getConsultType=(consult) =>{
      switch (consult) {
        case "Phone Consult":
          return "Phone Call";
        case "Video Consult":
          return "Video Call";
        case  "Audio Consult":
           return "Audio Call";
        default:
          return "--";
      }
    }



