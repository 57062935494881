import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { completed, incoming, pending } from 'constants/app';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { formatDate, toPascalCase, timeFormat, toPascalCaseStringacuity } from 'utilities/Utilities';
import { reviewFieldUpdate } from 'store/actions/EkgReviewActions';
import { CustomComponent } from 'screens/Ecgscreens/Screens';
import ConfirmationModalScreen from 'screens/Ecgscreens/ConfirmationModalScreen';
import { ECG_review_confirmationmodal } from 'screens/Ecgscreens/Ecgconstants';

const RenderSelectDropdown = ({
  setSecondaryDropDownOptions,
  secondaryDropDownOptions,
  errorMessage,
  disabled=false
}) => {
  const { reviewStatus } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleOptionClick = (event) => {
    if (disabled) {
      event.preventDefault();
      handleOpenPopup();
    }
  };
  const [ecgReview, setEcgReview] = useState({
    rhythmId: '',
    acuityId: '',
    qualityId: '',
    notes: '',
  });

  //Data Coming from redux Stor
  const baseRhythm = useSelector((state) => state.ekgReview?.baseRhythm);
  const acuity = useSelector((state) => state.ekgReview?.acuity);
  const quality = useSelector((state) => state.ekgReview?.quality);
  const storedSecondaryValues = useSelector((state) => state.ekgReview?.secondaryvalues);

  const reviewDetails = useSelector((state) => state.ekgReview?.reviewDetail);
  const userCountry = useSelector((state) => state.user.profile.country);

  useEffect(() => {
    dispatch(reviewFieldUpdate(false));
    const rhythmName = baseRhythm.filter((item) => item.rhythmName === reviewDetails?.rhythmName );
    const acuityName = acuity.filter((item) => item.acuity === reviewDetails?.acuityName);
    const qualityName = quality.filter((item) => item.quality === reviewDetails?.quality);

    setEcgReview({
      rhythmId: rhythmName[0]?.id ?  rhythmName[0]?.id : storedSecondaryValues?.rhythmId || '',
      acuityId: acuityName[0]?.id ? acuityName[0]?.id : storedSecondaryValues?.acuityId || '',
      qualityId:qualityName[0]?.id ? qualityName[0]?.id : storedSecondaryValues?.qualityId ||'',
      notes: reviewDetails?.notes ? reviewDetails?.notes : storedSecondaryValues?.notes||  '',
    });
  }, [reviewDetails, baseRhythm, acuity, quality]);

  useEffect(() => {
    setSecondaryDropDownOptions((prev) => ({
      ...prev,
      ...ecgReview,
    }));
  }, [ecgReview]);

  const handleSubmit = (e) => {
    reviewStatus === pending && dispatch(reviewFieldUpdate(true));
    if (reviewStatus === completed) return;
    const { name, value } = e.target;
    // if (value.length <= 1000 || name === "qualityId") {
      setEcgReview((prev) => ({
          ...prev,
          [name]: value,
      }));
  // }
    if (name === 'rhythmId') {
      const filteredIds = baseRhythm.filter((item) => item.id === value);
      setEcgReview((prev) => ({
        ...prev,
        rhythmId: filteredIds[0]?.id,
        acuityId: filteredIds[0]?.acuityId,
        notes: filteredIds[0]?.note,
      }));
    }

  };

  const remainingCharacterCount = () => {
    return 1000 - ecgReview.notes.length + ' characters left';
  };


  return (
    <>
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <FormControl required fullWidth  disabled={disabled}>
          <InputLabel id="demo-simple-select-required-label" onClick={handleOptionClick}>Quality</InputLabel>
          <Select onClick={handleOptionClick}
            value={ecgReview?.qualityId}
            label="Quality"
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            onChange={handleSubmit}
            name="qualityId"
            disabled={disabled}
          >
            {quality.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id}>
                  {toPascalCase(item.quality)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Typography
        color="error"
        variant="body2"
        style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
      >
        {secondaryDropDownOptions.qualityId === '' && errorMessage}
      </Typography>

      <Grid item xs={12}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel required>Base Rhythm </InputLabel>
          <Select onClick={handleOptionClick}
            value={ecgReview?.rhythmId}
            label="Base Rhythm"
            onChange={handleSubmit}
            name="rhythmId"
            disabled={disabled}
          >
            {baseRhythm.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id}>
                  {item.rhythmName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Typography
        color="error"
        variant="body2"
        style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
      >
        {secondaryDropDownOptions.rhythmId === '' && errorMessage}
      </Typography>
      <Grid item xs={12}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel required>Acuity</InputLabel>
          <Select onClick={handleOptionClick}
            value={ecgReview?.acuityId}
            label="Acuity"
            onChange={handleSubmit}
            name="acuityId"
            disabled={disabled}
          >
            {acuity.map((item, i) => {
              return (
                <MenuItem key={i} value={item.id}>
                  {toPascalCaseStringacuity(item.acuity)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Typography
        color="error"
        variant="body2"
        style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
      >
        {secondaryDropDownOptions.acuityId === '' && errorMessage}
      </Typography>

      <Grid item xs={12} >
        <TextField 
          fullWidth
          multiline
          rows={4}
          value={ecgReview?.notes}
          onChange={handleSubmit}
          name="notes"
          label="Notes"
          required
          // maxLength={1000}  
          inputProps={{ maxLength: 1000 }}
          disabled={disabled}
          onClick={handleOptionClick}
        />
       {(reviewStatus === incoming || reviewStatus === pending) && ecgReview?.notes?.length > 0 ? (
          <Typography style={{ color: ' var(--Light-Error-Main, #CC3D3F)' }}>
            {remainingCharacterCount()}
          </Typography>
        ) : null} 
      </Grid>

      <Grid item xs={12}>
        {reviewStatus === completed ? (
          <CustomComponent
            text={`Reviewed submitted ${formatDate(reviewDetails?.timeSubmit , userCountry)}  ${timeFormat(
              reviewDetails?.timeSubmit)} by 
            Dr. ${reviewDetails?.memberLastName} ,${reviewDetails?.memberFirstName}`}
            variant={'subtitle1edit'}
          />
        ) : null}
      </Grid>
    </Grid>
    {open && 
    <ConfirmationModalScreen
    sucessheading={ECG_review_confirmationmodal.READ_THE_ECG}
    handleClosePopup={handleClosePopup} 
    />
    }

    </>
  );

};

export default RenderSelectDropdown;
