import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { fontSizes, spacing } from '../../../style/styleSettings';

import ChipComponent from 'components/Dashboard/SessionComponents/ChipComponent';
import moment from 'moment';
// import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyle = makeStyles({
  root: {
    '& .MuiTypography-body2': {
      fontFamily: 'Work Sans',
      fontSize: 14,
    },
    '& .MuiTypography-h6': {
      fontFamily: 'Work Sans',
      fontSize: 18,
    },
  },
  chipHolder: {
    marginTop: '16px',
    display: 'flex',
    // justifyContent:'space-between'
  },
  holder: {
    marginTop: '10px',
  },
  holder_single: {
    margin: '0 !important',
    display: 'flex',
    '&>:nth-child(1)': {
      fontWeight: 600,
    },
    '&>:nth-child(2)': {
      marginLeft: '10px',
    },
  },
});

function ViewNotes({
  sessionNotes = null,
  programFocus = [],
  heartHabit = [],
  hhDataDeletionNotes = '',
}) {
  const classes = useStyle();
  return (
    <>
      {programFocus.length == 0 &&
      heartHabit.length === 0 &&
      sessionNotes === null &&
      hhDataDeletionNotes === '' ? (
        <Typography variant="h6" style={{ textAlign: 'center', margin: '20px' }}>
          No data to show
        </Typography>
      ) : (
        <Box className={classes.root} style={{ margin: '20px 10px' }}>
          {sessionNotes !== null && sessionNotes?.appointmentNote !== null && (
            <>
              <Typography variant="h6" gutterBottom component="div">
                <b>Session Notes:</b>
              </Typography>
              <Typography variant="body2">{sessionNotes?.appointmentNote}</Typography>
            </>
          )}
          {programFocus && programFocus.length > 0 && (
            <>
              {/* <Typography variant='body1'><b>Get Active, Sleep Improvement, Nutrition</b></Typography> */}
              <Box className={classes.holder}>
                <Grid container spacing={2}>
                  {programFocus.map((item, index) => (
                    <Grid item lg={4} md={4} style={{ padding: '10px 5px' }} key={index}>
                      <Typography variant="body1">
                        <b>{item.focus}</b>
                      </Typography>
                      <Box className={classes.chipHolder}>
                        <ChipComponent
                          variant="outlined"
                          size="small"
                          color="secondary"
                          label={item.achievement || `--`}
                          style={{
                            fontSize: `${fontSizes.SMALL}`,
                          }}
                        />
                        <ChipComponent
                          variant="outlined"
                          size="small"
                          label={item.stageOfChange || `--`}
                          color="primary"
                          style={{
                            marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                            fontSize: `${fontSizes.SMALL}`,
                          }}
                        />
                      </Box>
                      <Box className={classes.holder}>
                        <Typography variant="body2">Current: {item.current || `--`}</Typography>
                        <Typography variant="body2">Target: {item.target || `--`}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          {hhDataDeletionNotes !== '' && (
            <Typography variant="h6" style={{ color: 'red', textAlign: 'center' }}>
              {hhDataDeletionNotes}
            </Typography>
          )}
          {heartHabit && heartHabit.length > 0 && (
            <>
              <Box className={classes.holder}>
                <Grid container spacing={2}>
                  {heartHabit.map((item, index) => (
                    <Grid item lg={4} md={4} style={{ padding: '10px 5px' }} key={index}>
                      <Box className={classes.holder_single}>
                        <Typography variant="body2">{item.focus}:</Typography>
                        <Typography variant="body2">{item.focusNote}</Typography>
                      </Box>
                      <Box className={classes.holder_single}>
                        <Typography variant="body2">Duration:</Typography>
                        <Typography variant="body2">
                          {moment(item.startDate).format('MM-DD-YYYY')} -{' '}
                          {moment(item.endDate).format('MM-DD-YYYY')}
                        </Typography>
                      </Box>
                      <Box className={classes.holder_single}>
                        <Typography variant="body2">Frequency:</Typography>
                        <p>
                          {item.frequencyRecurrencePattern === ''
                            ? ' - '
                            : item.frequencyRecurrencePattern.split('=')[1].split(';')[0]}
                        </p>
                        <p>{item.frequencyDays !== '' && `  (${item.frequencyDays}) `}</p>
                      </Box>
                      <Box className={classes.holder_single}>
                        <Typography variant="body2">Notification:</Typography>
                        <p>
                          {item.notificationRecurrencePattern === ''
                            ? '-'
                            : item.notificationRecurrencePattern.split('=')[1].split(';')[0]}
                        </p>
                        <p>{item.notificationDays !== '' && `  (${item.notificationDays}) `}</p>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
export default ViewNotes;
