import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import moment from 'moment';
import EkgRecording from 'components/chart/EkgRecording';
import Ecgstatus from './Ecgstatus';
import Ecgdevicedata from './Ecgdevicedata';
import EcgHeartratedata from './EcgHeartratedata';
import { LOCAL_STORAGE_SECONDARY_VALUES, pending } from 'constants/app';
import CountdownTimer from 'react-component-countdown-timer';
import { getBaseRythmData, getsecondarystoredvalues } from 'store/actions';
import { storesecondaryDropDownOptions } from 'store/actions/EkgReviewActions';
import { ECG_review_page } from './Ecgconstants';

const EcgHeader = ({
  aiDeterminationDetails,
  devicedetails,
  heartrate,
  navigateBack,
  onScroll,
  patientId,
  secondaryDropDownOptions,
  selectedOptions,
  avblocks,
}) => {
  const { reviewStatus } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let key = 1;
  // Data is coming from redux Store
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const recordingSample = useSelector((state) => state.ekgReview.recordingSample);
  const { timeSubmit } = useSelector((state) => state.ekgReview?.reviewDetail);

  const [avBlockdata, SeavBlocktdata] = useState([]);
  const mapAvBlocksValue = (av) => {
    switch (av) {
      case 'firstdegree':
        return ECG_review_page.FIRST_DEGREE;
      case 'seconddegreeone':
        return ECG_review_page.SECOND_DEGREE_ONE;
      case 'thirddegree':
        return ECG_review_page.THIRD_DEGREE;
      case 'seconddegeentwo':
        return ECG_review_page.SECOND_DEGREE_TWO;
      case 'none':
        return ECG_review_page.NONE;
      default:
        return '';
    }
  };

  useEffect(() => {
    let avBlockValues = [];
    Object.keys(avblocks).forEach((item) => {
      if (avblocks[item] === true) {
        avBlockValues.push(mapAvBlocksValue(item));
      }
      SeavBlocktdata(avBlockValues);
    });
  }, [avblocks]);

  const updatedValues = {
    ...selectedOptions,
    avBlocks: [...avBlockdata],
    ...secondaryDropDownOptions,
  };

  const handleNavigation = () => {
    // We will dispatch the secondary values , To acees it if user navigates to and forth from compare screen
    dispatch(storesecondaryDropDownOptions(updatedValues));
    history.push(`/clinician/ecglist/review/${patientId}/${reviewStatus}/compare`);
  };

  return (
    <Grid item xs={12}>
      <Box style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: '16px' }}>
          <Typography style={{ marginRight: '24px', fontSize: '24px' }}>ECG</Typography>
          <Ecgstatus userStatus={aiDeterminationDetails} />
          <Ecgdevicedata devicedetails={devicedetails} />
          <EcgHeartratedata heartrate={heartrate} />
        </Box>
        {reviewStatus === pending && (
          <Box>
            <Typography
              sx={{ display: 'flex', flexDirection: 'row', columnGap: 1 }}
              style={{ marginLeft: 'auto' }}
            >
              {'Time Remaining: '}
              <CountdownTimer
                responsive
                count={300 - moment().diff(moment(timeSubmit), 'seconds', true)}
                size={15}
                hideDay
                hideHours
                hideTitle
                border
                noPoints={false}
                onEnd={navigateBack}
              />
            </Typography>
          </Box>
        )}
      </Box>

      {/* Compare ECG Button Will be Visible only for Incoming and pending not to the completed ECg's */}
      <Box sx={{ position: 'relative' }}>
        {(reviewStatus === pending || reviewStatus === 'incoming') && (
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              cursor: 'pointer',
              position: 'absolute', // Ensures it's fixed on the screen
              right: recordingSample.is6L ? '1rem' : '0rem', // Adjust based on where you want it
              bottom: recordingSample.is6L ? '1rem' : '0rem',
              backgroundColor: '#eee',
              borderLeft: '1px solid #eee',
              borderTop: '1px solid #eee',
              padding: '8px',
              zIndex: 1000, // Ensures it appears above other elements
              color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
              fontSize: '12px',
              fontWeight: '600',
              display: 'flex',
            }}
            onClick={handleNavigation}
          >
            <Box>Compare ECG</Box>
            <Box>
              <FullscreenIcon />
            </Box>
            <Box></Box>
          </Box>
        )}

        {recordingSample && (
          <EkgRecording
            key={key++}
            height={700}
            width={1500}
            recording={recordingSample}
            recordingSample={recordingSample}
            rowSeconds={15}
            calpulse={true}
            inverted={recordingSample.inverted} // using inverted flag from api response.
            is6l={Object.keys(recordingSample.samples).length === 6}
            duration={patientData?.duration}
            onScroll={onScroll}
          />
        )}
      </Box>
    </Grid>
  );
};

export default EcgHeader;
