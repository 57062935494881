import React, { useCallback, useEffect, useState } from 'react';
import {
  Typography,
  styled,
  useTheme,
  Grid
} from '@mui/material';
// import { Link } from 'react-router-dom';
import { getTimezoneName, parseName, streamToPromise } from 'utilities/Utilities';
import { AppointmentStatusTypes, SessionChannelTypeId } from 'utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import apiservice from 'api/services/apiservice.service';
import moment from 'moment';
import { useHistory } from 'react-router';

import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';
import EcgLoader from 'components/Shared/EcgLoader';
import { StatusColumn } from 'screens/coach/MemberList/components/StatusColumn';
import withStartConsultation from 'components/Dashboard/withStartConsultation';
import { getPatientDetails, isComponentMounted, startAdhocCall } from 'store/actions';
import { getMemberProfileDetails } from 'store/actions/memberProfile';
import { homeScreenButtonText } from './constants/DashBoardConstant';
import { MuiTableComponent } from 'screens/common/CustomUI';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import DoctorDashboardTable from './DoctorDashboardTable';
import CoachDashboardTable from './CoachDashboardTable';
import { getRegion } from 'auth/AuthUtilities';
import { TeleKardiaAPI } from 'Axios/axios';

const DashboardTable = (props) => {
  const theme = useTheme();
  const { userType } = props;
  const history = useHistory();
  const [sessionData, setSessionData] = useState([]);
  const [upcomingSessionInWaitroom, setupcomingSessionInWaitroom] = useState(null);

  // ========= redux state =========== //
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const { patientDetails } = useSelector((state) => state.appointments);
  const isLoading = useSelector((state) => state.appointments.loading);
  const isTeleconnectStarted = useSelector((state) => state.user.isComponentMounted);
  const { isFloatingWindow } = useSelector((state) => state.adhocCall);
  const appointments = useSelector((state) => state.events.events);
  const usertype = useSelector((state) => state.user.chatProfile.userType);
  const regionToken= getRegion()
  const isRegionUpdated = useSelector((state) =>state.user.isregionupdated);
  const userCountry = useSelector((state) => state.user.profile.country);
  //=========== end =========== //
  const [filteredRow, setFilteredRow] = useState([]);

  let offsetInMinutes = new Date().getTimezoneOffset();

  const timezoneValue = getTimezoneName();
  const now = moment();

  const startDateformatted = now.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
  const endDateformatted = now.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');

  /**
   * checks for updated session status from redux store
   * by calling /calendar api which updates event redux store.
   * @returns single array od session whose status is 'member in waitroom'
   */
  useEffect(() => {
    setupcomingSessionInWaitroom(
      appointments.filter((event) => event?.eventType === 'appointment' && event?.status === 12),
    );
  }, [appointments]);

  const getDataForTodaySession = async () => {
    const appointmentRequest =  streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/calendar/?endDate=${endDateformatted}&startDate=${startDateformatted}&offsetInMinutes=${offsetInMinutes}&timeZone=${timezoneValue}&splitBasedOnDays=true`,
      }),
    );
    const appointments = await appointmentRequest;
    if (appointments) {
      // console.log(appointments);
      return appointments.data.appointments;
      // return appointments.data.result.appointments;
    }
  };
  
  useEffect(() => {
    getDataForTodaySession().then((data) => {
      setSessionData(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [openDetailModal, setOpenDetailModal] = useState(false);
  const navigate = (pId, appId, appStatus) => {
    if (
      [
        AppointmentStatusTypes.Scheduled,
        AppointmentStatusTypes.InProgress,
        AppointmentStatusTypes.MemberInWaitingRoom,
        AppointmentStatusTypes.Encounter_Summary_Preparation,
      ].includes(appStatus)
    ) {
      if (userType === AUTH_TYPE.COACH)
        history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${pId}/${appId}`);
      else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}/${appId}`);
    } else {
      if (userType === AUTH_TYPE.COACH) history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${pId}`);
      else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}`);
    }
  };

  const [appId, setAppId] = useState('');
  const [patientName, setPatientName] = useState('');
  const videoCall = useCallback(
    (id, name) => {
      setAppId(id);
      setPatientName(name);
      const params = {
        timeZoneOffset: offsetInMinutes,
        timeZone: getTimezoneName(),
      };
      dispatch(getPatientDetails(teamId, clinicianId, id, params));
    },
    [patientDetails],
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(patientDetails).length > 0 && appId.length > 0) {
      props.alertHandler(patientName);
    }
    // return () => setAppId('');
  }, [patientDetails, appId]);

  const audioCall = (memberId, appointmentId) => {
    dispatch(getMemberProfileDetails(memberId)).then(() => {
      dispatch(startAdhocCall('Call Member', memberId, appointmentId, false));
    });
    completeSession(memberId, appointmentId);
  };

  const completeSession = useCallback((pId, appId) => {
    if (userType === AUTH_TYPE.COACH)
      history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${pId}/${appId}`);
    else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}/${appId}`);
  }, []);


  return (
    <>
      {usertype === AUTH_TYPE.DOCTOR ?
        <DoctorDashboardTable sessionData={sessionData} navigate={navigate} upcomingSessionInWaitroom={upcomingSessionInWaitroom} videoCall={videoCall} completeSession={completeSession} audioCall={audioCall} /> : <CoachDashboardTable sessionData={sessionData} navigate={navigate} moment={moment} upcomingSessionInWaitroom={upcomingSessionInWaitroom} StatusColumn={StatusColumn} AppointmentStatusTypes={AppointmentStatusTypes} SessionChannelTypeId={SessionChannelTypeId} isTeleconnectStarted={isTeleconnectStarted} isFloatingWindow={isFloatingWindow} videoCall={videoCall} audioCall={audioCall} completeSession={completeSession} />
      }

    </>
  )
};

export default withStartConsultation(DashboardTable);
