import React, { useState, useCallback, useEffect } from 'react';
import { useAsync, IfPending, IfFulfilled, IfRejected } from 'react-async';
import Container from '../Container';
import { Flex } from '../Flex';
import FlexItem from '../FlexItem';
import EcgLoader from '../EcgLoader';
import Spacing from '../Spacing';
import MaterialPagination from '../MaterialPagination';
import { getTimezoneName, move, parseName } from 'utilities/Utilities';
import get from 'lodash.get';
import { Note } from './styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiTypography from '../Typography/MuiTypography';
import FilterListIcon from '@material-ui/icons/FilterList';
import Datepicker from '../../Appointments/Calendar/material_datepicker_metrix';
import moment from 'moment';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';

/* eslint-disable */
// import { Body } from "../Typography";
import { createStyles, makeStyles, withStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
  Paper,
  InputBase,
  Grid,
  Button,
  Select,
  FormControl,
  OutlinedInput,
  Popover,
  Divider,
  SvgIcon,
  Box,
  IconButton,
  MenuItem,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core';
import ListTable from './ListTable';
import { streamToPromise } from 'utilities/Utilities';
import apiservice from 'api/services/apiservice.service';
import TitleAlert from '../Alert/TitleAlert';
import Snackbar from '@material-ui/core/Snackbar';
import { downloadCsvReportApi } from 'Services/API/actions';

type Props = {
  feature: String,
  /** The list of entity props to display in the list  */
  entityProps: Array<string>,
  /**
   * Function to load list of entities for the page
   *
   * @param {Object} filter An object with each property corresponding to a filter name, and the value the select one
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  loadAction: Promise<Array>,
  /** In container, wrap with withRouter to get history and privileges */
  history?: {
    push: Function, // eslint-disable-line
  },
  /** The table headers, component will looks up in translation files references*/
  headers?: Array<string>,
  /**
   * Function to add an entity
   *
   * @param {Object} history Router history object
   */
  addAction?: Function,
  /**
   * Function to edit an entity
   *
   * @param {Object} entity Entity to edit
   * @param {Object} history Router history object
   */

  viewFeedbackDetailsAction?: Function,
  /**
   * Function to drilldown on an entity
   *
   * @param {Object} entity Entity to edit
   * @param {Object} history Router history object
   */
  drillDownAction?: Function,
  /**
   * Function to delete an entity and refresh page
   *
   * @param {Object} entity Entity to remove
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  deleteAction?: Promise<Array>,
  /**
   * Function to save inline edited entity and refresh page
   *
   * @param {Object} entity Modified entity to save
   * @param {string} searchInput String to search, empty to return akl results
   * @param {number} pageNumber When paging, this is the page number
   */
  inlineEditAction?: Promise<Array>,
  /**
   * Function to delete an entity and refresh page
   *
   * @param {Array<Object>} sortedEntities entities with new sort order (sort sent is based on array order, provided function should compare to sort field and update if required)
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  sortAction?: Promise<Array>,
  /** Prop name containing the toggle field, must be a string */
  toggleField?: Boolean,
  /** Prop name containing the favorite field, must be a string */
  favoriteField?: Boolean,
  /** Page title */
  title?: string,
  /** Boolean to set whether we support search */
  searchEnabled?: Boolean,
  sortordertype: String,
  sortPropsName: String,
  /** Filters using select, each filter object should have a "name" property (string) and an "options" property (Array). Each option object should have a "value" and a "label" property */
  filters?: Array<Object>,
  /** Custom renderer for field, property should be the same as the name of the field, and value
   * should be a function. The special "_edit" prop is for deciding whether to display edit button or not  */
  render?: { [string]: Function },
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      'label + &': {
        marginTop: theme.spacing(3),
        fontSize: 16,
        color: '#000 !important',
        width: '100%',
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      borderBottom: '1px solid #d8d8d8',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['inherit'].join(','),
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 0,
    '& .MuiTableCell-root': {
      fontSize: '14px',
      fontFamily: 'Work Sans !important',
      fontWeight: 400,
    },

    '& .MuiTableCell-stickyHeader': {
      background: '#F1F2F3',
      borderBottom: '1px solid #B4BBC0',
      fontWeight: 600,
      zIndex: 2,
    },
  },
  searchbarroot: {
    padding: '2px 4px',
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    alignItems: 'center',
    background: 'rgba(118,118,128,0.12)',
  },
  paperoot: {
    // display: 'flex',
    // flexWrap: 'wrap',
    '& > *': {
      // margin: theme.spacing(1),
      width: theme.spacing(100),
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  container: {
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  formControlsmall: {
    background: '#FFFFFF',
    minWidth: 120,
    maxWidth: 300,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  smallButton1: {
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    color: 'black',
    padding: '10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
    marginTop: '25px',
  },
  smallButton2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    color: 'black',
    padding: '10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
    marginTop: '15px',
  },
  formControllabelstyle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'rgba(20, 42, 57, 0.72)',
  },
  sortingGridItem: {
    margin: '0 20px',
  },
  grid: {
    marginLeft: '24px',
  },
  popovergrid: {
    padding: theme.spacing(2),
    background: 'white',
  },
  // formControl:{
  //   width:"300px"
  // }
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    // "& $notchedOutline": {
    //   borderColor: "red"
    // },
    color: '#000000',
    backgroundColor: '#ffffff',
    '&:hover $notchedOutline': {
      color: '#2D9F86',
      borderColor: '#2D9F86',
    },
    '&$focused $notchedOutline': {
      color: '#000000',
      borderColor: '#2D9F86',
    },
  },
  input: {
    background: '#ffffff',
    '&:hover': {
      color: '#2D9F86',
    },
    '&$focused': {
      color: '#2D9F86',
    },
    fontSize: 16,
    padding: '10px 26px 10px 12px',
  },
  focused: {},
  notchedOutline: {},
}));

const divStyle = {
  color: 'rgba(20, 42, 57, 0.72)',
};

const buttonstyle = {
  color: '#F25F5F',
};
const userError = (error, lastAction) => {
  switch (lastAction) {
    case 'DELETE':
      return 'Error DeletingData';
    case 'ENABLE':
      return 'Error SavingData';
    case 'FAVORITE':
      return 'Error SavingData';
    case 'SORT':
      return 'Error Sorting Data';
    case 'SEARCH':
      return 'Error Loading Data';
    case 'FILTER':
      return 'Error Loading Data';
    default:
      return 'Error Loading Data';
  }
};
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const ListPageFeedbacks = (props: Props) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const {
    history,
    headers,
    entityProps,
    // feature,
    loadAction,
    addAction,
    deleteAction,
    viewFeedbackDetailsAction,
    drillDownAction,
    uploadappointmentsAction,
    inlineEditAction,
    sortAction,
    toggleField,
    favoriteField,
    title,
    render,
    searchEnabled,
    watch,
    sortordertype,
    sortPropsName,
    teamId,
    clinicianId,
    value,
    userCountry,
    regions
  } = props;

  const csvLink = React.createRef();

  const [searchInput, setSearchInput] = useState('');
  const [lastAction, setLastAction] = useState('LOAD');
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({});
  const [, setFilterValues] = useState({});

  const [status, setStatus] = React.useState('1,2,3,4,5,6,7,8,9,10');
  const [statusList, setstatusList] = React.useState([]);

  const [cardiologistvalue, setcardiologistvalue] = useState('All');
  const [cardiologistList, setcardiologistList] = React.useState([]);
  const mediawidth = useWidth();

  const inputLabel1 = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const inputLabel3 = React.useRef(null);

  const [label1Width, setLabel1Width] = React.useState(0);
  const [label2Width, setLabel2Width] = React.useState(0);
  const [label3Width, setLabel3Width] = React.useState(0);

  const [isSelectorOpen, setisSelectorOpen] = useState(false);

  const [invaliddatealert, setinvaliddatealert] = useState(false);
  const [invaliddatealertmessage, setinvaliddatealertmessage] = useState('Invalid Date Time');
  const [snackbaropen, setsnackbaropen] = React.useState(false);

  const [startdateformatted, setstartDateformatted] = useState('');
  const [enddateformatted, setenddateformatted] = useState('');

  const [dateselectionmenuitemtext, setdateselectionmenuitemtext] = useState('Select');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendtDate] = useState(new Date());

  const _cardiologistchangeaction = useCallback(
    async (event) => {
      setLastAction('FILTER');
      let memberidvalue = event.target.value;
      setFilter({ ...filter, memberId: memberidvalue });
      setcardiologistvalue(memberidvalue);
    },
    [filter, run, searchInput],
  );
  const onsubmitfilter = useCallback(async () => {
    setLastAction('FILTER');
    setFilter({
      ...filter,
      startDate: startdateformatted,
      endDate: enddateformatted,
    });
    return run('FILTER', {
      filter: {
        ...filter,
        startDate: startdateformatted,
        endDate: enddateformatted,
        memberId: cardiologistvalue,
      },
      take: pageSize,
    });
  }, [filter, run, startdateformatted, enddateformatted, cardiologistvalue]);

  const loadLookupAction = useCallback(async () => {
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/appointment-status`,
      }),
    );
    const appointmentstatuslookup = await appointmentRequest;
    setstatusList(appointmentstatuslookup.data.result);
    let cardiologistlistRequest
    if(regions !== undefined){
      cardiologistlistRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/clinicians?countryIds=${userCountry}&regionIds=${regions}`,
        }),
      );
      const cardiologistlistlookup = await cardiologistlistRequest;
      setcardiologistList(cardiologistlistlookup.data.result);
    } 
   
  }, []);

  useEffect(() => {
    loadLookupAction();
    // setLabel1Width(inputLabel1.current.offsetWidth);
    //setLabel2Width(inputLabel2.current.offsetWidth);
  }, []);
  const handlesnackbarClose = () => {
    setsnackbaropen(false);
  };
  const _daterangeChangeAction = useCallback(async () => {
    setLastAction('FILTER');
    setFilter({
      ...filter,
      startDate: startdateformatted,
      endDate: enddateformatted,
    });
    return run('FILTER', {
      filter: {
        ...filter,
        startDate: startdateformatted,
        endDate: enddateformatted,
      },
      searchInput: searchInput,
      take: pageSize,
    });
  }, [filter, run, searchInput, startdateformatted, enddateformatted]);
  const _daterangeresetAction = useCallback(
    async (startdatefrmtd, enddatefrmtd) => {
      setLastAction('FILTER');
      setFilter({
        ...filter,
        startDate: startdatefrmtd,
        endDate: enddatefrmtd,
      });
      return run('FILTER', {
        filter: { ...filter, startDate: startdatefrmtd, endDate: enddatefrmtd },
        searchInput: searchInput,
        take: pageSize,
      });
    },
    [filter, run, searchInput],
  );
  const handleEndDateChange = (date) => {
    const eodpattern = 'YYYY-MM-DDT23:59:00.000';
    setendtDate(date);
    let tempdt2 = moment(endOfDay(date)).format(eodpattern);
    setenddateformatted(tempdt2);
  };
  const handleStartDateChange = (date) => {
    const pattern = 'YYYY-MM-DDT00:00:00.000';
    setstartDate(date);
    let tempdt = moment(startOfDay(date)).format(pattern);
    setstartDateformatted(tempdt);
  };

  const handlepopoverokayclick = () => {
    setinvaliddatealertmessage(null);
    setinvaliddatealert(false);
    const pattern = 'YYYY-MM-DDT00:00:00.000';
    const eodpattern = 'YYYY-MM-DDT23:59:00.000';
    if (startDate === null || endDate === null) {
      setinvaliddatealertmessage('Invalid Date Time');
      setinvaliddatealert(true);
    } else if (startDate > endDate) {
      setinvaliddatealert(true);
      setinvaliddatealertmessage('Start date should not be greater than End date');
    } else if (startDate !== null && endDate !== null) {
      setinvaliddatealert(false);
      let tempdt1 = moment(startDate).format(pattern);
      let tempdt2 = moment(endDate).format(eodpattern);
      setstartDateformatted(tempdt1);
      setenddateformatted(tempdt2);
      setdateselectionmenuitemtext(
        `${moment(startDate).format('MM-DD-YYYY')} - ${moment(endDate).format('MM-DD-YYYY')}`,
      );
      setisSelectorOpen(false);
      setAnchorEl(null);
    }
  };

  const handlepopovercancelclick = () => {
    setisSelectorOpen(false);
    setstartDate(new Date());
    setendtDate(new Date());
    setstartDateformatted('');
    setenddateformatted('');
    setinvaliddatealert(false);
    setAnchorEl(null);
  };

  const handlepopoverresetclick = () => {
    // const pattern = "YYYY-MM-DDT00:00:00.000";
    // const eodpattern = "YYYY-MM-DDT23:59:00.000";
    // let tempdt1 = moment(startOfDay(new Date())).format(pattern);
    // let tempdt2 = moment(endOfDay(new Date())).format(eodpattern);
    setstartDate(new Date());
    setendtDate(new Date());
    // setstartDateformatted(tempdt1);
    // setenddateformatted(tempdt2);
    setstartDateformatted('');
    setenddateformatted('');
    setinvaliddatealert(false);
    setdateselectionmenuitemtext('Select');
    // _daterangeresetAction(tempdt1, tempdt2);
    setisSelectorOpen(false);
    setAnchorEl(null);
  };

  const popoveropen = Boolean(anchorEl);
  const popoverid = popoveropen ? 'simple-popover' : undefined;
  // const isMounted = useRef(true);

  const modifyAction = async (params) => {
    const [action, newArgs] = params;

    switch (action) {
      case 'DELETE':
        return deleteAction(newArgs);
      case 'ENABLE':
        return inlineEditAction(newArgs);
      case 'FAVORITE':
        return inlineEditAction(newArgs);
      case 'SORT':
        return sortAction(newArgs);
      case 'SEARCH':
        return loadAction(newArgs);
      case 'FILTER':
        return loadAction(newArgs);
      default:
        throw new Error('unhandled action');
    }
  };

  // Fetch data
  const listState = useAsync({
    promiseFn: loadAction,
    deferFn: modifyAction,
    watch,
  });
  const { run, setData, isLoading, error, setError } = listState;

  /** Change rows per page */
  const onSizeChange = useCallback(
    async (event) => {
      const rowsPerPage = +event.target.value;
      setPageSize(rowsPerPage);
      setLastAction('SEARCH');
      return run('SEARCH', {
        filter,
        searchInput,
        pageNumber: 1,
        take: rowsPerPage,
      });
    },
    [filter, run, searchInput],
  );

  /** Search input functions */
  // const onSearchClick = useCallback(async () => {
  //   setLastAction("SEARCH");
  //   return run("SEARCH", { filter, searchInput });
  // }, [filter, run, searchInput]);

  // const onSearchClick = useCallback(async () => {
  //   setLastAction("SEARCH");
  //   return run("SEARCH", {
  //     searchInput: searchInput,
  //     filter: filter,
  //   });
  // }, [filter, run, searchInput]);

  // const onClear = useCallback(async () => {
  //   setSearchInput("");
  //   setLastAction("SEARCH");
  //   return run("SEARCH", {
  //     searchInput: "",
  //     filter: filter,
  //   });
  // }, [filter, run]);

  // const updateSearchInputValue = (evt) => {
  //   return setSearchInput(evt.target.value);
  // };

  const onChangePage = useCallback(
    async (newPage: number) => {
      setLastAction('SEARCH');
      return run('SEARCH', {
        filter,
        searchInput,
        take: pageSize,
        pageNumber: newPage,
      });
    },
    [filter, run, searchInput, pageSize],
  );

  /** Action functions */
  // const _addAction = useCallback(async () => {
  //   setAddLoading(true);
  //   try {
  //     await addAction({
  //       history,
  //       reload: () => {
  //         if (isMounted.current) setAddLoading(false);
  //         return run("SEARCH");
  //       },
  //     });
  //   } catch (e) {
  //     setError(e);
  //   }
  // }, [addAction, history, run, setError]);

  const _statusChangeAction = useCallback(
    async (event) => {
      setStatus(event.target.value);
      setLastAction('FILTER');
      setFilterValues(event.target.value);
      let statusvalue = event.target.value;
      setFilter({ ...filter, status: statusvalue });
      return run('FILTER', {
        filter: { ...filter, status: statusvalue },
        searchInput: '',
        take: pageSize,
      });
    },
    [filter, run, searchInput],
  );

  const _drillDownAction = useCallback(
    (entity) =>
      drillDownAction
        ? async () => {
            setLastAction('DRILLDOWN');
            return drillDownAction({ entity, history });
          }
        : null,
    [drillDownAction, history],
  );

  const _uploadappointmentsAction = useCallback(
    (entity) =>
      uploadappointmentsAction
        ? async () => {
            setLastAction('EDIT');
            return uploadappointmentsAction({ entity, history });
          }
        : null,
    [uploadappointmentsAction, history],
  );

  const _viewFeedbackDetailsAction = useCallback(
    (entity) =>
      viewFeedbackDetailsAction
        ? async () => {
            setLastAction('EDIT');
            return viewFeedbackDetailsAction({ entity, history });
          }
        : null,
    [viewFeedbackDetailsAction, history],
  );

  // const reloadAction = useCallback(async () => {
  //   setSearchInput("");
  //   setLastAction("SEARCH");
  //   return run("SEARCH", {
  //     searchInput: "",
  //     filter: filter
  //   });
  // }, [filter, run]);

  const _deleteAction = useCallback(
    (entity, entities) =>
      deleteAction
        ? async () => {
            if (
              window.confirm('Are you sure you want to delete this record?') // NOSONAR
            ) {
              setLastAction('DELETE');
              return deleteAction({ entity, history });
            }
            return;
          }
        : null,
    [deleteAction, filter, run, searchInput, setData],
  );

  const _toggleAction = useCallback(
    (entity, entities) =>
      inlineEditAction
        ? async () => {
            setLastAction('ENABLE');
            // setData({
            //   data: entities.data.map(x => {
            //     if (
            //       (x.id && x.id === entity.id) ||
            //       (x.key && x.key === entity.key)
            //     ) {
            //       x[toggleField] = !entity[toggleField];
            //       return x;
            //     }
            //     return x;
            //   }),
            //   meta: entities.meta
            // });
            return run('ENABLE', {
              filter,
              entity,
              searchInput,
              pageNumber: entities.meta && entities.meta.currentPage,
            });
          }
        : null,
    [filter, inlineEditAction, run, searchInput, setData, toggleField],
  );

  const _favoriteAction = useCallback(
    (entity, entities) =>
      inlineEditAction
        ? async () => {
            setLastAction('FAVORITE');
            setData({
              data: entities.data.map((x) => {
                if (x.id === entity.id) {
                  x[favoriteField] = !entity[favoriteField];
                  return x;
                }
                return x;
              }),
              meta: entities.meta,
            });
            return run('FAVORITE', {
              filter,
              entity,
              searchInput,
              pageNumber: entities.meta && entities.meta.currentPage,
            });
          }
        : null,
    [favoriteField, filter, inlineEditAction, run, searchInput, setData],
  );

  const _sortAction = useCallback(
    (entities) =>
      sortAction
        ? async ({ oldIndex, newIndex }) => {
            setLastAction('SORT');
            const newEntities = move(entities.data, oldIndex, newIndex);
            setData({ data: newEntities, meta: entities.meta });
            return run('SORT', {
              filter,
              entities: newEntities,
              searchInput,
              pageNumber: entities.meta && entities.meta.currentPage,
            });
          }
        : null,
    [filter, run, searchInput, setData, sortAction],
  );

  const _sortHeaderAction = useCallback(
    async (prps) => {
      setLastAction('FILTER');
      return run('FILTER', {
        sortprops: prps,
        searchInput: '',
        take: pageSize,
      });
    },
    [filter, run, searchInput],
  );
  const handlestoppropogationChange = (event) => {
    event.stopPropagation();
    // set your value
  };
  const handlepopoveropenClick = (event) => {
    setisSelectorOpen(true);
    setAnchorEl(event.currentTarget);
  };

  // const _sortHeaderAction = async (prps) => {
  //   setLastAction("SORT");
  //   console.log("sort props",prps);

  //   return run("SORT", {
  //     filter: prps,
  //     searchInput: "",
  //     take: pageSize,
  //   });
  // };

  const [csvData, setcsvData] = useState([]);
  const downloadCsvReport = () => {
    let queryparams = {
      showType: '',
      memberId: cardiologistvalue,
      surveyStatus: 2,
      endDateTime: enddateformatted,
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      startDateTime: startdateformatted,
      countryIds: userCountry,
      regionIds: regions && regions,
    };
    if(regions !== undefined){
      downloadCsvReportApi(teamId, clinicianId, queryparams).subscribe(
        (res) => {
          setcsvData(res.data);
        },
        (error) => {
          console.log(error);
          setcsvData([
            {
              ERROR: error,
            },
          ]);
        },
      );
    }
   
  };
  useEffect(() => {
    if (csvData && csvData.length > 0 && csvLink && csvLink.current) csvLink.current.link.click();
  }, [csvData,regions]);

  return (
    <>
      {/* <Container xlarge> */}
      {(addAction || title) && <Spacing height="20px" />}
      <Grid
        alignItems="flex-start"
        spacing={0}
        container
        justify="flex-start"
        style={{ display: '-ms-flexbox', background: '#fff' }}
      >
        <Grid item>
          <InputLabel ref={inputLabel2} className={classes.formControllabelstyle}>
            Provider
          </InputLabel>
          <FormControl
            variant="outlined"
            className={
              mediawidth === 'xl' || mediawidth === 'lg'
                ? classes.formControl
                : classes.formControlsmall
            }
          >
            <Select
              className="not-rounded"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cardiologistvalue}
              input={
                <OutlinedInput
                  labelWidth={0}
                  // labelWidth={label2Width}
                  name="status"
                  id="outlined-status-simple"
                  classes={outlinedInputClasses}
                />
              }
              onChange={_cardiologistchangeaction}
            >
              <MenuItem value={'All'}>
                <MuiTypography
                  fontFamily="Work Sans"
                  fontSize="16px"
                  fonStyle="normal"
                  fonWeight="400"
                  lineHeight="20px"
                  color="#142A39"
                >
                  All
                </MuiTypography>
              </MenuItem>
              {cardiologistList &&
                cardiologistList.length > 0 &&
                cardiologistList.map((item, index) => (
                  <MenuItem
                    value={item.memberId.toString()}
                    classes={{ root: classes.menuitemroot }}
                    key={index}
                  >
                    <MuiTypography
                      fontFamily="Work Sans"
                      fontSize="16px"
                      fonStyle="normal"
                      fonWeight="400"
                      lineHeight="20px"
                      color="#142A39"
                    >
                      {parseName(item?.firstName, item?.lastName)}
                    </MuiTypography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <InputLabel ref={inputLabel3} className={classes.formControllabelstyle}>
            Date range
          </InputLabel>
          <FormControl
            variant="outlined"
            className={
              mediawidth === 'xl' || mediawidth === 'lg'
                ? classes.formControl
                : classes.formControlsmall
            }
          >
            <Select
              labelId="demo-simple-select-label-3"
              id="demo-simple-select-3"
              value={'0'}
              input={
                <OutlinedInput
                  labelWidth={0}
                  name="status"
                  id="outlined-status-simple"
                  classes={outlinedInputClasses}
                />
              }
              // onClose={handlepopoverClose}
              // onClick={handlepopoveropenClick}
              open={isSelectorOpen}
              onChange={handlestoppropogationChange}
              onOpen={handlepopoveropenClick}
            >
              <MenuItem value={'0'}>
                <MuiTypography
                  fontFamily="Work Sans"
                  fontSize="16px"
                  fonStyle="normal"
                  fonWeight="400"
                  lineHeight="20px"
                  color="#142A39"
                >
                  {dateselectionmenuitemtext}
                </MuiTypography>
              </MenuItem>
              <MenuItem value={'1'}>
                {' '}
                <Popover
                  id={popoverid}
                  open={popoveropen}
                  anchorEl={anchorEl}
                  // onClose={handlepopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Grid
                    container
                    justify="flex-end"
                    direction="column"
                    className={classes.popovergrid}
                  >
                    {invaliddatealert && (
                      <Grid item>
                        <TitleAlert title={invaliddatealertmessage} />
                      </Grid>
                    )}
                    <Grid item>
                      <MuiTypography
                        fontSize="12px"
                        fontFamily="Work Sans"
                        lineHeight="16px"
                        letterSpacing="0.4px"
                        color="rgba(20, 42, 57, 0.72)"
                      >
                        Start Date
                      </MuiTypography>
                      <Datepicker
                        label="Start Date"
                        date={startDate}
                        changeHandle={handleStartDateChange}
                      />
                    </Grid>
                    <Grid item>
                      <MuiTypography
                        fontSize="12px"
                        fontFamily="Work Sans"
                        lineHeight="16px"
                        letterSpacing="0.4px"
                        color="rgba(20, 42, 57, 0.72)"
                      >
                        End Date
                      </MuiTypography>
                      <Datepicker
                        label="End Date"
                        date={endDate}
                        changeHandle={handleEndDateChange}
                      />
                      <Divider />
                    </Grid>

                    <Grid item container justify="space-between" direction="row">
                      <Grid item>
                        <Button style={buttonstyle} onClick={handlepopoverresetclick}>
                          RESET
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={handlepopovercancelclick}>CANCEL</Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={handlepopoverokayclick}>OK</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Popover>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton
            className={
              mediawidth === 'xl' || mediawidth === 'lg'
                ? classes.smallButton1
                : classes.smallButton2
            }
            onClick={onsubmitfilter}
            aria-label="Search"
          >
            <FilterListIcon />
          </IconButton>
        </Grid>

        {/* export option not needed in  service portal-- thus commented out */}
        <Grid item>
          <CSVLink
            filename={'Feedback-Report.csv'}
            target="_blank"
            ref={csvLink}
            data={csvData}
          ></CSVLink>
          <IconButton
            onClick={downloadCsvReport}
            aria-label="Feedback CSV"
            style={{ margin: '20px 10px', backgroundColor: 'lightgrey' }}
          >
            <GetAppIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        {isLoading && ['DELETE', 'SORT', 'ENABLE'].includes(lastAction) && (
          <Note>
            <EcgLoader />
          </Note>
        )}
        {/* {!isLoading && (
                <Note>
                  <RefreshButton
                    type="button"
                    top="0"
                    onClick={() => onSearchClick()}
                  />
                </Note>
              )} */}

        <IfPending state={listState} initial={true}>
          <Container>
            <Spacing padding="80px">
              <EcgLoader />
            </Spacing>
          </Container>
        </IfPending>

        {isLoading && ['SEARCH', 'FILTER'].includes(lastAction) && (
          <Container>
            <Spacing padding="80px">
              <EcgLoader />
            </Spacing>
          </Container>
        )}

        {/*Display when there was an error with data*/}
        <IfRejected state={listState}>
          {(
            error, // NOSONAR
          ) => (
            <Flex justify="center">
              <Flex direction="column" justify="center" textAlign="center">
                <FlexItem>
                  <Spacing padding="50px">{userError(error, lastAction)}</Spacing>
                </FlexItem>
              </Flex>
            </Flex>
          )}
        </IfRejected>

        {/*Display when data was retrieved, persist for optimistic updates*/}
        <IfFulfilled state={listState} persist={true}>
          {(entities) =>
            (entities.data.length === 0 && !isLoading && (
              <Flex justify="center">
                <Spacing padding="20px">{'No Data'}</Spacing>
              </Flex>
            )) ||
            ((!isLoading || !['SEARCH', 'FILTER'].includes(lastAction)) && !error && (
              <>
                <ListTable
                userCountry={userCountry}
                  feature="Clinics"
                  {...props}
                  onSortEnd={_sortAction(entities)}
                  headings={headers}
                  sortordertype={sortordertype}
                  sortPropsName={sortPropsName}
                  sortHeaderAction={_sortHeaderAction}
                  items={entities.data.map((entity, index) => ({
                    entityValues: entityProps.map((prop) =>
                      render && render[prop] ? render[prop](get(entity, prop)) : get(entity, prop),
                    ),
                    toggleChecked:
                      toggleField && inlineEditAction
                        ? entity[toggleField] || typeof entity[toggleField] === 'undefined'
                        : null,
                    favoriteChecked:
                      favoriteField && inlineEditAction ? !!entity[favoriteField] : null,

                    drillDownAction: _drillDownAction(entity),
                    uploadappointmentsAction: _uploadappointmentsAction(entity),
                    deleteAction: _deleteAction(entity, entities),
                    onToggleChange: _toggleAction(entity, entities),
                    onFavoriteChange: _favoriteAction(entity, entities),
                    viewFeedbackDetailsAction: _viewFeedbackDetailsAction(entity),
                    isLoading,
                  }))}
                />
                <Flex justify="flex-end">
                  <MaterialPagination
                    pageSize={pageSize}
                    current={entities && entities.meta && entities.meta.currentPage}
                    total={entities && entities.meta && entities.meta.count}
                    onChange={onChangePage}
                    onSizeChange={onSizeChange}
                  />
                </Flex>
              </>
            ))
          }
        </IfFulfilled>
      </Paper>
      {/* </Grid> */}
      {/* </Grid> */}
      {/* </Container> */}
    </>
  );
};

export default ListPageFeedbacks;
