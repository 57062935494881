import axios from 'axios';
import * as types from './actionTypes';
import { CrpAPI, CrpAPIEU } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION, incoming } from 'constants/app';

// const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);

const getUnifiedPortalRegion = () => localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);

// let apiInstance= regionToken && regionToken === "us" ? CrpAPI : CrpAPIEU

const getCrpAPIInstance = () => {
  const currentRegion = getUnifiedPortalRegion();
  // default to US API instance
  return currentRegion === "eu" ? CrpAPIEU: CrpAPI;

}
export const submitPatientReportId = (reportId) => {
  return {
    type: types.SUMBITPATIENTREPORTID,
    payload: reportId,
  };
};

export const showToastHandler = (isShow) => {
  return {
    type: types.SHOWTOAST,
    payload: isShow,
  };
};

export const emptyPatientData = () => {
  return {
    type: types.EMPTYPATIENTDATA,
  };
};

export const setDefaultSubmitStatus = () => {
  return {
    type: types.SETDEFAULTSUBMITSTATUS,
  };
};

export const setIsLoadingPatientData = (boolean) => {
  return {
    type: types.IS_LOADING_PATIENT_DATA,
    payload: boolean,
  };
};

export const setIsSubmittingReview = (boolean) => {
  return {
    type: types.IS_SUBMITTING_REPORT,
    payload: boolean,
  };
};

export const setIsErrorGettingPatientData = (status) => {
  return {
    type: types.IS_ERROR_GETTING_PATIENT_DATA,
    payload: status,
  };
};

export const setIsErrorGettingBaseRhythmData = (boolean) => {
  return {
    type: types.IS_ERROR_GETTING_RHYTHM_DATA,
    payload: boolean,
  };
};

export const setIsErrorGettingAcuityData = (boolean) => {
  return {
    type: types.IS_ERROR_GETTING_ACUITY_DATA,
    payload: boolean,
  };
};

export const setIsErrorGettingQualityData = (boolean) => {
  return {
    type: types.IS_ERROR_GETTING_ACUITY_DATA,
    payload: boolean,
  };
};

export const isLoadingCancelReview = (boolean) => {
  return {
    type: types.IS_LOADING_CANCEL_REVIEW,
    payload: boolean,
  };
};

export const setReviewErrorMessage = (payload) => {
  return {
    type: types.REVIEW_ERROR_MESSAGE,
    payload,
  };
};

export const reviewFieldUpdate = (boolean) => {
  return {
    type: types.IS_REVIEW_FIELD_UPDATED,
    payload: boolean,
  };
};

export const storesecondaryDropDownOptions = (data) => {
  return {
    type: types.SECONDARY_DROP_VALUES_STORE,
    payload: data,
  };
};

export const emptysecondaryvalues = () => {
  return {
    type: types.EMPTY_SECONDARY_VALUES,
  };
};

export const isEcgscrolled=(boolean)=>{
  return{
    type: types.IS_ECG_SCROLLED,
    payload:boolean
  }
}

export const getPatientData = (id, status ,isregionupdated) => {
  const boolean = status === incoming ? false : true;
  const apiInstance= getCrpAPIInstance();
  return (dispatch) => {
    dispatch(setIsLoadingPatientData(true));
    apiInstance.get(`v1/patientDetail/${id}?complete=${boolean}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      // cancelToken: sourceToken,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setIsLoadingPatientData(false));
          dispatch(setIsErrorGettingPatientData(''));
          dispatch({
            type: types.GET_PATIENTDATA_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(setIsLoadingPatientData(false));
        if (axios.isCancel(err)) {
          return;
        }
        if (err.response && err.response.data) {
          dispatch(setIsErrorGettingPatientData(err.response.data.error.devMsg.slice(0, 8)));
          return;
        }
        dispatch(setIsErrorGettingPatientData('error'));
      });
  };
};

export const getBaseRythmData = (isregionupdated) => {
  const apiInstance= getCrpAPIInstance();
  return (dispatch) => {
    apiInstance.get(`v1/rhythm`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETBASERHYTHM,
            payload: res.data.data,
          });
          dispatch(setIsErrorGettingBaseRhythmData(false));
        }
      })
      .catch((err) => {
        dispatch(setIsErrorGettingBaseRhythmData(true));
      });
  };
};

export const getAcuity = (isregionupdated) => {
  const apiInstance= getCrpAPIInstance();
  return (dispatch) => {
    apiInstance.get(`v1/acuity`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETACUITY,
            payload: res.data.data,
          });
          dispatch(setIsErrorGettingAcuityData(false));
        }
      })
      .catch((err) => {
        dispatch(setIsErrorGettingAcuityData(true));
      });
  };
};

export const getQuality = (isregionupdated) => {
  const apiInstance= getCrpAPIInstance();
  return (dispatch) => {
    apiInstance.get(`v1/quality`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETQUALITY,
            payload: res.data.data,
          });
          dispatch(setIsErrorGettingQualityData(false));
        }
      })
      .catch((err) => {
        dispatch(setIsErrorGettingQualityData(true));
      });
  };
};

export const reviewSubmit = (review) => {
  const apiInstance= getCrpAPIInstance();
  return (dispatch) => {
    dispatch(setIsSubmittingReview(true));
    dispatch(setReviewErrorMessage(''));
    apiInstance.post(`v1/review`, review, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.SUBMITREVIEW,
            payload: true,
          });
          dispatch(showToastHandler(true));
          dispatch(setIsSubmittingReview(false));
        }
      })
      .catch((err) => {
        dispatch(setReviewErrorMessage(err.response?.data?.error.devMsg));
        dispatch({
          type: types.SUBMITREVIEW,
          payload: false,
        });
        dispatch(setIsSubmittingReview(false));
      });
  };
};

export const updateReportStatus = (patientId, status ,isregionupdated) => {

  const apiInstance= getCrpAPIInstance();

  const statusUpdateObject = {
    patientId,
    status,
  };
  return async (dispatch) => {
    try {
      dispatch(isLoadingCancelReview(true));
      const res = await apiInstance.put(`v1/status/${patientId}/${status}`, statusUpdateObject, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        dispatch(isLoadingCancelReview(false));
        if (res.data.data === 'Update successfully..') {
          dispatch({
            type: types.REVIEWSTATUS,
            payload: true,
          });
        }
      }
    } catch (err) {
      dispatch(isLoadingCancelReview(false));
    }
  };
};
