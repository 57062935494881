import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  // Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { CheckboxWithLabel, Select, Switch } from 'formik-material-ui';

import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import { GoAPI, GoAPIEU, KccGoAPEU, KccGoAPI, KccGoAPIEU, KccGoAPUS, TeleKardiaAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { useDispatch, useSelector } from 'react-redux';
import EcgLoader from 'components/Shared/EcgLoader';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import { AUTH_TYPE, LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';
import License from './License';
import { handletoggleHeader } from 'store/actions';
import {
  COMPONENT_POPUP_TEXTS,
  COMPONENT_USAGE,
  COUNTRY_FULL_FORM,
  headerText,
  phoneRegExp,
  sectionText,
} from '../constant/Constant';
import ModalComponent from 'components/Common/modal/ModalComponent';
import { getTimezoneName } from 'utilities/Utilities';
import { deleteTeammemberAPI } from 'Services/API/actions';
import { getregiondetails } from 'store/actions/settings';
import EcgReviewComponent from './EcgReviewComponent';
import AudioReviewComponent from './AudioReviewComponent';
import TeleconnectReviewComponent from './TeleconnectReviewComponent';

const Root = styled('div')(({ theme }) => ({
  width: 'inherit',
  '& .MuiGrid-root>.MuiGrid-item': {
    marginBottom: '16px',
  },
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Work Sans',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: 'Work Sans',
  },
  '& .MuiTypography-body1': {
    fontFamily: 'Work Sans',
    color: 'rgba(0,0,0,0.87)',
  },
}));

// form validations

const validationSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  // npiNo: yup.string().required('NPI is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter a valid phone number with country code')
    .min(11, 'Please enter a valid phone number with country code')
    .max(13, 'Please enter a valid phone number with country code'),
});

const ProviderInformation = ({
  userId,
  usage = COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS,
  ...props
}) => {
  const dispatch = useDispatch();
  //======= component level state ===========//
  const [loading, setLoading] = useState(false);
  const [regionsInfo, setRegionsInfo] = useState([]);
  const [initialValues, setinitialValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    npiNo: '',
    country: '',
    regions: [],
    roles: [],
    access: [],
    notification: [],
    canManageAvailability: false,
    provider: false,
  });
  const [showAlert, setShowAlert] = useState({
    data: '',
    error: false,
  });
  const [confirmDeleteTeammate, setConfirmDeleteTeammate] = useState(false);

  //= ========== ends ============//

  //=========== Redux state =============//
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const { isAdmin } = useSelector((state) => state.user.profile.permissions);
  const { isSideBar } = useSelector((state) => state.settings);
  const fullfillementtoggle = useSelector((state) => state.user.profile.isFulfillment);
  const countryDetails = useSelector((state) => state.settings.regionListInformation);
  const isregionupdated= useSelector((state) => state.user.isregionupdated)
  const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
  // const countryDetails= updatedCountryDetails.push("India", "UK", "Canada")
  // const updatedCountryDetails = [...countryDetails, "India"];
  const userCountry = useSelector((state) => state.user.profile.country);
  // ========== ends ============//
  const getSingleUserDetails = (teamId, userId) => {
    setLoading(true);
    const apiInstance=regionToken && regionToken==="us" ? KccGoAPUS : KccGoAPEU
    apiInstance.get(`i/v1/kcc/teams/${teamId}/members/${userId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        let data = res.data;
        let roles = Object.keys(data.permissions).filter((key) => data.permissions[key] === true);
        let access = Object.keys(data.access).filter((key) => data.access[key] === true);
        let provider =
          access.includes('isTeleConnect') ||
          access.includes('isECGReview') ||
          access.includes('isAudioReview');

        setinitialValues((initialvalues) => ({
          ...initialvalues,
          ...data,
          provider,
          regions: data.regions[0],
          roles,
          access,
        }));

        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const editSingleUserDetails = (values) => {
    setLoading(true);
    let data = {
      id: userId,
      can_manage_availability: values.canManageAvailability,
      first_name: values.first_name,
      last_name: values.last_name,
      npiNo: values.npiNo,
      email: values.email,
      phone: values.phone.toString(),
      country: values.country,
      regions: typeof values.regions === 'object' ? values.regions : [values.regions],
      is_physician:
        values.access.includes('isAudioReview') ||
        values.access.includes('isECGReview') ||
        values.access.includes('isTeleConnect'),
      is_admin: values.roles.includes('isAdmin'),
      access: {
        isAudioReview: values.access.includes('isAudioReview'),
        isECGReview: values.access.includes('isECGReview'),
        isTeleConnect: values.access.includes('isTeleConnect'),
      },
    };
    let apiInstance= (regionToken === "us" || isregionupdated === "us" )? GoAPI : GoAPIEU;
    apiInstance.put(`i/v2/unified/teams/${teamId}/members/${userId}`, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setLoading(false);
        getSingleUserDetails(props.teamId, userId);
      })
      .catch((err) => console.log(err));
  };
  const getUserNotificationDetails = (teamId, userId) => {
    regionToken && regionToken ==="us" && TeleKardiaAPI.get(`api/v1/teams/${teamId}/member/${userId}/settings/notification`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        let data = res.data;
        setinitialValues((initialValues) => ({
          ...initialValues,
          notification: Object.keys(data.result).filter((key) => data.result[key] === true),
        }));
      })
      .catch((err) => console.log(err));
  };
  const editUserNotificationDetails = (values) => {

    let data = {
      emailEnabled: values.notification.includes('emailEnabled'),
      smsEnabled: values.notification.includes('smsEnabled'),
      teamId: teamId,
      memberId: userId,
    };
    regionToken==="us" && TeleKardiaAPI.post(`api/v1/teams/${teamId}/member/${userId}/settings/notification`, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        getUserNotificationDetails(teamId, userId);
      })
      .catch((err) => console.log(err));
  };

  const inviteNewUser = (values, resetForm) => {
    let data = {
      firstName: values.first_name,
      lastName: values.last_name,
      npiNo: values.npiNo,
      email: values.email,
      phone: values.phone.toString(),
      country: values.country,
      regions: regionToken === "us" ? [values.regions] : values.regions,
      permissions: {
        isAdmin: values.roles.includes('isFulfillment') || values.roles.includes('isAdmin'),
        isPhysician:
          values.access.includes('isECGReview') ||
          values.access.includes('isAudioReview') ||
          values.access.includes('isTeleConnect'),
      },
      access: {
        isECGReview: values.access.includes('isECGReview'),
        isAudioReview: values.access.includes('isAudioReview'),
        isTeleConnect: values.access.includes('isTeleConnect'),
      },
      notifications: {
        isEmail: values.notification.includes('isEmailEnabled'),
        isSms: values.notification.includes('isSmsEnabled'),
      },
      userType: values.roles.includes('isFulfillment') ? 'Operations' : userType,
    };
    let apiInstance= (regionToken === "us" || isregionupdated === "us" )? KccGoAPI : KccGoAPIEU;
    apiInstance.post(`i/v1/unified/invite`, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setShowAlert({
          data: 'Joining invite has been sent, please check your email to know more',
          error: false,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
        resetForm();
      })
      .catch((err) => console.log(err));
  };

  // as this a common component for 3 diff tabs, this useEffect will change the initial form fields value based on type/usage of componnent.
  useEffect(() => {
    // getCountryDetails(teamId)
    dispatch(getregiondetails(teamId));
    // const updatedDetails = { ...countryDetails, country: "IN" };
    if (usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS) {
      dispatch(handletoggleHeader(headerText.TAB1, true));
      getSingleUserDetails(props.teamId, userId);
      getUserNotificationDetails(teamId, userId);
    } else if (usage === COMPONENT_USAGE.USER_INVITE) {
      dispatch(handletoggleHeader(headerText.TAB6, true));
      setinitialValues({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        regions: [],
        roles: [],
        access: [],
        notification: [],
        canManageAvailability: false,
        provider: false,
      });
    } else {
      dispatch(handletoggleHeader(usage, false));
      getSingleUserDetails(props.teamId, userId);
      getUserNotificationDetails(teamId, userId);
    }
  }, [usage]);

  const confirmDeleteTeammateClick = () => {
    setConfirmDeleteTeammate(true);
  };
  const cancleDelete = () => {
    setConfirmDeleteTeammate(false);
  };
  const deleteTeammate = useCallback(() => {
    const params = {
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };
    setConfirmDeleteTeammate(false);
    deleteTeammemberAPI(teamId, profileId, userId, params).subscribe(
      async (res) => {
        if ('detailed_error' in res.data) {
          setShowAlert({
            data: res.data.detailed_error,
            error: true,
          });
          setTimeout(() => {
            setShowAlert({ data: '', type: null });
          }, 3000);
        } else {
          setShowAlert({
            data: 'Member is deleted successfully',
            error: false,
          });
          setTimeout(() => {
            setShowAlert({ data: '', type: null });
            res.status === 200 && props.goBack();
            props.reloadMemberData();
          }, 3000);
        }
      },
      (error) => {
        setShowAlert({
          data: error.message,
          error: true,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 3000);
      },
    );
  }, []);

  return loading ? (
    <EcgLoader />
  ) : (
    <>
      {/* formmik used for react form and their validation   */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions, resetForm) => {
          if (usage === COMPONENT_USAGE.USER_INVITE) {
            inviteNewUser(values, resetForm);
            resetForm();
          } else {
            editSingleUserDetails(values);
            editUserNotificationDetails(values);
          }
          actions.setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => {
          if (values.country) {
            const countryData =
              countryDetails && countryDetails.find((item) => item.country === values.country);
            setRegionsInfo(countryData?.regions);
          }
          return (
            <Form>
              <SectionMain
                headerElement={{
                  headerText: `${usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                      ? sectionText.MY
                      : sectionText.TEAM_MEMBER
                    } Information`,
                  // helperText: '* Required',
                  childElements: (
                    <Root>
                      <SectionMain
                        sx={{ margin: '10px 0' }}
                        headerElement={{
                          headerText: `${usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                              ? sectionText.MY
                              : sectionText.TEAM_MEMBER
                            } Details`,
                          helperText: '* Required',
                        }}
                        bodyElement={[
                          {
                            // headerText: { inviteSubHeaderText },
                            childElements: (
                              <Grid container columnSpacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.first_name && Boolean(errors.first_name)}
                                    helperText={touched.first_name && errors.first_name}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.last_name && Boolean(errors.last_name)}
                                    helperText={touched.last_name && errors.last_name}
                                  />
                                </Grid>
                                {userType === AUTH_TYPE.DOCTOR && (
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      id="npiNo"
                                      name="npiNo"
                                      label="NPI"
                                      value={values.npiNo}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={touched.npiNo && Boolean(errors.npiNo)}
                                      helperText={touched.npiNo && errors.npiNo}
                                      disabled={
                                        isAdmin &&
                                        usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                                      }
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    type="number"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <PhoneIphoneIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.email &&
                                      (Boolean(errors.email) ||
                                        !/^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                          values.email,
                                        ))
                                    }
                                    helperText={touched.email && errors.email}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <EmailIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                          {/* Country and regions gets auto populated  (ASP-502 and ASP-500, ASP-499)*/}
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Field
                                      component={Select}
                                      type="text"
                                      label="Country"
                                      name="country"
                                      id="outlined-country"
                                      // id="country"
                                      // multiple={true}
                                      inputProps={{ name: 'country', id: 'country' }}
                                      value={values.country}
                                      error={touched.country && Boolean(errors.country)}
                                      helperText={touched.country && errors.country}
                                      onChange={(e) => {
                                        handleChange(e);
                                        // handleCountryChange(e);
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <MenuItem value={'select'}>Select Country</MenuItem>
                                      {countryDetails &&
                                        countryDetails.map((item, i) => (
                                          <MenuItem key={i} value={item?.country}>
                                            {item?.country === "CA" ? COUNTRY_FULL_FORM : item?.country}
                                          </MenuItem>
                                        ))}
                                    </Field>{' '}
                                  </FormControl>
                                </Grid>
                                {regionToken === "us" && 
                              
                                <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <Field
                                    value={values.regions}
                                    component={Select}
                                    type="text"
                                    label="Province/State"
                                    name="regions"
                                    inputProps={{ name: 'regions', id: 'regions' }}
                                    onChange={handleChange}
                                  >
                                    <MenuItem value={'select'}>Select Region</MenuItem>
                                    {regionsInfo &&
                                      regionsInfo.map((item) => (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                  </Field>
                                </FormControl>
                              </Grid>
                                }
                               
                              </Grid>
                            ),
                          },
                        ]}
                      />
                      <SectionMain
                        sx={{ margin: '10px 0' }}
                        headerElement={{
                          headerText: `${usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                              ? sectionText.MY
                              : sectionText.TEAM_MEMBER
                            } Roles`,
                          // helperText: '* Required',
                        }}
                        bodyElement={[
                          {
                            childElements: (
                              <>
                                <FormGroup>
                                  <FormControlLabel
                                    disabled={
                                      usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                                    }
                                    control={
                                      <Field
                                        component={Switch}
                                        type="checkbox"
                                        name="roles"
                                        value={'isAdmin'}
                                      />
                                    }
                                    label="Account Administrator"
                                  />
                                  <Typography sx={{ ml: 2 }} variant="body2">
                                    Can manage team settings (e.g. add/remove members)
                                  </Typography>
                                  {fullfillementtoggle ? (
                                    <>
                                      {' '}
                                      <FormControlLabel
                                        sx={{ mt: 2 }}
                                        disabled={
                                          usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                                        }
                                        control={
                                          <Field
                                            component={Switch}
                                            type="checkbox"
                                            name="roles"
                                            value={'isFulfillment'}
                                          />
                                        }
                                        label="Fulfillment"
                                      />
                                      <Typography sx={{ ml: 2 }} variant="body2">
                                        Can manage member's shipping details
                                      </Typography>{' '}
                                    </>
                                  ) : null}

                                  <FormControlLabel
                                    sx={{ mt: 2 }}
                                    disabled={
                                      usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                                    }
                                    control={
                                      <Field component={Switch} type="checkbox" name="provider" />
                                    }
                                    label="Provider"
                                  />
                                  <Typography sx={{ ml: 2 }} variant="body2">
                                    Access to Provider Functions (e.g. Ordering Provider)
                                  </Typography>
                                  {/* Based on the contry , options will begiven */}
                                  {userCountry === "US" && <FormGroup sx={{ ml: 2 }} row>
                                    {userType === AUTH_TYPE.DOCTOR && (
                                      <>
                                        <EcgReviewComponent values={values} usage={usage} />
                                        <AudioReviewComponent values={values} usage={usage} />
                                      </>
                                    )}
                                    <TeleconnectReviewComponent values={values} usage={usage} />
                                  </FormGroup>
                                  }
                                  {/* Saudi Arabia */}
                                  {userCountry === "SA" &&
                                    <FormGroup sx={{ ml: 2 }} row>
                                      {userType === AUTH_TYPE.DOCTOR && (
                                        <>
                                          <EcgReviewComponent values={values} usage={usage} />
                                          <TeleconnectReviewComponent values={values} usage={usage} />
                                        </>
                                      )}
                                    </FormGroup>
                                  }
                                  {/* Canada */}
                                  {userCountry === "CA" || regionToken === "eu" &&
                                    <FormGroup sx={{ ml: 2 }} row>
                                      {userType === AUTH_TYPE.DOCTOR && (
                                        <>
                                          <EcgReviewComponent values={values} usage={usage} />
                                        </>
                                      )}
                                    </FormGroup>
                                  }
                                </FormGroup>
                              </>
                            ),
                          },
                        ]}
                      />
                      {(!isSideBar || usage === COMPONENT_USAGE.USER_INVITE) && (
                        <SectionMain
                          sx={{ margin: '10px 0' }}
                          headerElement={{
                            headerText: 'Manage Availability',
                          }}
                          bodyElement={[
                            {
                              headerText:
                                'Select the notification type the member will recieve for upcoming events.',
                              childElements: (
                                <>
                                  <FormControlLabel
                                    // sx={{ mt: 2 }}
                                    control={
                                      <Field
                                        component={Switch}
                                        type="checkbox"
                                        name="canManageAvailability"
                                      />
                                    }
                                    label="Manage Availability"
                                  />
                                </>
                              ),
                            },
                          ]}
                        />
                      )}
                      <SectionMain
                        sx={{ margin: '10px 0' }}
                        headerElement={{
                          headerText: `${usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
                              ? sectionText.MY
                              : sectionText.TEAM_MEMBER
                            } Notification Preferences`,
                          // helperText: '* Required',
                        }}
                        bodyElement={[
                          {
                            headerText:
                              'Select the notification type the member will recieve for upcoming events.',
                            childElements: (
                              <FormGroup row sx={{ width: '100% !important' }}>
                                <Field
                                  style={{ width: '40% !important' }}
                                  type="checkbox"
                                  component={CheckboxWithLabel}
                                  name="notification"
                                  key={'smsEnabled'}
                                  value={'smsEnabled'}
                                  Label={{ label: 'SMS' }}
                                />
                                <Field
                                  type="checkbox"
                                  component={CheckboxWithLabel}
                                  name="notification"
                                  key={'emailEnabled'}
                                  value={'emailEnabled'}
                                  Label={{ label: 'Email' }}
                                />
                              </FormGroup>
                            ),
                          },
                        ]}
                      />
                    </Root>
                  ),
                }}
                footerElement={{
                  footerLayout: !isSideBar ? 'space-between' : 'flex-end',
                  buttonInputProps: (
                    <>
                      {showAlert.data !== '' && (
                        <TeamAlert title={showAlert.data} error={showAlert.error} />
                      )}

                      {!isSideBar && (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={confirmDeleteTeammateClick}
                          disabled={userId === profileId} // no delete option for self.
                        >
                          Delete
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={
                          errors.first_name ||
                          errors.last_name ||
                          errors.phone ||
                          errors.email ||
                          errors.country ||
                          errors.regions
                        }
                      >
                        Save Change
                      </Button>
                    </>
                  ),
                }}
              />
            </Form>
          );
        }}
      </Formik>

      {/* License table is only available for doctors and if doctor admin logs in, then he can edit individual users licenses */}
      {isAdmin &&
        (usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS ||
          usage === COMPONENT_USAGE.USER_INVITE)
        ? null
        : userType === AUTH_TYPE.DOCTOR && <License usage={usage} userId={userId} />}

      {/* Teammate delete comfirmation Popup */}
      <ModalComponent
        headerText={'Delete Member?'}
        modalText={COMPONENT_POPUP_TEXTS.DELETE_PROVIDER}
        subText={COMPONENT_POPUP_TEXTS.DELETE_PROVIDER_SUBTEXT}
        open={confirmDeleteTeammate}
        handleClose={cancleDelete}
        cancelButtonText={'cancel'}
        confirmButtonText={'Yes, Delete'}
        confirmHandler={deleteTeammate}
      // confirmPayload={confirmRevokeLicenseParameter}
      />
    </>
  );
};

export default ProviderInformation;
