import React from 'react';
import { Box, Grid } from '@mui/material';
import { ColourCodes, ContentDetails, SectionHeader } from './PreviewreportStyling';
import { PREVIEW_REPORT_MODAl, PREVIEW_REPORT_TYPE, getConsultType } from './PreviewreportConst';
import PreviewreportEkgDetails from './PreviewreportEkgDetails';


/**
 * PreviewReportConsultationDetails Component
 * This component helps in displaying consulation details like ,
 * Visit reason, Type of visit and EKG overread Notes
 * @param {reportDetails} param0 
 * @returns 
 */

const PreviewReportConsultationDetails = ({ reportDetails }) => {
  return (
    <Box sx={{ borderBottom: ColourCodes.lightGrey, marginTop: '12px', paddingBottom: '12px' }}>
      <Box sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
        <SectionHeader title={PREVIEW_REPORT_MODAl.CONSULTATION_DETAILS} textSize={'14px'} margin={'0px'}/>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <ContentDetails
              headertitle={PREVIEW_REPORT_MODAl.REASON_FOR_CONSULTATION}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
            <Box>
              {reportDetails?.patientAppointment?.visitReason
                ? reportDetails?.patientAppointment?.visitReason
                : '--'}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <ContentDetails
              headertitle={PREVIEW_REPORT_MODAl.METHOD_OF_COMMUNICATION}
              textSize={'10px'}
              textcolor={ColourCodes.DarkShadeCyan}
            />
            <Box>
              {reportDetails?.patientAppointment?.appointmentChannelType
                ? getConsultType(reportDetails?.patientAppointment?.appointmentChannelType)
                : '--'}
            </Box>
          </Box>
        </Grid>
        {reportDetails?.headerType === PREVIEW_REPORT_TYPE.EKG && (
          <Grid item xs={6}>
            <Box>
              <ContentDetails
                headertitle={PREVIEW_REPORT_MODAl.SYMPTOMS}
                textSize={'10px'}
                textcolor={ColourCodes.DarkShadeCyan}
              />
              <Box>{reportDetails ? reportDetails?.patientAppointment?.symptomsStr : '--'}</Box>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* ECG Details Section :: This section will populate only if the visit type is EKG */}
      {reportDetails?.headerType === PREVIEW_REPORT_TYPE.EKG && (
        <PreviewreportEkgDetails reportDetails={reportDetails} />
      )}
    </Box>
  );
};

export default PreviewReportConsultationDetails;
