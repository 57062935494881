import { ECG_review_page } from "screens/Ecgscreens/Ecgconstants";

export const ECG_REQUEST_STATUS = {
  incoming: ['NOT STARTED', 'IN PROGRESS', 'OVERDUE'],
  completed: ['PENDING', 'COMPLETED'],
};

export const AV_BLOCKS = [
  {
    label: 'None',
    name: 'none',
  },
  {
    label: '1st Degree',
    name: 'firstdegree',
  },
  {
    label: '2nd Degree Mobitz I',
    name: 'seconddegreeone',
  },
  {
    label: '2nd Degree Mobitz II',
    name: 'seconddegeentwo',
  },
  {
    label: '3rd Degree',
    name: 'thirddegree',
  },
];

export const SEARCH_PLACEHOLDER = `Search by Member Name / Report Id`;
export const MUI_FILTER_OPERATOR = {
  startsWith: 'starts with',
  isEmpty: 'is empty',
  not: 'not equals',
  after: 'is after',
  before: 'is before',
  onOrAfter: 'is on or after',
  onOrBefore: 'is on or before',
  isAnyOf: 'is any of',
  isNotEmpty: 'is not empty',
  isInRange: 'is in range',
  endsWith: 'ends with',
  is: 'equals',
};

export const API_PAYLOAD_DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss.000';
export const START_OF_DATE_FORMAT = 'YYYY-MM-DDT00:00:00.000';
export const END_OF_DATE_FORMAT = 'YYYY-MM-DDT23:59:59.000';


export const TRANSFORMED_VALUES={
  Atrial:['afib'],
  PossibleAtrial:['afib'],
  PossibleAtrialFibrillatio:['afib'],
  'Possible Atrial':['afib'],
  'Possible Atrial Fibrillation' : ['afib'],
  possible : ['afib'],
  Possible: ['afib'],
  'sinus ':['sinusRhythm'],
 'sinus Rhythm':['sinusRhythm']
}


export  const maplanguagevalue = (language) => {
  switch (language) {
    case 'fr':
      return ECG_review_page.FRENCH;
    case 'sv':
      return ECG_review_page.SWEDISH;
    case 'en':
      return ECG_review_page.ENGLISH;
    case 'fi-FI':
      return ECG_review_page.FINNISH;
    case 'vi':
      return ECG_review_page.VIETNAMESE;
    case 'en-gb':
      return ECG_review_page.ENGLISH;
    case 'en-US':
      return ECG_review_page.ENGLISH;
     case 'ar':
        return ECG_review_page.ARABIC;
    case 'de':
      return ECG_review_page.GERMAN
    case 'nl':
        return ECG_review_page.DUTCH
    case 'sk':
          return ECG_review_page.SOLVAK
    case 'da-DK':
            return ECG_review_page.DENMARK
    case 'no':
          return ECG_review_page.NORWEGIAN
    case 'hu':
       return ECG_review_page.HUNGARIAN
    case 'cs':
        return ECG_review_page.CZECH
   case 'de-AT':
          return ECG_review_page.GERMAN
   case 'es':
     return ECG_review_page.SPANISH
   case 'da':
     return ECG_review_page.GERMANIC
    case 'pt-BR':
        return ECG_review_page.PORTUGUESE
    default:
      return '';
  }
};