/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import * as API from '../../Services/API/actions';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions/index';
import swal from 'sweetalert';
import moment from 'moment';
import * as Constants from 'utilities/Constants';
import { getAge, getTimezoneName } from 'utilities/Utilities';
import _get from 'lodash.get';
import 'assets/styles/bootstrap.scss';
import ReAssignAppointment from './ReAssignAppointment';
import { useTranslation } from 'react-i18next';
import { getRegion } from 'auth/AuthUtilities';

const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';

const withStartConsultation = (WrapperComponent) => {
  return function Comp(props) {
    const { t } = useTranslation();
    const [appStatus, setAppStatus] = useState(0);
    const [patientDetails, setPatientDetails] = useState({});
    const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
    const dispatch = useDispatch();
    const clinicianId = useSelector((state) => state.user.profile.id);
    const teamId = useSelector((state) => state.user.team.id);
    const [reassignmodalOpen, setreassignmodal] = useState(false);
    const regionToken= getRegion()
    useEffect(() => {
      if (Object.keys(patientDetailsntwrk).length > 0) {
        setAppStatus(patientDetailsntwrk.patientAppointment.appointmentStatusId);
        setPatientDetails(patientDetailsntwrk);
      }
    }, [patientDetailsntwrk]);

    let event_time =
      'patientAppointment' in patientDetails
        ? patientDetails.patientAppointment.scheduledStartTime
        : null;
    const deleteAppointmentHandler = () => {
      let current_time = new Date();
      if (new Date(current_time) > new Date(event_time)) {
        props.error('Past sessions cannot be cancelled');
      } else {
        setreassignmodal(false);
        let queryParams = {
          startDateTime: moment(moment().startOf('day')._d).format(pattern),
          endDateTime: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        };
        let appointmentqueryParams = {
          startDateTime: moment(moment().startOf('day')._d).format(pattern),
          endDateTime: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
          appointmentStatus: '1,2,3,6,7,9',
        };
        let dashboardmetricsParams = {
          startDate: moment(moment().startOf('day')._d).format(pattern),
          endDate: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        };
        let deleteparams = {
          version: patientDetails.version,
        };
        dispatch(
          actions.deleteAppointment(
            props.id,
            clinicianId,
            teamId,
            queryParams,
            appointmentqueryParams,
            'dashboardmiddlesection',
            dashboardmetricsParams,
            deleteparams,
          ),
        );
        regionToken && regionToken==="us" &&  API.getMetrics(teamId, clinicianId, dashboardmetricsParams).subscribe();
        props.loader && props.loader();
      }
    };
    const reassignAppointment = () => {
      setreassignmodal(true);
    };
    const reassignmodalHandler = () => {
      setreassignmodal(false);
    };

    const alertHandler = useCallback(
      (memberName = '') => {
        swal({
          closeOnClickOutside: false,
          title:
            appStatus === Constants.AppointmentStatusTypes.MemberInWaitingRoom
              ? t('Start Session')
              : t('Continue Session'),
          text:
            appStatus === Constants.AppointmentStatusTypes.MemberInWaitingRoom
              ? t('Are you sure you want to start the session with') +
                '  ' +
                _get(patientDetails, 'patient.firstName') +
                ' ' +
                _get(patientDetails, 'patient.lastName') +
                '?'
              : appStatus === 0
              ? t('Are you sure you want to continue the session with ') + memberName + '?'
              : t('Are you sure you want to continue the session with') +
                '  ' +
                _get(patientDetails, 'patient.lastName') +
                ', ' +
                _get(patientDetails, 'patient.firstName') +
                '?',
          className: 'telekardia',
          buttons: {
            cancel: {
              visible: true,
              text: '',
              className: 'swal_cancel swal_black swal_close',
              closeModal: true,
            },
            confirm: {
              text:
                appStatus === Constants.AppointmentStatusTypes.MemberInWaitingRoom
                  ? t('START SESSION')
                  : t('CONTINUE SESSION'),
              visible: true,
              className: 'swal_ok swal_green',
              closeModal: true,
            },
          },
        }).then((res) => {
          if (res) {
            setalertCloseFlag(true);
            let body = {
              timeZoneOffset: new Date().getTimezoneOffset(),
              timeZone: getTimezoneName(),
            };
            props.memberInWaitingRoom && props.cancelMemberWaiting();
            if (appStatus === Constants.AppointmentStatusTypes.MemberInWaitingRoom) {
              // update appointment status
              body = {
                ...body,
                appointmentStatus: Constants.AppointmentStatusTypes.Physician_Review,
              };
              dispatch(actions.isComponentMounted(true));
              API.updateAppointmentStatus(teamId, clinicianId, props.id, body).subscribe(
                (res) => {},
                (error) => {},
              );
            } else {
              dispatch(actions.isComponentMounted(true));
            }
          }
        });
      },
      [patientDetails],
    );

    const [alertCloseFlag, setalertCloseFlag] = useState(false);

    useEffect(() => {
      // returned function will be called on component unmount
      return () => {
        setreassignmodal(false);
      };
    }, []);

    return (
      <>
        <WrapperComponent
          {...props}
          patientDetails={patientDetails}
          reassignAppointment={reassignAppointment}
          alertHandler={alertHandler}
          alertCloseFlag={alertCloseFlag}
        />
        {reassignmodalOpen === true && (
          <ReAssignAppointment
            show={reassignmodalOpen}
            teamId={teamId}
            handleClose={reassignmodalHandler}
            onCancel={deleteAppointmentHandler}
            patientDetails={patientDetails}
            {...props}
          />
        )}
      </>
    );
  };
};

export default withStartConsultation;
