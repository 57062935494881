import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled ,Box, Dialog, Snackbar, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@material-ui/lab';

import { fontSizes } from 'style/styleSettings';
import AddEditNotes from 'screens/coach/MemberProfile/components/Documentation/AddEditNotes';
import ViewNotes from 'screens/coach/MemberProfile/components/Documentation/ViewNotes';
import { getAlertNoteDetails, getNote } from 'store/actions/memberProfile';
import { ADHOC_NOTES, ALERT_NOTE } from 'constants/app';

const AlertStyles = (theme) => ({
  root: {
    '& .MuiAlert-message': {
      fontFamily: 'Work Sans !important',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
});

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      style={{ margin: 0, padding: '16px', backgroundColor: 'rgb(240, 240, 240)' }}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            color: 'darkgrey',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogRoot = styled(MuiDialogContent)((theme) => ({
  root: {
    padding: fontSizes.LARGE,
    backgroundColor: 'rgb(240, 240, 240)',
    '& .MuiTypography-body1': {
      fontFamily: 'Work Sans !important',
    },
    '& .MuiTypography-h5': {
      fontFamily: 'Work Sans !important',
    },
    '& .MuiTypography-h4': {
      fontFamily: 'Work Sans !important',
    },
    '& .MuiTypography-body2': {
      fontFamily: 'Work Sans !important',
    },
    '& .MuiSvgIcon-root': {
      color: '#142A39',
    },
  },
  input: {
    fontFamily: 'Work Sans !important',
  },
}));

export default function Documentation({
  setopenNotesModal,
  noteId,
  onClose,
  memberId,
  openNotesModal,
  file,
  type = null,
  ...other
}) {
  const styles = AlertStyles();
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: null,
    text: null,
  });
  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  //handler to fetch notes for display on table
  const fetchNoteHandler = async () => {
    getNote(teamId, coachId, noteId)
      .then((res) => {
        if (res) {
          let noteData = res.result;
          if (res.result.type === ALERT_NOTE) {
            getAlertNoteDetails(teamId, coachId, noteId).then((res) => {
              setData({ ...res, ...noteData });
            });
          } else {
            setData(noteData);
          }
        }
      })
      .catch((err) => setError(true));
  };

  useEffect(() => {
    fetchNoteHandler();

    //cleanup memory leaks
    return setData(null);
  }, []);

  const snackBarDataHandler = (d) => {
    setSnackbarData(d);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openNotesModal}
      className={styles.root}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          variant="h5"
          style={{
            fontFamily: 'Work Sans',
          }}
        >
          {!noteId ? 'Add Note' : 'View Note'}
        </Typography>
      </DialogTitle>
      <DialogRoot dividers>
        <Box>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarData?.open}
            autoHideDuration={6000}
            onClose={() =>
              setSnackbarData((e) => {
                return { ...e, open: false };
              })
            }
          >
            <Alert
              onClose={() =>
                setSnackbarData((e) => {
                  return { ...e, open: false };
                })
              }
              severity={snackbarData?.severity}
              sx={{
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Work Sans',
                  textTransform: 'uppercase',
                  letterSpacing: '0.50px',
                }}
              >
                {snackbarData?.text}
              </Typography>
            </Alert>
          </Snackbar>
        </Box>
        {!noteId ? (
          <AddEditNotes
            memberId={memberId}
            type={type === null ? ADHOC_NOTES : type}
            snackBarDataHandler={snackBarDataHandler}
            onClose={onClose}
            file={file}
            {...other}
          />
        ) : (
          <ViewNotes
            memberId={memberId}
            snackBarDataHandler={snackBarDataHandler}
            onClose={onClose}
            loading={data === null}
            data={data}
            error={error}
            refetch={() => {
              setData(null);
              fetchNoteHandler();
            }}
            type={type === null ? ADHOC_NOTES : type}
            {...other}
          />
        )}
      </DialogRoot>
    </Dialog>
  );
}
