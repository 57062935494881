import { Grid, Typography, styled } from '@mui/material';
import { useHistory } from 'react-router';
import { GridActionsCellItem, getGridDateOperators } from '@mui/x-data-grid-premium';
import Chips from 'components/mui/tableElements/Chips';
import {
  INITIAL_SESSION_STATUS,
  SESSION_CATEGORY,
  SESSION_METHODS,
  SESSION_PROGRAM,
  SESSION_STATUS,
  SESSION_TYPES,
  capitalizeFirstLetter,
  getvisittypedetails,
} from 'constants/session';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MuiTableComponent } from 'screens/common/CustomUI';
import {
  AppointmentClosedReasonsType,
  AppointmentStatusTypes,
  CANADADATE_TIME_FORMAT,
  DATE_TIME_FORMAT,
  SessionCompletionMethodTextConversion,
} from 'utilities/Constants';
import {
  calculateAge,
  countryConfig,
  formatDate,
  navigateToMemberProfile,
  statusColorOutliner,
  timeFormat,
} from 'utilities/Utilities';
import ConsultationsDetailModal from './ConsultationsDetailModal';
import PreviewReportModal from 'components/AppointmentDetails/PreviewReportModal';
import { adminSessionTableAPIs } from 'Services/consultationsService';
import { ADMIN_CONSULTATION, US_STATES } from 'constants/app';
import moment from 'moment';
import { API_PAYLOAD_DATE_FORMAT } from 'components/ekg/constants/EcgInboxConstants';
import { Table_HEADERNAME } from 'components/Tables/BloodPressureTable/TableConsts';
import { getregiondetails } from 'store/actions/settings';

export const TableRowText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Work Sans',
}));

const AdminConsultations = () => {
  const history = useHistory();
  const ref=useRef()
  const dispatch = useDispatch();
  //====== component level state =======//
  const [loading, setloading] = useState(false);
  const [filteredRow, setFilteredRow] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  
  

  //======== redux state ==========//
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const userCountry = useSelector((state) => state.user.profile.country);
  const countryDetails = useSelector((state) => state.settings.regionListInformation);
  //This gives the regions based on country (To fetch the regionIDs)
  const regions=countryDetails && countryDetails[0]?.regions.join(',')
  const initialState = {
    page: page,
    pageSize: pageSize,
    appointmentStatus: INITIAL_SESSION_STATUS,
    searchString: '',
    sortParam: '',
    sortOrder: '',
    startDateTime: '',
    endDateTime: '',
    visitType:'',
  };
  const [apiQueryParams, setapiQueryParams] = useState(initialState);
  useEffect(()=>{
    if (!countryDetails || !countryDetails.length) {
      dispatch(getregiondetails(teamId));  // Fetch the country details if not loaded
    }
  },[teamId ,regions])

  useEffect(() => {
    setapiQueryParams((prev) => ({
      ...prev,
      page,
      pageSize,
     ...(regions ? {regionIds:regions}: {})
    }));
  }, [page, pageSize ,regions]);

  // When regionIds takes undefined It gives all region Data(hence a check before API call)
  useEffect(() => {
    if(regions !==undefined && regions && apiQueryParams.regionIds !== undefined ){
      tableAPICall(apiQueryParams);
    }
  }, [apiQueryParams]);


  const tableAPICall = (queryParams = null) => {
    setloading(true);
    adminSessionTableAPIs(teamId, clinicianId, queryParams).then((res) => {
      setFilteredRow(transformAppointmentList(res?.appointments || []));
      setTotalRowCount(res?.totalElements || 0);
      setloading(false);
    });
  };

  /**
   *
   * @param {Array} list
   * @returns array of object which includes custom columns along with api responses
   *  --> startDate, name, age, gender, statusMethods
   */
  const transformAppointmentList = (list) => {
    return list?.reduce((acc, cur) => {
      
      return [
        ...acc,
        {
          ...cur,
          name: `${cur.patient.lastName}, ${cur.patient.firstName}`,
          provider: `${cur.physician.lastName}, ${cur.physician.firstName}`,
          age: calculateAge(cur.patient.dob),
          appointmentMethods:
            SessionCompletionMethodTextConversion[cur?.appointmentCompletionMethod] ||
            AppointmentClosedReasonsType[cur?.closedReasonType] ||
            cur.appointmentStatusDisplayName,
        },
      ];
    }, []);
  };

  
  const column = [
    {
      field: 'startdatetime',
      headerName: Table_HEADERNAME.DATE,
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => `${formatDate(value)}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{formatDate(params?.row?.startdatetime, userCountry)}</TableRowText>
      ),
    },
    {
      field: 'time',
      headerName: Table_HEADERNAME.TIME,
      width: 150,
      filterable: false,
      sortable: false,
      type: 'dateTime',
      valueGetter: (params) => `${timeFormat(params?.row?.startdatetime)}`,
      valueFormatter: ({ value }) => value,
      renderCell: (params) => (
        <TableRowText variant="body2">{timeFormat(params?.row?.startdatetime)}</TableRowText>
      ),
    },
    {
      field: 'name',
      headerName: Table_HEADERNAME.MEMBER,
      width: 200,
      type: 'string',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <TableRowText variant="body2">{params?.row?.name}</TableRowText>,
    },
    {
      field: 'provider',
      headerName: Table_HEADERNAME.PROVIDER,
      width: 200,
      type: 'string',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <TableRowText variant="body2">{params?.row?.provider}</TableRowText>,
    },
    {
      field: 'age',
      headerName: Table_HEADERNAME.AGE,
      width: 60,
      type: 'number',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.age || '--'}</TableRowText>
      ),
    },
    {
      field: 'region',
      headerName: Table_HEADERNAME.REGION,
      width: 100,
      type: 'singleSelect',
      valueOptions: countryDetails && countryDetails[0]?.regions.map((item) => item),
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.region || '--'}</TableRowText>
      ),
    },
    {
      field: 'program',
      headerName: Table_HEADERNAME.PROGRAM,
      width: 150,
      type: 'singleSelect',
      valueFormatter: ({ value }) => `${value}`,
      valueOptions: SESSION_PROGRAM,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.patient?.patientProgram  || '--'}</TableRowText>
      ),
    },
    {
      field: 'visitType',
      headerName: Table_HEADERNAME.CATEGORY,
      width: 150,
      type: 'singleSelect',
      valueFormatter: ({ value }) => getvisittypedetails(value),
      valueOptions: SESSION_CATEGORY,
      valueGetter: (params) => params?.row?.visitType,
      renderCell: (params) => (
        <TableRowText variant="body2">
          {getvisittypedetails(params?.row?.visitType) || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'appointmentChannelType',
      headerName: Table_HEADERNAME.TYPE,
      width: 150,
      type: 'singleSelect',
      valueOptions: SESSION_TYPES,
      valueGetter: (value) => value.row.appointmentChannelType.split(' ')[0],
      renderCell: (params) => (
        <TableRowText variant="body2">
          {params?.row?.appointmentChannelType.split(' ')[0] || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'appointmentStatus',
      headerName: Table_HEADERNAME.STATUS,
      width: 120,
      type: 'singleSelect',
      valueOptions: SESSION_STATUS,
      valueGetter: (params) => params?.row?.appointmentStatus,
      renderCell: (params) => (
        <Chips
          label={params?.row?.appointmentStatus || '--'}
          color={statusColorOutliner(params?.row?.appointmentStatus)}
        />
      ),
    },
    {
      field: 'appointmentMethods',
      headerName: Table_HEADERNAME.MEATHOD_REASON,
      width: 150,
      type: 'singleSelect',
      valueOptions: SESSION_METHODS,
      valueGetter: (params) => params?.row?.appointmentMethods,
      renderCell: (params) => (
        <TableRowText variant="body2">
          {params?.row?.appointmentMethods === 'Scheduled' ? '--' : params?.row?.appointmentMethods}
        </TableRowText>
      ),
    },
    {
      field: 'action',
      headerName: Table_HEADERNAME.ACTIONS,
      width: 100,
      sorting: false,
      hideable: false,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="View Profile"
          onClick={() => viewMemberProfile(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="View Details"
          onClick={() => viewSessionDetails(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="View Report"
          onClick={() => viewSessionReport(params.row)}
          disabled={true}
          showInMenu
        />,
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="Edit Report"
          onClick={() => editSessionReport(params.row)}
          disabled={params.row.appointmentStatusId === AppointmentStatusTypes.Scheduled}
          showInMenu
        />,
      ],
    },
  ];

  const columnsiFilter = useMemo(
    () =>
      column.map((col) => {
        if (col.type !== 'date') {
          return col;
        }

        return {
          ...col,
          filterOperators: getGridDateOperators().filter(
            (operator) => operator.value === 'after' || operator.value === 'before',
          ),
        };
      }),
    [column, getGridDateOperators],
  );

  /**
   * custom functionality for mui data grid table's
   * custom filter
   * custom sorting
   * and custom column visibility
   */
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    age: true,
  });

  const onSortModelChange = (param) => {
    setapiQueryParams((prev) => ({
      ...prev,
      sortParam: param[0]?.field,
      sortOrder: param[0]?.sort,
    }));
  };

  const onFilterModelChange = useCallback((param) => {
    if (param.items?.length == 0) {
      setapiQueryParams((prev) => ({
        ...initialState,
      }));
    }
    param.items.forEach((element) => {
      // Avoid calling API if the value is not yet set
      if (element.value != undefined) {
        if (element?.columnField == 'name' || element?.columnField == 'provider') {
          setapiQueryParams((prev) => ({
            ...prev,
            searchString: element?.value,
          }));
        }
        if (element?.columnField === 'appointmentStatus') {
          setapiQueryParams((prev) => ({
            ...prev,
            appointmentStatus: AppointmentStatusTypes[element?.value],
          }));
        }
        if (element?.columnField == 'category') {
          setapiQueryParams((prev) => ({
            ...prev,
            visitType: element?.value,
          }));
        }
        if (element?.columnField === 'startdatetime' && element?.value) {
          if (element?.operatorValue === 'after')
            setapiQueryParams((prev) => ({
              ...prev,
              startDateTime: moment(element?.value).format(API_PAYLOAD_DATE_FORMAT),
            }));
          else
            setapiQueryParams((prev) => ({
              ...prev,
              endDateTime: moment(element?.value).format(API_PAYLOAD_DATE_FORMAT),
            }));
        }
      } else
        setapiQueryParams((prev) => ({
          ...prev,
          startDateTime: '',
          endDateTime: '',
        }));
    });
  }, []);

  // ============= end custom filter/sortng/visibilty =========//

  /**
   * on click of view profile action button
   * will get redirected to member profile screen where user can see member's additional details -->
   *    like programs, health details, activities.. etc.
   */
  const viewMemberProfile = useCallback((row) => {
    navigateToMemberProfile(history, row.patient?.patientId);
  }, []);

  /**
   * on click of  view details action button
   * will open an modal where session related extra content will be displayed -->
   *    like audit trails
   */
  const [viewSessionDetail, setViewSessionDetail] = useState({
    open: false,
    memberId: '',
    appointmentId: '',
  });

  const viewSessionDetails = useCallback((row) => {
    setViewSessionDetail({
      open: true,
      memberId: row.patient?.patientId,
      appointmentId: row?.appointmentId,
    });
  }, []);
  const viewSessionDetailModalCloseHandler = () => {
    setViewSessionDetail({
      ...viewSessionDetail,
      open: false,
    });
  };
  /**
   * on click of  view report action button
   * will open an modal where session documentation will be displayed as pdf -->
   *    like ekg graph
   */
  const [viewReport, setViewReport] = useState({
    open: false,
    memberId: '',
    appointmentId: '',
  });
  const viewSessionReport = useCallback((row) => {
    setViewReport({
      open: true,
      memberId: row.patient?.patientId,
      appointmentId: row.appointmentId,
    });
  }, []);
  const viewReportCloseHandler = () => {
    setViewReport({
      ...viewReport,
      open: false,
    });
  };

  /**
   * on click of  edit details action button
   * will get redirected to This session screen where user can edit details
   * like notes, ecg interpretation, session status/methods
   */
  const editSessionReport = useCallback((row) => {
    navigateToMemberProfile(history, row.patient?.patientId, row.appointmentId, '/true');
  }, []);

  return (
    <>
      <SectionMain
        headerElement={{
          headerText: 'Consultations',
        }}
        bodyElement={[
          {
            childElements: (
              <Grid container>
                <Grid item xs={12}>
                  <MuiTableComponent
                    fileName={ADMIN_CONSULTATION}
                    getRowId={(row) => row?.appointmentId}
                    loading={loading}
                    columns={columnsiFilter}
                    rows={filteredRow}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'startdatetime', sort: 'desc' }], // initial table should be latest date first
                      },
                    }}
                    columnToolbar={true}
                    filterToolbar={true}
                    densityToolbar={true}
                    exportToolbar={true}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rowsPerPageOptions={[20, 50, 100, totalRowCount]}
                    rowCount={totalRowCount}
                    // ColumnResizeIcon={null}
                    onFilterModelChange={(data) => onFilterModelChange(data)}
                    onSortModelChange={(param) => onSortModelChange(param)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                      exportToolbarOptions={{
                        excelOptions: {
                          columnsStyles: {
                            date: { numFmt: countryConfig(userCountry) },
                            date: { numFmt: countryConfig(userCountry) }
                          },
                        },
                        csvOptions: {
                          columnsStyles: {
                            date: { numFmt: countryConfig(userCountry) },
                            date: { numFmt: countryConfig(userCountry)}
                          },
                        }
                      }}
                  />
                </Grid>
              </Grid>
            ),
          },
        ]}
      />

      {/* this modal will showcase session details audit trail */}
      {viewSessionDetail.open ? (
        <ConsultationsDetailModal
          show={viewSessionDetail.open}
          appointmentId={viewSessionDetail.appointmentId}
          patient={viewSessionDetail.memberId}
          memberId={clinicianId}
          clinicianId={clinicianId}
          teamId={teamId}
          handleClose={() => viewSessionDetailModalCloseHandler()}
        />
      ) : null}

      {/* This modal shows pdf report of a session */}
      {viewReport.open ? (
        <PreviewReportModal
          open={viewReport.open}
          memberId={clinicianId}
          patientId={viewReport.memberId}
          appointmentId={viewReport.appointmentId}
          handlePreviewReportModalClose={viewReportCloseHandler}
        />
      ) : null}
    </>
  );
};

export default AdminConsultations;
