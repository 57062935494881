/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import EkgRecording from '../chart/EkgRecording';
import { Box, Typography } from '@material-ui/core';
import { Modal } from '@mui/material';
import Alert from '@material-ui/lab/Alert';
import { calcRowSeconds } from '../Utilities';
import * as Lib from 'constants/RecordingConstants';
import _get from 'lodash.get';
import apiservice from 'api/services/apiservice.service';
import { streamToPromise, getTimezoneName, formatDate, timeFormat } from 'utilities/Utilities';
import EcgLoader from '../Shared/EcgLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PatientHistoryTable from './PatientHistoryTable';
import { ColourCodes, SectionHeader } from './PreviewreportStyling';
import PreviewReportConsultationDetails from './PreviewReportConsultationDetails';
import {
  PREVIEW_REPORT_BP_HEADING,
  PREVIEW_REPORT_MODAl,
  PREVIEW_REPORT_TYPE,
} from './PreviewreportConst';
import BloodpressureDetails from './BloodpressureDetails';
import CrossMark from '../../assets/images/CrossMark.png';
import PreviewReportHeaderSection from './PreviewReportHeaderSection';
import PreviewreportModalRecomendedActions from './PreviewreportModalRecomendedActions';
import PreviewreportClinicianDetails from './PreviewreportClinicianDetails';
import Ecgpreviewdata from './Ecgpreviewdata';

/**
 * PreviewReport Modal Compinent
 * This Component , Displays the Report which will be Sent to end users as a AFTER VISIT REPORTS.
 * @param {string} props
 * @returns
 */

const PreviewReportModal = (props) => {
  const { t } = useTranslation();
  // const classes = useStyles();
  const dispatch = useDispatch();

  let appointmentId = props.appointmentId;

  //Data From Redux Store
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const { isAdmin } = useSelector((state) => state.user.profile.permissions);
  const userCountry = useSelector((state) => state.user.profile.country);
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const MIN_SECONDS = 1;
  const mainContentWidth = 914;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0 ? calcRowSeconds(mainContentWidth) : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;

  // Component level states
  const [reportDetails, setReportDetails] = useState({});
  const [ekgLoader, setekgLoader] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [reportDetailalertOn, setreportDetailalertOn] = useState(false);

  const loadAction = useCallback(async () => {
    let apiPath = isAdmin
      ? `api/v1/teams/${teamId}/admin/${clinicianId}/appointments/${appointmentId}/overread-report`
      : `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/overread-report`;
    let offsetInMinutes = new Date().getTimezoneOffset();
    const timezoneValue = getTimezoneName();
    setekgLoader(true);
    try {
      await streamToPromise(
        apiservice.getapiservice({
          baseUrl: `${apiPath}?patientId=${props.patientId}&timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
        }),
      ).then((res) => {
        setekgLoader(false);
        setReportDetails(res?.data);
      });
    } catch (e) {
      setekgLoader(false);
      setAlertOn(true);
      setreportDetailalertOn(true);
      setReportDetails({});
    }
  }, []);

  const decodeuridata = (data) => {
    try {
      return decodeURI(data);
    } catch (e) {
      return data;
    }
  };

  useEffect(() => {
    loadAction();
  }, []);

  useEffect(() => {
    if (
      reportDetails &&
      reportDetails?.ecgRecording?.id
      // Object.keys(reportDetails).length > 0 &&
      // reportDetails.patientDetail &&
      // reportDetails.patientDetail.ekgResults &&
      // reportDetails.patientDetail.ekgResults.length > 0 &&
      // reportDetails.patientDetail.ekgResults[0].ekgRecordId !== ''
    ) {
      // loadEkgAction(reportDetails?.ecgRecording?.id);
    } else {
      setekgLoader(false);
      setAlertOn(true);
    }
  }, [reportDetails]);

  return (
    <Box>
      <Modal open={props.open} aria-labelledby="modal-title" aria-describedby="modal-description">
        {ekgLoader === true ? (
          <EcgLoader />
        ) : (
          <>
            {reportDetails && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '8px',
                  width: '1060px', // Adjust the width of the modal as needed
                  border: ColourCodes.lightGrey,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '80vh', // Set a max-height for the modal to enable scrolling
                }}
              >
                {reportDetailalertOn === true ? (
                  <>
                    <img
                      onClick={props.handlePreviewReportModalClose}
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                        marginLeft: 'auto',
                        marginBottom: '12px',
                      }}
                      src={CrossMark}
                    />
                    <Alert severity="warning">{t(PREVIEW_REPORT_MODAl.RESULT_NOT_FOUND)}</Alert>
                  </>
                ) : (
                  <>
                    <img
                      onClick={props.handlePreviewReportModalClose}
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                        marginLeft: 'auto',
                        marginBottom: '12px',
                      }}
                      src={CrossMark}
                    />
                    <Box
                      sx={{
                        border: '1px solid var(--default-default-border, #B5BCC1)',
                        padding: '16px',
                        borderRadius: '15px',
                        flex: 1, // This will allow the content to grow and be scrollable
                        overflow: 'auto', // This enables scrolling
                      }}
                    >
                      {/* Header Section :: This is common Section for all type of visits  */}
                      <PreviewReportHeaderSection reportDetails={reportDetails} />

                      {/* Consultation Details  Section :: This is  Common Section for all type of visit */}
                      <PreviewReportConsultationDetails reportDetails={reportDetails} />

                      {/* Recommended Action Section:: This is Common section for all type of visit */}
                      <PreviewreportModalRecomendedActions reportDetails={reportDetails} />

                      {/* Consultation Summary Section :: This is common section for all type of visit */}
                      <Box sx={{ gap: '8px', paddingBottom: '12px' }}>
                        <Box sx={{ marginTop: '8px', fontWeight: 'bold' }}>
                          <SectionHeader
                            title={PREVIEW_REPORT_MODAl.CONSULTATION_SUMMARY}
                            textSize={'14px'}
                            margin={'0px'}
                          />
                        </Box>
                        <Typography variant="body2">
                          {reportDetails?.patientAppointment?.consultationSummaryNotes}
                        </Typography>
                      </Box>

                      {/* cliician Notes section :: This Section is specific for CLINICIAN Visit */}
                      {reportDetails?.headerType === PREVIEW_REPORT_TYPE.CLINICIAN && (
                        <PreviewreportClinicianDetails reportDetails={reportDetails} />
                      )}

                      {/* Patient History Table :: This is common section for all type of visit */}
                      <PatientHistoryTable reportDetails={reportDetails} />

                      {/* BP GRAPH section :: This Section is specific for BP Visit */}
                      {reportDetails && reportDetails?.headerType === PREVIEW_REPORT_TYPE.BLOOD && (
                        <>
                          <BloodpressureDetails
                            headerText={PREVIEW_REPORT_BP_HEADING.BLODD_PRESSURE_DETAILS}
                            contentheadertext1={PREVIEW_REPORT_BP_HEADING.AVERAGE_SYSTOLIC}
                            contentheadertext1Data={`${Math.round(reportDetails?.bloodPressure?.averageSystolic)} mm HG`}
                            contentheadertext2={PREVIEW_REPORT_BP_HEADING.AVERAGE_DIASTOLIC}
                            contentheadertext2Data={`${Math.round(
                              reportDetails?.bloodPressure?.averageDiastolic)} mm HG`}
                             
                            completeRecordings={reportDetails}
                          />
                          <BloodpressureDetails
                            headerText={PREVIEW_REPORT_BP_HEADING.BLODD_PRESSURE_HISTORY}
                            contentheadertext1={PREVIEW_REPORT_BP_HEADING.RECORDING_PERIOD}
                            contentheadertext1Data={` from ${
                              formatDate(
                                reportDetails?.bloodPressure?.recordingPeriod?.startDate,
                                userCountry,
                              ) || 'N/A'
                            } to ${
                              formatDate(
                                reportDetails?.bloodPressure?.recordingPeriod?.endDate,
                                userCountry,
                              ) || 'N/A'
                            }`}
                            contentheadertext2={PREVIEW_REPORT_BP_HEADING.RECORDING_TAKEN}
                            contentheadertext2Data={reportDetails?.bloodPressure?.recordingCount}
                            completeRecordings={reportDetails}
                          />
                          <SectionHeader
                            title={PREVIEW_REPORT_BP_HEADING.HOW_TO_READ_CHART}
                            textSize={'14px'}
                            margin={'0px'}
                          />
                          <Typography variant="body2">
                            {PREVIEW_REPORT_BP_HEADING.CHART_DATA}
                          </Typography>
                        </>
                      )}
                      {/* Till here BP Graph Section completed

                   {/* ECG GRAPH section :: This Section is specific for ECG Visit */}
                      {reportDetails && reportDetails.headerType === PREVIEW_REPORT_TYPE.EKG && (
                        <Box sx={{ marginTop: '20px', position: 'relative' }}>
                          {reportDetails?.ecgRecording?.previewData && (
                            <>
                             <Ecgpreviewdata reportDetails={reportDetails}  userCountry={userCountry}/>

                              <EkgRecording
                                height={695}
                                width={1000}
                                recording={reportDetails?.ecgRecording?.previewData}
                                recordingSample={reportDetails?.ecgRecording?.previewData}
                                calpulse={true}
                                rowSeconds={rowSeconds}
                                inverted={reportDetails?.ecgRecording?.inverted}
                                is6l={reportDetails?.ecgRecording?.is6l}
                              />
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </Modal>
    </Box>
  );
};

export default PreviewReportModal;
