import React from 'react';
import { useSelector } from 'react-redux';
import { Box ,Typography } from '@mui/material';
import { PREVIEW_REPORT_MODAl } from './PreviewreportConst';
import { ColourCodes, SectionHeader } from './PreviewreportStyling';
import { formatDate, timeFormat } from 'utilities/Utilities';

/**
 * PreviewreportEkgDetails Component
 * This Compoenet helps in Viewing the EKG Review Information Done by Doctor/Physician 
 * Helps in Displaying , HeartRate, EKG Review, Date Recorded.
 * @param {*} param0 
 * @returns 
 */


const PreviewreportEkgDetails = ({ reportDetails }) => {
  const userCountry = useSelector((state) => state.user.profile.country);
  return (
    <>
      <Box sx={{ marginBottom: '8px', marginTop: '12px', fontWeight: 'bold' }}>
        <SectionHeader title={PREVIEW_REPORT_MODAl.EKG_REVIEW} textSize={'14px'} margin={'0px'} />
      </Box>
      <Box sx={{ padding: '16px', backgroundColor: '#F3F5FA' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: ColourCodes.lightGrey,
          }}
        >
          <Box>
            <SectionHeader title={'Primary Rhythm'} textSize={'14px'} margin={'0px'}/>
          </Box>
          <Box>
              {PREVIEW_REPORT_MODAl.RECORDED_DATE} {' '}
            {reportDetails?.ecgRecording?.recordedAt
              ? `${formatDate(reportDetails?.ecgRecording?.recordedAt, userCountry)} ${timeFormat(
                  reportDetails?.ecgRecording?.recordedAt,
                )}`
              : null}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', margin: '12px 0px 16px 0px', justifyContent: 'space-between' }}>
          <Box>{reportDetails?.patientAppointment?.heartCondition}</Box>
          <Box>{PREVIEW_REPORT_MODAl.HEART_RATE}  {reportDetails?.ecgRecording?.bpm} BPM</Box>
        </Box>

        <Typography style={{ marginBottom: '4px' }}>
          {PREVIEW_REPORT_MODAl.CLINICIAN_NOTES}
        </Typography>
        <Typography variant="body2">
          {reportDetails?.patientAppointment?.clinicianNotes
            ? reportDetails?.patientAppointment?.clinicianNotes
            : '--'}
        </Typography>
      </Box>
    </>
  );
};

export default PreviewreportEkgDetails;
