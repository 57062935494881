import * as types from './actionTypes';
import { getToken } from 'auth/AuthUtilities';
import { CrpAPI, CrpAPIEU } from 'Axios/axios';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';

export const emptyDataRedux = () => {
  return {
    type: types.RESET_REDUX_DATA,
  };
};

/**
 * update redux store 'ecgReviewPaginationArray' for review pagination
 * @param {Array} requestIds
 * @returns null
 */
export const ecgReviewPagination = (requestIds) => {
  return {
    type: types.ECG_REVIEW_PAGINATION,
    payload: requestIds,
  };
};

export const isFetching = (status) => {
  return {
    type: types.ISFETCHING,
    payload: status,
  };
};

export const getOverdueAndNotStartedCount = () => {
  return (dispatch) => { 
    const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
    let apiInstance= regionToken === "us" ? CrpAPI :CrpAPIEU
    apiInstance.get('v1/count', {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GET_OVERDUE_AND_NOTSTARTED_COUNT,
            payload: res.data.data.count,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const checkReviewStatus = (id) => {
  return (dispatch) => {
    const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
    let apiInstance= regionToken === "us" ? CrpAPI :CrpAPIEU
    apiInstance.get(`v1/checkStatus/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.ISREVIEWINPROGRESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const resetIsOpenReviewClicked = () => {
  return {
    type: types.RESETISOPENREVIEWCLICKED,
  };
};

export const ecgTablePageNumber = (pageNumber) => {
  return {
    type: types.ECG_TABLE_PAGE_NUMBER,
    payload: pageNumber
  };
};

