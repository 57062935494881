
import React from 'react';
import { Box } from '@mui/material';
import TableComponent from './Table';
import { SectionHeader } from './PreviewreportStyling';
import { PATIENT_HISTORY_KEYS_MAP, PATIENT_HISTORY_TYPES, PREVIEW_REPORT_MODAl } from './PreviewreportConst';


const getHistoryValues = (reportDetails, type) => {

  const key = PATIENT_HISTORY_KEYS_MAP[type]; // Get the correct key
  if (!key || !reportDetails || !Array.isArray(reportDetails[key])) return '--';

  return reportDetails[key].filter(Boolean).join(', ') || '--';
};

/**
 * PatientHistoryTable Component
 * @param {object} reportDetails - Patient details from the report payload
 * @returns {JSX.Element}
 */
const PatientHistoryTable = ({ reportDetails }) => {
  if (!reportDetails?.patientAppointment) return null; // Handle missing data safely

  // Prepare table data
  const data = Object.keys(PATIENT_HISTORY_TYPES).reduce((acc, typeKey) => {
    const label = PATIENT_HISTORY_TYPES[typeKey]; // Get the correct label
    const value = getHistoryValues(reportDetails.patientAppointment, label); // Fetch corresponding value
    acc[label] = value;
    return acc;
  }, {});

  return (
    <Box sx={{  fontFamily: "DM Sans", color: '#182C3D' }}>
      {/* Title */}
      <SectionHeader title={PREVIEW_REPORT_MODAl.PATIENT_HISTORY} textSize={"14px"} margin={'8px 0px 0px 0px'}/>

      {/* Table */}
      <TableComponent data={data} cellSeparator=":" />
    </Box>
  );
};

export default PatientHistoryTable;
