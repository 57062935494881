/*eslint-disable*/
import jwtDecode from 'jwt-decode';
import { GoAPI, GoAPIEU } from '../Axios/axios';
import * as Constants from 'constants/app';
import { fetchRenewJWT, getUserFail, setAuthentication, user_logout } from 'store/actions/user';
import { updatePrevLocation } from 'store/reducers/app';
import { makeSimilarReactRouterLocationObject } from 'utilities/Utilities';

let timer;

export const setToken = (token = '') => {
  localStorage.setItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
};
export const clearToken = () => {
  localStorage.removeItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY);
};

export const setRegionToken = (token = '') => {
  localStorage.setItem(Constants.LOCAL_STORAGE_UNIFIED_PORTAL_REGION, token);
};



export const setUnifiedPortalRegion=(region)=>{
  return  localStorage.setItem(Constants.LOCAL_STORAGE_UNIFIED_PORTAL_REGION , region);
}
export const getRegion= () =>{
  let region = localStorage.getItem(Constants.LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
  return region;
}

export const getToken = () => {
  let token = localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY);
  let regionToken = localStorage.getItem(Constants.LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
  if (!token || !(timeRemainingOnTokenMs(token) / 60000 > 5)) {
    //redirect to home
    // window.location.replace("http://" + window.location.host + "/login");
   const apiInstance = regionToken === 'us' ? GoAPI : GoAPIEU
   apiInstance.get(`/i/v1/jwt`, {
      headers: { Authorization: `Bearer ${token}` },
    }) 
     .then((res) => {
        localStorage.setItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY, res.data.jwt);
        return res.data.jwt;
      })
      .catch((err) => {
              console.log(err);
                throw err
            })
            return token;
  } else {
    return token;
  }
};

export const timeRemainingOnTokenMs = (token) => {
  // const token = getToken();
  const tokenExists = token && token.length > 0;

  // return no time left on token if token does not exist
  if (!tokenExists) {
    return 0;
  }
  // Try to decode token as if it was valid
  // if valid, return time remaining on token
  // else just return no time left on token
  let decoded;
  try {
    decoded = jwtDecode(token);
    const tokenExpiresAt = new Date(decoded.exp * 1000);
    const now = new Date();
    const remainingJwtTime = Math.floor(tokenExpiresAt.getTime() - now.getTime()); // time remaining in milliseconds
    return remainingJwtTime < 0 ? 0 : remainingJwtTime;
  } catch (err) {
    // console.log(err)
    return 0;
  }
};

export const checkAuthentication = () => (dispatch, getState) => {
  let token = localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY);
  // Check to see how long is left on token
  const tokenTimeRemainingMs = timeRemainingOnTokenMs(token);
  // console.log('checkAuthentication', tokenTimeRemainingMs)

  if (tokenTimeRemainingMs === 0) {
    // Token no longer valid, clear auth
    dispatch(setAuthentication(false));
  } else {
    // On hard page loads, if token still valid,
    // refresh authenticated flag in redux store
    if (!getState().user.isAuthenticated) {
      dispatch(setAuthentication(getToken()));
    }

    // console.log(isAuthenticated, authorizationType, tokenTimeRemainingMs)

    // TODO: find better spot to change this
    // FIX: add stricter authorization by default but need jwt to support sesison type
    // dispatch(changeAuthorizationType(Constants.AUTHORIZATION_TYPES.PHYSICIAN))
  }
  return getState().user.isAuthenticated;
};

const APP_ACTION_BLACKLIST = [
  // exclude third party library actions like @@fedux-form, @@INIT
  /^@@/,
  // AUTH is noisy since it doesn't interval checks. We want app user interactions events
  /^AUTH\./,
  // Following are legacy app actions that is part of the check for action === 'function'
  /^APP\./,
];

/**
 *
 * @param {*} action
 * @returns true|false
 */
function isIdleTimeAction(action) {
  const { type } = action;
  if (type) {
    const found = APP_ACTION_BLACKLIST.find((regex) => type.match(regex) !== null);
    return !found;
  }
  return false;
}

/**
 * Redux middleware that update the value of idle time.
 */
// export const idleTimeUpdater =
//   ({ dispatch, getState }) =>
//   (next) =>
//   (action) => {
//     const now = new Date();
//     const lastIdleTime = getResetIdleTime() || now;
//     const timeDelta = Math.floor(now.getTime() - lastIdleTime.getTime()); // time delta in milliseconds
//     const idleTimeUpdaterThresholdMs = 1000 * 60; // 1 min
//     // Don't keep spamming isactive so just delay for a min
//     if (
//       (typeof action === 'function' || isIdleTimeAction(action)) &&
//       (getResetIdleTime() === null || timeDelta > idleTimeUpdaterThresholdMs)
//     ) {
//       // console.log(action)
//       // console.log('... setting idle time ...')
//       setResetIdleTime();
//       // dispatch(sendLogoutWarning(false))
//     }
//     return next(action);
//   };


export const idleTimeUpdater =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const now = new Date();
    const lastIdleTime = getResetIdleTime() || now;
    const timeDelta = Math.floor(now.getTime() - lastIdleTime.getTime()); // Time delta in milliseconds
    const idleTimeUpdaterThresholdMs = 1000 * 60; // 1 min

    const shouldSkip = localStorage.getItem("SKIP_IDLE_UPDATE") === "true"; // Check flag
    if (
      !shouldSkip &&  // Skip updating if flag is set
      (typeof action === "function" || isIdleTimeAction(action)) &&
      (getResetIdleTime() === null || timeDelta > idleTimeUpdaterThresholdMs)
    ) {
      setResetIdleTime();
    }

    return next(action);
  };


// Used on page change in RouteManager and network requests in ApiUrls
export const renewAuth = () => (dispatch, getState) =>
  dispatch(fetchRenewJWT()).then((jwt) => setToken(jwt));

/** Reset Idle Time */
export const clearResetIdleTime = () => {
  localStorage.removeItem(Constants.LOCAL_STORAGE_IDLE_TIME_KEY);
};

/**
 * Set idle time in localStorage
 * localStorage key LOCAL_STORAGE_IDLE_TIME_KEY
 */
export const setResetIdleTime = () => {
  const now = new Date();
  localStorage.setItem(Constants.LOCAL_STORAGE_IDLE_TIME_KEY, now);
};

/**
 * Get idle time from localStorage key LOCAL_STORAGE_IDLE_TIME_KEY
 * @returns - Idle time
 */
const getResetIdleTime = () => {
  const idleTime = localStorage.getItem(Constants.LOCAL_STORAGE_IDLE_TIME_KEY);
  if (idleTime !== null) {
    return new Date(idleTime);
  }
  return null;
};

export const clearIntervalAuthenticationChecker = () => {
  if (timer !== undefined) {
    timer = window.clearInterval(timer);
  }
};



export const intervalAuthenticationChecker = () => (dispatch, getState) => {
  clearIntervalAuthenticationChecker(); // Clears the interval.
  const timerInterval = 1000 * 15; // Check every 15 seconds

  timer = window.setInterval(() => {
    const { isAuthenticated, errorMsg } = getState().user; // Redux state

    if (isAuthenticated) {  
      let token = localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY); // Get token
      const timeRemainingMs = timeRemainingOnTokenMs(token); // Calculate token expiry time
      const now = new Date(); // Current time
      const lastIdleTime = getResetIdleTime() || now; // Get last idle time
      // let effectiveIdleTime = getpreviousIdleTime() ? getpreviousIdleTime() : lastIdleTime;
      const timeDelta = Math.floor(now.getTime() - lastIdleTime.getTime()); // Calculate time delta
      const maxIdleTimeMs = 1000 * 60 * 20; // 20 mins

      if (timeDelta >= maxIdleTimeMs) {
        console.log("User inactive for 20+ minutes. Logging out.");
        dispatch(updatePrevLocation(makeSimilarReactRouterLocationObject(window.location)));
        clearResetIdleTime();
        dispatch(user_logout());
        return;
      }

      if (timeRemainingMs <= 0) {  
        dispatch(updatePrevLocation(makeSimilarReactRouterLocationObject(window.location)));
        clearResetIdleTime();
        let disMessage =
          errorMsg === Constants.PORTAL_ERROR_MESSAGES.BACKEND_DOWN
            ? Constants.LOGIN_ERROR_MESSAGES.BACKEND_DOWN
            : Constants.LOGIN_ERROR_MESSAGES.SESSION_TIMEOUT;
        dispatch(getUserFail(disMessage));
        dispatch(user_logout());
      } 
      else if (timeRemainingMs > 0 && timeRemainingMs <= Constants.IDLE_WARNING_THRESHOLD_MS) {
        // setPreviousIdealtime(lastIdleTime);
        
        // **Prevent idle time updates**
        localStorage.setItem("SKIP_IDLE_UPDATE", "true");

        dispatch(renewAuth());

        // **Allow idle updates after 5 seconds**
        setTimeout(() => {
          localStorage.removeItem("SKIP_IDLE_UPDATE");
        }, 5000);
      }   
    }
  }, timerInterval);
};



// export const intervalAuthenticationChecker = () => (dispatch, getState) => {
//   clearIntervalAuthenticationChecker();
//   const timerInterval = 1000 * 15; // check every 15 seconds
//   timer = window.setInterval(() => {
//     const { isAuthenticated, errorMsg } = getState().user;

//     if (isAuthenticated) {
//       let token = localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY);
//       const timeRemainingMs = timeRemainingOnTokenMs(token);
//       const now = new Date();
//       const lastIdleTime = getResetIdleTime() || now;
//       const timeDelta = Math.floor(now.getTime() - lastIdleTime.getTime()); // time delta in milliseconds
//       const idleTimeUpdaterThresholdMs = 1000 * 60; // 1 mins
//       // handle token
//       if (timeRemainingMs <= 0) {
//         // token expired, force user out, logout user
//         dispatch(updatePrevLocation(makeSimilarReactRouterLocationObject(window.location)));
//         clearResetIdleTime();
//         let disMessage =
//           errorMsg === Constants.PORTAL_ERROR_MESSAGES.BACKEND_DOWN
//             ? Constants.LOGIN_ERROR_MESSAGES.BACKEND_DOWN
//             : Constants.LOGIN_ERROR_MESSAGES.SESSION_TIMEOUT;
//         dispatch(getUserFail(disMessage));
//         dispatch(user_logout());
//       } else if (
//         timeRemainingMs > 0 &&
//         timeRemainingMs <= Constants.ACTIVE_USER_UPDATE_TOKEN_THRESHOLD_MS 
//       ) {
//         // start thinking about refreshing token when less than or equal to ACTIVE_USER_UPDATE_TOKEN_THRESHOLD_MS
//         if (timeDelta <= idleTimeUpdaterThresholdMs) {
//           // the user has been active in the last idleTimeUpdaterThresholdMs time period so refresh the token
//           console.log('DIAGNOSTIC: RENEWING JWT');
//           dispatch(renewAuth());
//         } else {
//           // no activity in awhile since idleTimeUpdaterThresholdMs time period, warn user about logout
//           // if (timeRemainingMs <= Constants.IDLE_WARNING_THRESHOLD_MS) {
//           //   dispatch(sendLogoutWarning(true))
//           // } else {
//           //   dispatch(sendLogoutWarning(false))
//           // }
//         }
//       } else {
//         // no-op do nothing!
//         // at this point, the user has a fresh token and does not need to worry about renewing their token
//         // idle time management is only a concern when the token is becoming stale but not expired yet
//       }
//     }
//   }, timerInterval);
// };
