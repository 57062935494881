import { TeleKardiaAPI } from 'Axios/axios';
import { getRegion } from 'auth/AuthUtilities';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';
import { getTimezoneName } from 'utilities/Utilities';

/**
 *
 * @param {string} teamId
 * @param {string} clinicianId
 * @param {object} queryParams
 * @returns api response
 */
export const adminSessionTableAPIs = (teamId, clinicianId, queryParams) => {
  const regionToken= getRegion()
  return regionToken && regionToken==="us" && TeleKardiaAPI.get(
    `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}&pageNumber=${
      queryParams?.page
    }&pageSize=${queryParams?.pageSize}&appointmentStatus=${
      queryParams?.appointmentStatus || [1, 2, 3, 4, 5, 7, 10, 12]
    }&patientSearchString=${queryParams?.patientSearchString || ''}&memberSearchString=${queryParams?.memberSearchString || ''}&sortOrder=${
      queryParams?.sortOrder || ''
    }&sortParam=${queryParams?.sortParam || ''}&startDateTime=${
      queryParams?.startDateTime
    }&endDateTime=${queryParams?.endDateTime}&visitType=${queryParams?.visitType}&regionIds=${queryParams?.regionIds}`,
  )
    .then((res) => {
      return res?.data?.result;
    })
    .catch((err) => console.log(err));
};

/**
 *
 * @param {string} teamId
 * @param {string} clinicianId
 * @param {object} queryParams
 * @returns api response
 */
export const doctorSessionTableAPIs = (teamId, clinicianId, queryParams) => {
  const regionToken= getRegion()
  return  regionToken && regionToken ==="us" && TeleKardiaAPI.get(
    `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments?offsetInMinutes=${new Date().getTimezoneOffset()}&timeZone=${getTimezoneName()}&pageNumber=${
      queryParams?.page
    }&pageSize=${queryParams?.pageSize}&appointmentStatus=${
      queryParams?.appointmentStatus || [1, 2, 3, 4, 5, 7, 10, 12]
    }&patientSearchString=${queryParams?.patientSearchString || ''}&sortOrder=${
      queryParams?.sortOrder || ''
    }&sortParam=${queryParams?.sortParam || ''}&startDateTime=${
      queryParams?.startDateTime
    }&endDateTime=${queryParams?.endDateTime}`,
  )
    .then((res) => {
      return res?.data?.result;
    })
    .catch((err) => console.log(err));
};
