import { Box, Typography } from '@mui/material';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';
import React from 'react'
import { formatDate, timeFormat } from 'utilities/Utilities';
import { ECG_COMPARE_HEADER } from './EcgCompareConstants';
import { useSelector } from 'react-redux';

const EcgModalTopRecordings = ({ topFiveRecordings, handleClick, getEKGImage }) => {

    //Data from Redux Store
    const userCountry = useSelector((state) => state.user.profile.country);
    return (
        <>
            {topFiveRecordings && topFiveRecordings.length > 0 ? (
                topFiveRecordings.map((item) => {
                    const aiDeterminationDetail = getEKGAlgorithmDeterminationDisplayText(
                        item.algorithmDetermination,
                        item.algorithmPackage,
                        item.bpm
                    );
                    return (
                        <Box
                            key={item.id}
                            onClick={() => handleClick(item)}
                            sx={{
                                cursor: "pointer",
                                paddingBottom: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                            }}
                        >
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {/* src={getEKGImage(aiDeterminationDetail)} */}
                                <img style={{ width: "15px", height: "15px", marginRight: "8px" }} src={getEKGImage(aiDeterminationDetail)} />
                                <Typography variant="subtitle2" sx={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.60)" }}>
                                    {aiDeterminationDetail}
                                </Typography>
                            </Box>
                            <Typography variant="subtitle2">
                                {`${formatDate(item.recordedAt, userCountry)} ${timeFormat(item.recordedAt)}`}
                            </Typography>
                        </Box>
                    );
                })
            ) : (
                <Typography variant="subtitle2">{ECG_COMPARE_HEADER.NO_RECORDINGS_FOUND}</Typography>
            )}
        </>

    )
}

export default EcgModalTopRecordings
