import ReactDOM from 'react-dom';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import '@opentok/client';
import { createSession, preloadScript, OTPublisher, OTSubscriber } from 'opentok-react';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import moment from 'moment';
import SockJsClient from 'react-stomp';
import { useTranslation } from 'react-i18next';

import { Button, Paper } from '@mui/material';
import { SvgIcon } from '@material-ui/core';
import CallSharpIcon from '@material-ui/icons/CallSharp';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';

import { getPatientDetails, isComponentMounted } from 'store/actions';

import MuiTypography from 'components/Shared/Typography/MuiTypography';
// import EndConsultationPopUp from 'components/Appointments/Calendar/EndConsultationPopUp';
import PatientDidntjoinCallPopup from 'components/Appointments/Calendar/PatientDidntjoinCallPopup';
import PatientLeftCallPopup from 'components/Appointments/Calendar/PatientLeftCallPopup';
import PatientDeclinePopUp from 'components/Appointments/Calendar/PatientDeclinePopUp';
import OfflinePopup from 'components/Appointments/Calendar/OfflinePopup';
import styles from 'screens/common/AdhocPhone/adhoc.module.css';
import ModalComponent from 'components/Common/modal/ModalComponent';
import 'assets/styles/videocall.scss';

import useTimer from '../../../hooks/useTimer';
import Countdown_Timer from 'components/AppointmentDetails/CountdownTimer';
import { getTimezoneName, streamToPromise } from 'utilities/Utilities';
import apiservice from 'api/services/apiservice.service';
import { getRegion, getToken } from 'auth/AuthUtilities';
import clsx from 'clsx';
import useJoiningTimer from '../../../hooks/useJoiningTimer';
import { TeleKardiaAPI } from 'Axios/axios';
import * as Constants from 'utilities/Constants';
import * as API from 'Services/API/actions';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES, LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';

const useSession = (session = {}) => {
  const { apiKey, sessionId, token } = session;
  const [streams, setStreams] = useState([]);

  const sessionHelper = useMemo(
    () =>
      sessionId &&
      sessionId !== 'masked session id' &&
      createSession({
        apiKey,
        sessionId,
        token,
        onStreamsUpdated: (streams = []) => {
          setStreams([...streams]);
        },
      }),
    [apiKey, sessionId, token],
  );

  useEffect(() => {
    return () => {
      sessionId && sessionId !== 'masked session id' && sessionHelper.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, sessionId, token]);

  return {
    sessionHelper,
    streams,
  };
};

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === 'function') {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return status;
};

function VideoTeleConnect() {
  const history = useHistory();

  const { t } = useTranslation();
  const [patientdeclinemodal, setpatientdeclinemodal] = useState(false);
  const [offlinealertopen, setofflinealertopen] = useState(false);
  const [patientleftcallpopup, setpatientleftcallpopup] = useState(false);
  const [patientleftcallreason, setpatientleftcallreason] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const patientDetail = useSelector((state) => state.appointments.patientDetails);

  const handleNetworkChange = (online) => {
    !online && setofflinealertopen(true);
  };
  const onLine = useNetwork(handleNetworkChange);
  //Generates the click handler, which returns a promise that resovles to the provided url.

  const PIP = {
    position: 'absolute',
    width: '164px',
    height: '164px',
    bottom: '20px',
    left: '20px',
    zIndex: 100,
    borderWidth: '3px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49)',
    bordeRadius: '5px',
  };

  const expandmoreicon = {
    position: 'absolute',
    top: '20px',
    right: '30px',
    zIndex: 100,
  };

  const [error] = React.useState(null);
  const [publishVideo, setpublishVideo] = useState(true);
  const [publishAudio, setpublishAudio] = useState(true);
  const [sesssionactive, setsesssionactive] = useState(false);
  const [callstarted, setcallstarted] = useState(false);
  // const [isNotified, setisNotified] = useState(false);
  const [endcallconfirmationpopup, setendcallconfirmationpopup] = useState(false);

  const userType = useSelector((state) => state.user.chatProfile.userType);
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);

  //=====sideline all network errorCallback events when video call starts======//
  const dispatch = useDispatch();
  //=========//

  const { timer, handleStart, handleReset } = useTimer(0);

  // below is to initiate function till we get sesssion id and token from api or else custom hook doesnt accept empty string or nulll value
  const [session, setsession] = React.useState({
    apiKey: 11111111,
    sessionId: 'masked session id',
    token: 'masked token id',
  });

  const loadAction = useCallback(() => {
    streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail?.appointmentId}/av/token`,
      }),
    )
      .then((res) => {
        setsession({
          apiKey: res.data.result.apikey,
          sessionId: res.data.result.sessionId,
          token: res.data.result.token,
        });
      })
      .catch((err) => {
        streamToPromise(
          apiservice.getapiservice({
            baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail?.appointmentId}/av/token`,
          }),
        )
          .then((res) => {
            setsession({
              apiKey: res.data.result.apikey,
              sessionId: res.data.result.sessionId,
              token: res.data.result.token,
            });
          })
          .catch((err) => {
            console.log(err);
            closePatientLeftCallPopupProvidersidedismiss();
          });
      });
  }, []);

  const { sessionHelper, streams } = useSession(session);

  const [reconnectionflag, setreconnectionflag] = React.useState(false);
  const [rejoinCallFlag, setrejoinCallFlag] = React.useState(false);
  const [inProgressCall, setinProgressCall] = React.useState(false);
  const [sessionTimer, setSessionTimer] = React.useState(0);

  const establishnewconnection = () => {
    setreconnectionflag(true);
  };

  const SummaryPreparationHandler = () => {
    setrejoinCallFlag(true);
    let body = {
      appointmentStatus: Constants.AppointmentStatusTypes.Encounter_Summary_Preparation,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };

    API.updateAppointmentStatus(teamId, clinicianId, patientDetail.appointmentId, body).subscribe(
      (res) => {},
      (error) => {},
    );
  };

  useEffect(() => {
    if (patientDetail?.patientAppointment?.appointmentStatusId === 7) {
      setrejoinCallFlag(true);
    } else if (patientDetail?.patientAppointment?.appointmentStatusId === 3) {
      setinProgressCall(true);
    }
    let duration = moment(patientDetail?.patientAppointment?.scheduledEndTime).diff(
      moment(patientDetail?.patientAppointment?.scheduledStartTime),
      'seconds',
    );
    setSessionTimer(calcUpdatedTime(duration));
    loadAction();
  }, [patientDetail]);

  useEffect(() => {
    const regionToken=getRegion();
    if (sessionHelper.session) {
      if (!reconnectionflag && !rejoinCallFlag) {
        //if call ends from either of the sides call wont starts again
        togglesession(); //when session starts call automatically starts
      }
      sessionHelper.session.on('streamCreated', function (event) {
        handleReset();
        handleStart();
      });
      sessionHelper.session.on('streamDestroyed', function (event) {
        console.log('streamDestroyed');
        let version =
          'patientAppointment' in patientDetail ? patientDetail.patientAppointment.version : null;
  
        let baseUrl = TeleKardiaAPI;
        regionToken && regionToken ==="us" && baseUrl
          .get(
            `/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/status?version=${version}`,
            {
              headers: { Authorization: ` Bearer ${getToken()}` },
            },
          )
          .then((res) => {
            const apStatus = res.data.result.appointment_statusid;
            setpatientleftcallreason(apStatus);
            setpatientleftcallpopup(true);
          })
          .catch((err) => console.log('error - ', err));
      });
      sessionHelper.session.on('sessionDisconnected', function (event) {
        console.log('sessionDisconnected...');
        // setIsDisconnected(true)
        // handleReset();
      });
      sessionHelper.session.on('sessionReconnected', function (event) {
        console.log('sessionReconnected');
        // handleReset();
      });
      sessionHelper.session.on('connectionDestroyed', function (event) {
        console.log('connectionDestroyed');
        // handleReset();
      });
      sessionHelper.session.on('connectionCreated', function (event) {
        console.log('connectionCreated');
        streamToPromise(
          apiservice.postapiservice({
            baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/av/archive`,
          }),
        );
      });
      sessionHelper.session.on('streamCreated', function (event) {
        console.log('streamCreated');
        if (reconnectionflag) {
          // togglesession();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHelper.session]);

  const { joiningtimer, handlejoiningStart, handlejoiningReset } = useJoiningTimer(0);

  const [patientjoiningtimeout, setpatientjoiningtimeout] = useState(false);

  useEffect(() => {
    if (sessionHelper.session && callstarted) {
      if (streams.length === 0) {
        handlejoiningStart();
      } else {
        handlejoiningReset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHelper.session, streams, callstarted]);

  useEffect(() => {
    if (joiningtimer === 300) {
      setpatientjoiningtimeout(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joiningtimer]);

  //============ disconnecting the session ===========//
  // useEffect(() => {
  //   if (sessionHelper.session) {
  //     return () => sessionHelper.disconnect();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sessionHelper.session]);

  const CONNECTED = {
    flex: 1,
    alignItems: 'stretch',
    insertMode: 'append',
    height: 'inherit',
    width: '100%',
  };

  const SUBCONNECTED = {
    //   display: "flex",
    // flexDirection: "row",
    flex: 1,
    alignItems: 'stretch',
    insertMode: 'append',
    height: 'inherit',
    width: '100%',
  };

  let PUBLISHER_VIDEO = { ...CONNECTED };
  if (streams.length > 0) {
    PUBLISHER_VIDEO = { ...PUBLISHER_VIDEO, ...PIP };
  }
  const InProgressHandler = () => {
    // setalertCloseFlag(true)
    let body = {
      appointmentStatus: Constants.AppointmentStatusTypes.InProgress,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };

    API.updateAppointmentStatus(teamId, clinicianId, patientDetail.appointmentId, body).subscribe(
      (res) => {},
      (error) => {},
    );
  };

  const togglesession = async (event) => {
    if (sesssionactive) {
      setAnchorEl(event.currentTarget);
      setendcallconfirmationpopup(true); //enable endcall flag
    } else {
      // loadAction();
      InProgressHandler(); // status of session changes to 3 (in progress)
      // setisNotified(true);
      handleReset();
      // handleStart();
      setsesssionactive(!sesssionactive);
      setcallstarted(!callstarted);
      await streamToPromise(
        apiservice.postapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/notify`,
        }),
      )
        .then((res) => console.log(res))
        .catch(async (err) => {
          await streamToPromise(
            apiservice.postapiservice({
              baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/notify`,
            }),
          );
        });
      // setisNotified(false);
      await streamToPromise(
        apiservice.postapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/av/archive`,
        }),
      );
    }
  };

  const endsessionconfirmationclick = () => {
    sessionHelper.session.signal(
      {
        data: 'doctor left call',
      },
      function (error) {
        if (error) {
          console.log('signal error (' + error.name + '): ' + error.message);
          sessionHelper.session.disconnect();
          handleReset();
          setendcallconfirmationpopup(false);
          setsesssionactive(false);
          setcallstarted(false);
          establishnewconnection(timer);
        } else {
          SummaryPreparationHandler(); //session status goes to 7
          sessionHelper.session.disconnect();
          streamToPromise(
            apiservice.postapiservice({
              baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/notify/endcall`,
            }),
          );
          handleReset();
          setendcallconfirmationpopup(false);
          setsesssionactive(false);
          setcallstarted(false);
          establishnewconnection(timer);
        }
      },
    );
  };

  const continuesessionconfirmationclick = () => {
    setendcallconfirmationpopup(false);
  };

  const removePopup = () => {
    setofflinealertopen(false);
  };
  const closeofflinepopup = () => {
    sessionHelper.session.signal(
      {
        data: 'patient offline',
      },
      function (error) {
        if (error) {
          console.log('signal error (' + error.name + '): ' + error.message);
          sessionHelper.session.disconnect();
          handleReset();
          setofflinealertopen(false);
          establishnewconnection(timer);
        } else {
          sessionHelper.session.disconnect();
          handleReset();
          setofflinealertopen(false);
          establishnewconnection(timer);
        }
      },
    );
  };

  const toggleAudio = () => {
    setpublishAudio(!publishAudio);
  };

  const toggleVideo = () => {
    // OTSubscriber.subscribeToVideo(!publishVideo)
    setpublishVideo(!publishVideo);
  };

  // const togglesession = () => {
  //   setsesssionactive(!sesssionactive);
  // };

  const showstompmessage = (msg) => {
    // OTSubscriber.subscribeToVideo(!publishVideo)
    setpatientdeclinemodal(true);
  };

  const hidepatientdeclinemodal = () => {
    sessionHelper.session.signal(
      {
        data: 'patient declined call',
      },
      function (error) {
        if (error) {
          console.log('signal error (' + error.name + '): ' + error.message);
          SummaryPreparationHandler(); //session status changes to 7
          sessionHelper.session.disconnect();
          handleReset();
          setpatientdeclinemodal(false);
          establishnewconnection(timer);
        } else {
          SummaryPreparationHandler(); //session status changes to 7
          sessionHelper.session.disconnect();
          handleReset();
          setpatientdeclinemodal(false);
          establishnewconnection(timer);
        }
      },
    );
  };

  const closePatientLeftCallPopup = () => {
    setpatientleftcallpopup(false);
    establishnewconnection(timer);
  };

  const closePatientEndedCallPopup = () => {
    sessionHelper.session.signal(
      {
        data: 'patient left call',
      },
      function (error) {
        if (error) {
          console.log('signal error (' + error.name + '): ' + error.message);
          SummaryPreparationHandler(); //session status changes to 7
          sessionHelper.session.disconnect();
          handleReset();
          setpatientleftcallpopup(false);
          establishnewconnection(timer);
        } else {
          SummaryPreparationHandler(); //session status changes to 7
          sessionHelper.session.disconnect();
          handleReset();
          setpatientleftcallpopup(false);
          establishnewconnection(timer);
          setcallstarted(false);
        }
      },
    );
  };

  const closepatientmissedconsultaion = () => {
    sessionHelper.session.signal(
      {
        data: 'patient missed session',
      },
      function (error) {
        if (error) {
          SummaryPreparationHandler(); //session status goes to 7
          sessionHelper.session.disconnect();
          handleReset();
          setpatientjoiningtimeout(false);
          setsesssionactive(false);
          setcallstarted(false);
          establishnewconnection(timer);
        } else {
          SummaryPreparationHandler(); //session status goes to 7
          sessionHelper.session.disconnect();
          streamToPromise(
            apiservice.postapiservice({
              baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetail.appointmentId}/notify/endcall`,
            }),
          );
          handleReset();
          setsesssionactive(false);
          setcallstarted(false);
          setpatientjoiningtimeout(false);
          establishnewconnection(timer);
        }
      },
    );
  };

  const closepatientmissedconsultaiononly = () => {
    setpatientjoiningtimeout(false);
  };
  const closepatientleftconsultaiononly = () => {
    setpatientleftcallpopup(false);
  };

  const closePatientLeftCallPopupProviderside = () => {
    setpatientleftcallpopup(false);
    handleReset();
    establishnewconnection(timer);
  };

  const closePatientLeftCallPopupProvidersidedismiss = useCallback(() => {
    setpatientleftcallpopup(false);
    handleReset();
    establishnewconnection(timer);
  }, []);

  const customHeaders = {
    Authorization: `Bearer ${getToken()}`,
  };

  const completeSessionNotes = () => {
    const params = {
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };
    dispatch(getPatientDetails(teamId, clinicianId, patientDetail.appointmentId, params));
    userType === AUTH_TYPE.COACH
      ? history.push(
          `${COACH_ROUTES.MEMBER_PROFILE_BASE}/${patientDetail.patient.patientId}/${
            patientDetail.appointmentId
          }/${true}`,
        )
      : history.push(
          `${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${patientDetail.patient.patientId}/${
            patientDetail.appointmentId
          }/${true}`,
        );

    dispatch(isComponentMounted(false));
  };

  const calcUpdatedTime = (duration) => {
    let start =
      patientDetail?.patientAppointment?.appointmentStatus === 'InProgress'
        ? patientDetail.patientAppointment.actualStartDateTime ||
          patientDetail.patientAppointment.scheduledStartTime
        : patientDetail.patientAppointment.scheduledStartTime;
    let diffInSeconds = moment().diff(moment(start), 'seconds');
    if (diffInSeconds < 0) {
      return duration - 600;
    }

    return duration - diffInSeconds - 600;
  };

  const [dragState, setDragState] = useState(0);

  const onStart = () => {
    setDragState(dragState + 1);
  };

  const onStop = () => {
    setDragState(dragState - 1);
  };
  const dragHandlers = { onStart: onStart, onStop: onStop };

  return ReactDOM.createPortal(
    <div style={{ width: 'auto' }}>
      {/* {isCallStarted && ( */}
      <Draggable
        handle="div"
        {...dragHandlers}
        bounds={{ top: -200, left: -500, right: 700, bottom: 600 }}
      >
        <Paper className={styles.mainContainer}>
          <Resizable
            defaultSize={{
              width: '560px',
              height: 'auto',
            }}
            style={{ margin: '-10px -10px -20px', padding: '10px', overflow: 'hidden' }}
          >
            <div>
              <div className={`telekardia`}>
                <div className="row no-gutters">
                  <div className="col-12 bg_white px-0 pt-0 mb-md-3 mb-xl-3">
                    <div className="d-flex flex-row align-items-center chat_head pb-2 px-3 panel_head">
                      {callstarted ? (
                        <div
                          className="d-flex flex-column f16 font_white align-content-center text-truncate"
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          <div className="d-flex flex-row weight_600">
                            {(streams && streams.length > 0) || patientleftcallpopup ? (
                              'Call in progress'
                            ) : (
                              <MuiTypography
                                fontSize="16px"
                                fontFamily="Work Sans"
                                lineHeight="20px"
                                letterSpacing="0.25px"
                                fontWeight={600}
                                color="#FFFFFF"
                              >
                                Calling{' '}
                                {streams && (streams.length > 0) | patientleftcallpopup
                                  ? ''
                                  : `${patientDetail?.patient.firstName.slice(0, 6)}...`}
                              </MuiTypography>
                            )}
                          </div>
                        </div>
                      ) : reconnectionflag === false && !rejoinCallFlag && !inProgressCall ? (
                        <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
                          <div className="d-flex flex-row weight_600">Member is waiting...</div>
                        </div>
                      ) : (
                        rejoinCallFlag && (
                          <div className="d-flex flex-row f16 font_white align-items-center text-truncate">
                            <div className="d-flex flex-row weight_600">
                              Call ended! Complete your session notes
                            </div>
                            <span className="font-weight-normal px-3"> </span>
                            <Button variant="contained" onClick={completeSessionNotes}>
                              here
                            </Button>
                          </div>
                        )
                      )}
                      {callstarted && (
                        <h6 style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                          <Countdown_Timer count={sessionTimer} key={1} color={'#142a39'} />
                        </h6>
                      )}
                      {patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                        patientDetail?.patientAppointment?.appointmentChannelTypeId === 3 &&
                        callstarted && (
                          <button
                            type="button"
                            className="btn text-uppercase h40 btn-outline-white min-width104 ml-auto position-relative px-3 d-flex justify-content-between align-items-center"
                            onClick={toggleVideo}
                          >
                            {publishVideo ? (
                              <>
                                <SvgIcon viewBox="0 0 18 12" style={{ fontSize: 18 }}>
                                  <path
                                    d="M14 4.5V1C14 0.734783 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734783 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                                    fill="#FFFFFF"
                                  />
                                </SvgIcon>
                                <span className="d-inline-block ml-2">{t('Video')}</span>
                              </>
                            ) : (
                              <>
                                <SvgIcon viewBox="0 0 19 19" style={{ fontSize: 18 }}>
                                  <path
                                    d="M1.27 0L0 1.27L2.73 4H2C1.73478 4 1.48043 4.10536 1.29289 4.29289C1.10536 4.48043 1 4.73478 1 5V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2 16 14.39 15.92 14.54 15.82L17.73 19L19 17.73L1.27 0ZM19 4.5L15 8.5V5C15 4.73478 14.8946 4.48043 14.7071 4.29289C14.5196 4.10536 14.2652 4 14 4H7.82L19 15.18V4.5Z"
                                    fill="#FFFFFF"
                                  />
                                </SvgIcon>
                                {t('Video')}
                              </>
                            )}
                          </button>
                          // </CustomButton>
                        )}
                      {/* <CustomButton
                  // className="btn_teal_two min-width72 ml-2"
                  className={
                    patientDetail?.patientAppointment?.appointmentChannelTypeId && patientDetail?.patientAppointment?.appointmentChannelTypeId === 3
                      ? "btn_teal_two min-width72 ml-2"
                      : "btn_teal_two min-width72 ml-auto"
                  }
                  onClick={toggleAudio}
                > */}
                      {callstarted && (
                        <button
                          type="button"
                          className={
                            patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                            patientDetail?.patientAppointment?.appointmentChannelTypeId === 3
                              ? 'btn text-uppercase h40 btn-outline-white min-width104 ml-2 position-relative px-3 d-flex justify-content-between align-items-center'
                              : 'btn text-uppercase h40 btn-outline-white min-width104 ml-auto position-relative px-3 d-flex justify-content-between align-items-center'
                          }
                          onClick={toggleAudio}
                        >
                          {publishAudio ? (
                            <>
                              <SvgIcon viewBox="0 0 14 19" style={{ fontSize: 18 }}>
                                <path
                                  d="M7 0C7.79565 0 8.55871 0.316071 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0ZM14 9C14 12.53 11.39 15.44 8 15.93V19H6V15.93C2.61 15.44 0 12.53 0 9H2C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9H14Z"
                                  fill="#FFFFFF"
                                />
                              </SvgIcon>
                              {t('Mute')}
                            </>
                          ) : (
                            <>
                              <MicOffOutlinedIcon />
                              {t('Unmute')}
                            </>
                          )}
                        </button>
                      )}

                      {/* if call ends either from coach or member the rejoin / start call button wont be shown  */}
                      {!rejoinCallFlag && (
                        <button
                          type="button"
                          disabled={!onLine}
                          className={clsx(
                            callstarted
                              ? 'btn text-uppercase h40 bg_red_darker min-width127'
                              : !reconnectionflag && !inProgressCall
                              ? 'btn text-uppercase h40 btn_green min-width127'
                              : 'btn text-uppercase h40 btn_white_fill min-width127',
                            callstarted ? 'ml-3 position-relative' : 'ml-auto position-relative',
                          )}
                          // onClick={(e) => {
                          //   // !clickStartCall && setClickStartCall(true);
                          //   // clickStartCall &&
                          //   togglesession(e);
                          // }}
                          onClick={togglesession}
                        >
                          {callstarted ? (
                            <>
                              <SvgIcon
                                viewBox={
                                  patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                                  patientDetail?.patientAppointment?.appointmentChannelTypeId === 3
                                    ? '0 0 18 12'
                                    : streams && streams.length > 0
                                    ? '0 0 24 24'
                                    : '0 0 18 18'
                                }
                                style={{ fontSize: 20 }}
                              >
                                <path
                                  d={
                                    patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                                    patientDetail?.patientAppointment?.appointmentChannelTypeId ===
                                      3
                                      ? 'M14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z'
                                      : streams && streams.length > 0
                                      ? 'M3.68 16.07l3.92-3.11V9.59c2.85-.93 5.94-.93 8.8 0v3.38l3.91 3.1L24 12.39c-6.41-7.19-17.59-7.19-24 0l3.68 3.68z'
                                      : 'M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z'
                                  }
                                  fill="#FFFFFF"
                                />
                              </SvgIcon>
                              <span className="d-inline-block ml-2">
                                <MuiTypography
                                  fontSize="14px"
                                  fontFamily="Work Sans"
                                  lineHeight="16.71px"
                                  letterSpacing="0.25px"
                                  fontWeight={700}
                                  color="#FFFFFF"
                                >
                                  {t('HANG UP')}
                                </MuiTypography>
                              </span>
                            </>
                          ) : (
                            <>
                              <SvgIcon viewBox="0 0 18 19" style={{ fontSize: 19 }}>
                                {reconnectionflag === false && !inProgressCall ? (
                                  <path
                                    d={
                                      patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                                      patientDetail?.patientAppointment
                                        ?.appointmentChannelTypeId === 3
                                        ? 'M14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z'
                                        : streams && streams.length > 0
                                        ? 'M3.68 16.07l3.92-3.11V9.59c2.85-.93 5.94-.93 8.8 0v3.38l3.91 3.1L24 12.39c-6.41-7.19-17.59-7.19-24 0l3.68 3.68z'
                                        : 'M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z'
                                    }
                                    fill="#FFFFFF"
                                  />
                                ) : (
                                  <path
                                    d="M18 3.5V8.5H16.5V5H10.87L13.3 7.43L12.24 8.5L8 4.25L12.24 0L13.3 1.07L10.87 3.5H18ZM5.82 6.08C6.08 5.82 6.17 5.43 6.06 5.08C5.69 3.92 5.5 2.72 5.5 1.5C5.5 1.23478 5.39464 0.98043 5.20711 0.792893C5.01957 0.605357 4.76522 0.5 4.5 0.5H1C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5C0 6.00868 1.79107 10.3327 4.97918 13.5208C8.1673 16.7089 12.4913 18.5 17 18.5C17.2652 18.5 17.5196 18.3946 17.7071 18.2071C17.8946 18.0196 18 17.7652 18 17.5V14C18 13.7348 17.8946 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13C15.79 13 14.58 12.81 13.43 12.43C13.08 12.32 12.69 12.41 12.43 12.67L10.23 14.87C7.39 13.42 5.09 11.12 3.64 8.28L5.82 6.08Z"
                                    fill="#142A39"
                                  />
                                )}
                              </SvgIcon>
                              <span className="d-inline-block ml-2">
                                <MuiTypography
                                  fontSize="14px"
                                  fontFamily="Work Sans"
                                  lineHeight="16.71px"
                                  letterSpacing="0.25px"
                                  fontWeight={700}
                                  color={
                                    reconnectionflag === false && !inProgressCall
                                      ? '#fff'
                                      : '#142A39'
                                  }
                                >
                                  {reconnectionflag === false && !inProgressCall
                                    ? t('START CALL')
                                    : t('REJOIN')}
                                </MuiTypography>
                              </span>
                            </>
                          )}
                        </button>
                      )}
                    </div>
                    <div
                      className={
                        // publishVideo &&
                        patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                        patientDetail?.patientAppointment?.appointmentChannelTypeId === 3
                          ? 'videoclls d-flex flex-column align-items-center justify-content-center'
                          : 'heightaudiozero d-flex flex-column align-items-center justify-content-center'
                      }
                    >
                      {callstarted ? (
                        <>
                          {sesssionactive ? (
                            <div className="col-12 bg_white height460 p-3 m-0">
                              {error ? (
                                <div className="error">
                                  <strong>Error:</strong>
                                </div>
                              ) : null}

                              <>
                                <OTPublisher
                                  style={PUBLISHER_VIDEO}
                                  properties={{
                                    publishAudio: publishAudio,
                                    publishVideo:
                                      publishVideo &&
                                      patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                                      patientDetail?.patientAppointment
                                        ?.appointmentChannelTypeId === 3,
                                    videoSource:
                                      publishVideo &&
                                      patientDetail?.patientAppointment?.appointmentChannelTypeId &&
                                      patientDetail?.patientAppointment
                                        ?.appointmentChannelTypeId === 2
                                        ? null
                                        : true,
                                    showControls: false,
                                    width: '100%',
                                    height: '100%',
                                  }}
                                  session={sessionHelper.session}
                                />
                                {
                                  streams &&
                                    streams.length > 0 &&
                                    streams.map((stream) => {
                                      return (
                                        <OTSubscriber
                                          style={SUBCONNECTED}
                                          key={stream.id}
                                          session={sessionHelper.session}
                                          stream={stream}
                                          properties={{
                                            publishAudio: publishAudio,
                                            publishVideo: publishVideo,
                                            videoSource:
                                              publishVideo &&
                                              patientDetail?.patientAppointment
                                                ?.appointmentChannelTypeId &&
                                              patientDetail?.patientAppointment
                                                ?.appointmentChannelTypeId === 2
                                                ? null
                                                : true,
                                            // videoSource: 'screen',
                                            showControls: false,
                                            width: '100%',
                                            height: '100%',
                                          }}
                                        />
                                      );
                                    })
                                  // : (
                                  //   <div className="user_img"></div>
                                  // )
                                }
                              </>
                            </div>
                          ) : (
                            <>
                              <div className="col-12 mb-3 bg_white height460">
                                <div className="user_img"></div>
                                <div className="position-absolute fixed-bottom text-center py-3">
                                  <p className="font_white">{t('Call discontinued')}</p>
                                  <button
                                    type="button"
                                    className="btn text-uppercase h40 ml-auto btn_green min-width127"
                                    onClick={togglesession}
                                  >
                                    <CallSharpIcon className="f30" /> {'Re-START CALL'}
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        reconnectionflag === false && <div>{null}</div>
                      )}
                      {
                        <ModalComponent
                          headerText={'Confirm'}
                          modalText={'Would you like to end the session'}
                          open={endcallconfirmationpopup}
                          handleClose={continuesessionconfirmationclick}
                          cancelButtonText={'No, Continue'}
                          confirmButtonText={'Yes, End Sesion'}
                          confirmHandler={endsessionconfirmationclick}
                        />
                        // <EndConsultationPopUp
                        //   type="Video Call"
                        //   open={endcallconfirmationpopup}
                        //   anchorEl={anchorEl}
                        //   continueSession={continuesessionconfirmationclick}
                        //   endSession={endsessionconfirmationclick}
                        // />
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                <OfflinePopup
                  open={offlinealertopen}
                  closeofflinepopup={closeofflinepopup}
                  removePopup={removePopup}
                  onLine={onLine}
                />
              }
              {
                <PatientDeclinePopUp
                  open={patientdeclinemodal}
                  closeofflinepopup={hidepatientdeclinemodal}
                />
              }
              {
                <PatientLeftCallPopup
                  open={patientleftcallpopup}
                  patientleftcallreason={patientleftcallreason}
                  closePatientLeftCallPopup={closePatientLeftCallPopup}
                  closePatientEndedCallPopup={closePatientEndedCallPopup}
                  closePatientLeftCallPopupProviderside={closePatientLeftCallPopupProviderside}
                  closePatientLeftCallPopupProvidersidedismiss={
                    closePatientLeftCallPopupProvidersidedismiss
                  }
                  closepatientleftconsultaiononly={closepatientleftconsultaiononly}
                />
              }
              <PatientDidntjoinCallPopup
                open={patientjoiningtimeout}
                closepatientmissedconsultaion={closepatientmissedconsultaion}
                closepatientmissedconsultaiononly={closepatientmissedconsultaiononly}
              />
              <SockJsClient
                headers={customHeaders}
                url={`${process.env.REACT_APP_TELEKARDIA_API_BASE}teleconnect-ws`}
                topics={[`/clinician/${clinicianId}/web/notification`]}
                //  onConnect={onConnect}
                onMessage={(msg) => showstompmessage(msg)}
              />
            </div>
          </Resizable>
        </Paper>
      </Draggable>
    </div>,
    document.getElementById('floating-root'),
  );
}

export default preloadScript(VideoTeleConnect);
