import * as types from './actionTypes';
import { CrpAPI, CrpAPIEU, KccGoAPI, KproService, KproServiceEU } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';
import {
  REPORT_TYPE_MENU_OPTIONS,
  reportTitleConst,
  reportTypeConst,
} from 'constants/ekgReportMenuOptionsConstants';

export const isClearClickedStatus = (status) => {
  return {
    type: types.GETCLEARCLICKEDSTATUS,
    payload: status,
  };
};

export const noReportForSelectedFilters = (status) => {
  return {
    type: types.NO_REPORT_FOR_SELECTED_FILTERS,
    payload: status,
  };
};

export const isErrorGeneratingReport = (status) => {
  return {
    type: types.IS_ERROR_GENERATING_REPORT,
    payload: status,
  };
};

export const getMembersData = (teamId) => {
  return (dispatch) => {
    let regionToken = localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
    let apiInstance= regionToken === "us" ? KproService : KproServiceEU;
    apiInstance.get(`i/v1/teams/${teamId}/members`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETMEMBERSDATA,
            payload: res.data.members,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const exportReport = (reportDetails) => {
  let apiUrl = reportDetails.service === 'Clinician Review' ? 'v1/report' : 'v1/stethio/report';
  let reportType;
  let reviewTitle;
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[0].reportType) {
    reportType = reportTypeConst['COMPLETED'];
    reviewTitle = reportTitleConst['COMPLETED'];
  }
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[1].reportType) {
    reportType = reportTypeConst['QUEUE'];
    reviewTitle = reportTitleConst['QUEUE'];
  }
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[2].reportType) {
    reportType = reportTypeConst['OVERDUE'];
    reviewTitle = reportTitleConst['OVERDUE'];
  }
  if (reportDetails.reportType === REPORT_TYPE_MENU_OPTIONS[3].reportType) {
    reportType = reportTypeConst['COMPLETED_OVERDUE'];
    reviewTitle = reportTitleConst['COMPLETED_OVERDUE'];
  }
  return (dispatch) => {
    const regionToken=localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION);
    let apiInstance= regionToken === "us" ? CrpAPI :CrpAPIEU
    apiInstance.get(`${apiUrl}?data=${JSON.stringify({ ...reportDetails, reportType })}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/csv',
      },
    })
      .then((response) => {
        dispatch(noReportForSelectedFilters(false));
        dispatch(isErrorGeneratingReport(false));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reviewTitle}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        dispatch(noReportForSelectedFilters(false));
        dispatch({
          type: types.SUBMIT_REPORT_BASED_ON_FILTER,
          payload: true,
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          dispatch(noReportForSelectedFilters(true));
          return;
        }
        dispatch(isErrorGeneratingReport(true));
      });
  };
};
