import { Box, Typography } from '@mui/material';
import React from 'react';
import { formatDate, timeFormat } from 'utilities/Utilities';

const EcgpreviewHeader = ({ children, rightPosition }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        right: rightPosition,
        bottom: '48rem',
        gap: '3px',
        zIndex: 1000,
      }}
    >
      {children}
    </Box>
  );
};

const Ecgpreviewdata = ({ reportDetails, userCountry }) => {
  return (
    <Box>
      <EcgpreviewHeader rightPosition={'41rem'}>
        <Typography sx={{ fontWeight: 'bold !important' }}>{reportDetails.ecgRecording.bpm} BPM</Typography>
        <Typography>
          Recorded: {formatDate(reportDetails.ecgRecording.recordedAt, userCountry)} at{' '}
          {timeFormat(reportDetails.ecgRecording.recordedAt)}
        </Typography>
        <Typography>{reportDetails?.ecgRecording.duration} Sec</Typography>
        <Typography style={{ display: 'inline', backgroundColor: 'black', color: 'white', fontStyle:'italic' }}>
          {reportDetails?.ecgRecording.is6l ? '6L' : '--'}
        </Typography>
      </EcgpreviewHeader>

      <EcgpreviewHeader rightPosition={reportDetails?.ecgRecording.is6l ? '1rem' : '7rem'}>
        <Typography>Scale: {reportDetails.ecgRecording.scale},10mm/mV</Typography>
        <Typography>Filter: {reportDetails.ecgRecording.filterType}, </Typography>
        <Typography>{reportDetails.ecgRecording.previewData.mainsFrequency}Hz</Typography>
      </EcgpreviewHeader>
    </Box>
  );
};

export default Ecgpreviewdata;
