import SectionBody from 'layouts/ScreenLayout/sections/SectionBody'
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain'
import React from 'react'
import {
    TemporaryEcgHackedGraph,
    TemporaryWeightHackedGraph,
    TemporaryBloodPressureHackedGraph,
  } from 'components/Graphs';
import SectionHeader from 'layouts/ScreenLayout/sections/SectionHeader';
import { CustomComponent } from 'screens/Ecgscreens/Screens';
import PatientChartscreen from 'screens/Ecgscreens/PatientChartscreen';
import systolicimage from "../../../../../../assets/images/systolic.png"
import distolicimage from "../../../../../../assets/images/Diastolic.png"
import { Box, Grid, Paper,Stack,Typography } from '@mui/material';

const BloodPressureGraph = ({
    completeRecordings,
    recordingIds,
    recordings,
    selectedRecording,
    setSelectedRecording,
    clearSelectedRecording,
    width,
    AvgSys,
    totalRecordings
}) => {

  return (
   <SectionMain sx={{backgroundColor:"var(--Grey-50, #FAFAFA) !important" , margin:"0px !important" ,padding:"24px"}}
   headerElement={{
    childElements:(
      <>
      <CustomComponent text={`Blood Pressure`} variant={"headertext"}/>
      <Stack direction={'row'} gap={"24px"} padding={"24px 0px 24px 0px"}>
        <CustomComponent text={<span sx={{ color: 'text.primary' }} style={{ fontSize:"12px"}}>{totalRecordings ? totalRecordings : "--"}  Recordings: </span>}/>
        <CustomComponent  text={( <span style={{ fontSize: '12px', lineHeight: '20px'}}> <img src={systolicimage}   alt="circle icon"  style={{verticalAlign: 'middle',marginRight: '5px'}}   />   Systolic BP</span>)}/>
         <CustomComponent text={ <span style={{fontSize:"12px" ,lineHeight: '20px'}}>  <img src={distolicimage} alt="circle icon" style={{verticalAlign: 'middle',  marginRight: '5px'}}  /> Diastolic BP</span>
  }/>
      </Stack>
        <TemporaryBloodPressureHackedGraph
        completeRecordings={completeRecordings}
        recordingIds={recordingIds}
        width={width}
        recordings={recordings}
        selectedRecording={selectedRecording}
        setSelectedRecording={setSelectedRecording}
        clearSelectedRecording={clearSelectedRecording}
        />
        </>
    )
    
   }}
   />
  )
}

export default BloodPressureGraph
