import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StatusColumn } from './components/StatusColumn';
import { makeStyles } from '@material-ui/core';
import { EnhancedTableHead } from '../Common/EnhancedTableHead';
// import { useTranslation } from 'react-i18next';
import Filter from './components/Filter';
import { useHistory } from 'react-router';
import { COACH_ROUTES, ROUTES } from 'constants/app';
import moment from 'moment';
import SponsorPopover from './components/SponsorPopover';
import { adminHeadCells, headCells } from './data/mockData';
import InfoIconModal from './components/InfoIconModal';
import { useSelector } from 'react-redux';
import { programMap } from 'utilities/Constants';
import { GoAPI, KccGoAPI, KccGoAPIEU } from '../../../Axios/axios';
import { getRegion, getToken } from 'auth/AuthUtilities';
import BloodPressureComponent from './components/BloodPressureComponent';
import { parseName } from 'utilities/Utilities';

const componentStyle = makeStyles({
  containerStyle: {
    //marginTop: '70px',
  },

  tableRowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgb(250, 250, 250)',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      backgroundColor: '#F0F8F5 !important',
    },
  },
});

export default function MemberList(props) {
  // const { t } = useTranslation();
  const { getMemberDetails, memberList, count } = props;
  const classes = componentStyle();
  const [isAdminMemberList, setIsAdminMemberList] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableHeadCells, setTableHeadCells] = useState([]);
  const [mL, setMl] = useState([]);
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [filterString, setfilterString] = useState('');
  const isregionupdated= useSelector((state) => state.user.isregionupdated)
  const regionToken=getRegion()
  /**
   * Fetching the member list data based on admin and coach
   * TODO: A way to trigger api calls and storing data on filter update
   */
  useEffect(() => {
    let isAdmin = false;
    /**
     * Bifurcating the member list to accomodate both admin and coach
     */
    if (props.location.pathname.includes('admin')) {
      setIsAdminMemberList(true);
      isAdmin = true;
    }
    getMemberDetails(isAdmin, coachId, teamId, page, rowsPerPage, 'user_last_name', 'ASC');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //======== geting filter details ========//
  const [filterDetails, setfilterDetails] = useState({});
  useEffect(() => {
    let apiInstance= (regionToken === "us" || isregionupdated === "us" )? KccGoAPI : KccGoAPIEU;
    apiInstance.get(`/i/v1/kcc/adminListFilterItems`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let resultData = res.data;
        dataFormating(resultData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const dataFormating = (filterDetails) => {
    const filterDetailsData = {
      program: [],
      sponsor: [],
      status: [],
      bloodPressure: [],
      coach: [],
    };
    if (Object.keys(filterDetails) && Object.keys(filterDetails).length > 0) {
      if (filterDetails.hasOwnProperty('coachDetails')) {
        filterDetailsData.coach = filterDetails.coachDetails.reduce((acc, cur, index) => {
          return [
            ...acc,
            {
              id: `coach${index + 1}`,
              name: `${cur.coachLastName} ${cur.coachFirstName}`,
              coachId: cur.coachID,
              type: 'coach',
            },
          ];
        }, []);
      }
      if (filterDetails.hasOwnProperty('program')) {
        filterDetailsData.program = filterDetails.program.reduce((acc, cur, index) => {
          if (programMap[cur]) {
            return [
              ...acc,
              {
                id: `program${index + 1}`,
                name: programMap[cur],
                type: 'program',
              },
            ];
          } else {
            return [...acc];
          }
        }, []);
      }
      if (filterDetails.hasOwnProperty('sponsorName')) {
        filterDetailsData.sponsor = filterDetails.sponsorName.reduce((acc, cur, index) => {
          return [
            ...acc,
            {
              id: `sponsor${index + 1}`,
              name: cur,
              type: 'sponsor',
            },
          ];
        }, []);
      }
      if (filterDetails.hasOwnProperty('status')) {
        filterDetailsData.status = filterDetails.status.reduce((acc, cur, index) => {
          return [
            ...acc,
            {
              id: `status${index + 1}`,
              name: cur,
              type: 'status',
            },
          ];
        }, []);
      }
      if (filterDetails.hasOwnProperty('bloodPressure'))
        filterDetailsData.bloodPressure = filterDetails.bloodPressure.reduce((acc, cur, index) => {
          return [
            ...acc,
            {
              id: `blood${index + 1}`,
              name: cur,
              type: 'bloodPressure',
            },
          ];
        }, []);
      setfilterDetails(filterDetailsData);
    }
  };
  //=======================================//

  /**
   * Populating head cells based on admin or coach
   */
  useEffect(() => {
    isAdminMemberList ? setTableHeadCells(adminHeadCells) : setTableHeadCells(headCells);
  }, [isAdminMemberList]);

  /**
   * Data decoration for viewing
   */
  useEffect(() => {
    // console.log('setting new member list')
    let temp = [...memberList];
    temp.map((m) => {
      m.lastLogin = m.lastLogin ? new Date(m.lastLogin) : null;
      m.nextSession = new Date(m.nextSession);
      return m;
    });
    setMl(temp);
  }, [memberList]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + 0) * rowsPerPage - mL.length) : 0;

  const history = useHistory();

  //NAVIGATION TO MEMBER PROFILE SCREEN ON CLICK OF MEMBER COLUMN DATA
  const memberProfileNavigation = (value, e) => {
    history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${value}`);
  };
  //NAVIGATION TO MEMBER PROFILE SCREEN ON CLICK OF MEMBER COLUMN DATA
  const coachProfileNavigation = (value, e) => {
    // history.push(`${ROUTES.SETTINGS}/team-members/${value}`);
  };

  //STATUS COLUMN SORING ALGORITHM
  const statusLegend = {
    inactive: 'INACTIVE',
    noncompliant: 'NONCOMPLAINT',
    active: 'ACTIVE',
    onHold: 'ONHOLD',
    completed: 'COMPLETED',
    terminated: 'TERMINATED',
  };
  const computeLastLogin = (val) => {
    let oneDayBefore = moment().subtract(1, 'days');
    let oneWeekBefore = moment().subtract(7, 'days');
    if (val) {
      switch (true) {
        case moment(val) > oneDayBefore:
          return moment(val).format('hh:mm A');
        case moment(val) < oneWeekBefore:
          return moment(val).format('MM-DD-YYYY');
        default:
          return moment(val).fromNow();
      }
    }
  };

  //========= populating modal on hovering of infoicon in table headers =====//
  const [open, setOpen] = React.useState(false);
  const [columnName, setcolumnName] = useState('');
  const handleModalOpen = (id) => {
    setOpen(true);
    setcolumnName(id);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  // ============= //

  //====== trigger api to sort table based on a particular column ======//
  const [sortTypeFlag, setsortTypeFlag] = useState(false);
  const [sortColumn, setsortColumn] = useState('user_last_name');
  const [sortType, setsortType] = useState('ASC');
  const handleSorting = (id) => {
    setPage(0);
    setsortTypeFlag(!sortTypeFlag);
    let sColumn, sType;
    switch (id) {
      case 'participantLastName':
        sColumn = 'user_last_name';
        setsortColumn('user_last_name');
        break;
      case 'sponsor':
        sColumn = 'sponsor_name';
        setsortColumn('sponsor_name');
        break;
      case 'lastLogin':
        sColumn = 'user_last_login';
        setsortColumn('user_last_login');
        break;
      case 'programList':
        sColumn = 'program_id';
        setsortColumn('program_id');
        break;
      default:
        sColumn = 'member_first_name';
        setsortColumn('member_first_name');
        break;
    }
    setsortType(sortTypeFlag ? 'ASC' : 'DESC');
    sType = sortTypeFlag ? 'ASC' : 'DESC';
    getMemberDetails(
      isAdminMemberList,
      coachId,
      teamId,
      0,
      rowsPerPage,
      sColumn,
      sType,
      filterString,
    );
  };
  //==================//

  //=========== table pagination handler =============//
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getMemberDetails(
      isAdminMemberList,
      coachId,
      teamId,
      newPage,
      rowsPerPage,
      sortColumn,
      sortType,
      filterString,
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
    getMemberDetails(
      isAdminMemberList,
      coachId,
      teamId,
      page,
      parseInt(event.target.value),
      sortColumn,
      sortType,
      filterString,
    );
  };
  // ======================== //

  return (
    <Box
      sx={{ width: '100%', padding: isAdminMemberList ? '24px' : '0px' }}
      className={classes.containerStyle}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Filter
          memberFilters={filterDetails}
          setfilterString={setfilterString}
          isAdmin={isAdminMemberList}
          coachId={coachId}
          teamId={teamId}
          page={page}
          setPage={setPage}
          pageLimit={rowsPerPage}
          sortColumn={sortColumn}
        />
        <TableContainer>
          <Table aria-labelledby="Member table">
            <EnhancedTableHead
              from="memberList"
              columnData={tableHeadCells}
              handleSorting={handleSorting}
              handleModalOpen={handleModalOpen}
            />
            <TableBody>
              {mL &&
                // stableSort(mL, getComparator(order, orderBy))
                mL.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage).map((row, index) => {
                  let statusColumnBorderColor, outliner;
                  {
                    // STATUS PILLS COLOR AND STYLING
                    (() => {
                      if (row.status != null) {
                        if (statusLegend[row.status] === 'ACTIVE') {
                          statusColumnBorderColor = '#2D9F86';
                          outliner = 'solid';
                        } else if (
                          statusLegend[row.status] === 'TERMINATED' ||
                          statusLegend[row.status] === 'COMPLETED'
                        ) {
                          statusColumnBorderColor = '#BABFBD';
                          outliner = 'dashed';
                        } else if (
                          statusLegend[row.status] === 'INACTIVE' ||
                          statusLegend[row.status] === 'ONHOLD'
                        ) {
                          statusColumnBorderColor = '#FDC246';
                          outliner = 'solid';
                        } else {
                          statusColumnBorderColor = '#CC3D3F';
                          outliner = 'solid';
                        }
                      }
                    })();
                  }
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, index)}
                      tabIndex={-1}
                      key={index}
                      // selected={!!selected}
                      className={classes.tableRowStyle}
                    >
                      {isAdminMemberList && (
                        <TableCell
                          style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
                          onClick={() => coachProfileNavigation(row.coachId)}
                        >
                          <u>{parseName(row.coachFirstName, row.coachLastName)}</u>
                        </TableCell>
                      )}
                      {isAdminMemberList && (
                        <TableCell
                          style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
                          onClick={() => memberProfileNavigation(row.participantId)}
                        >
                          <u>{parseName(row.participantFirstName, row.participantLastName)}</u>
                        </TableCell>
                      )}
                      {!isAdminMemberList && (
                        <TableCell
                          style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
                          onClick={() => memberProfileNavigation(row.participantId)}
                        >
                          <u>{parseName(row.participantFirstName, row.participantLastName)}</u>
                        </TableCell>
                      )}
                      <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                        {row.nextSession > new Date()
                          ? moment(row.nextSession).format('MM-DD-YYYY')
                          : 'Unscheduled'}
                      </TableCell>
                      <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                        {row.bloodPressure && row.bloodPressure.length > 0 && (
                          <BloodPressureComponent bp={row.bloodPressure} />
                        )}
                      </TableCell>
                      <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                        {row.lastLogin ? computeLastLogin(row.lastLogin) : '--'}
                      </TableCell>
                      <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                        {row.sponsor &&
                          (row.sponsor.length > 6 ? (
                            <SponsorPopover item={row.sponsor} length={6} />
                          ) : (
                            row.sponsor
                          ))}
                      </TableCell>
                      <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                        {row.program ? programMap[row.program] : ''}
                      </TableCell>
                      <TableCell>
                        <StatusColumn
                          data={row.status != null ? statusLegend[row.status] : '-'}
                          outliner={outliner}
                          columnBorderColor={statusColumnBorderColor}
                        />
                      </TableCell>
                      {!isAdminMemberList && (
                        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                          {row.nextAction}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={count || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {/* Modal on click of column's info icon */}
        <InfoIconModal
          open={open}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          columnName={columnName}
        />
      </Paper>
    </Box>
  );
}
