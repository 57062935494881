import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  Select as MuiSelect,
  MenuItem,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  styled,
  TextField,
} from '@mui/material';
// import { IconButton, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// import SmsFailedRoundedIcon from '@mui/icons-material/SmsFailedRounded';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import Loading from 'components/Shared/EcgLoader';
import ProgramProgress from './ProgramProgress';
import ProgramFocusComponent from 'components/Dashboard/SessionComponents/ProgramFocusComponent';
import HeartHabit from 'components/Dashboard/SessionComponents/HeartHabit';

import classes from './ProgramStyles.module.css';
import { KccGoAPI, KccGoAPIEU, TeleKardiaAPI } from '../../../../../Axios/axios';

import { getRegion, getToken } from 'auth/AuthUtilities';
import { USER_TEAM_TYPE, programMap } from 'utilities/Constants';
import { ADHOC_SMS, AUTH_TYPE, LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';
import { fetchShippingDetails } from 'utilities/ApiUrls';

import { callNextSessionAPI } from 'store/actions/member';
import { sessionHeartHabitDetails, startAdhocCall } from 'store/actions';
import {
  getMemberProfileDetails,
  getMemberProfilePPDetails,
  setCurrentWeekDetails,
  updateVisionStatement,
} from 'store/actions/memberProfile';
import Documentation from 'components/Common/ModalView/Documentation';
import SponsorPopover from 'screens/coach/MemberList/components/SponsorPopover';
import SectionFooter from 'layouts/ScreenLayout/sections/SectionFooter';
import SkeletonLoader from 'components/Loading/SkeletonLoader';
import { sessionProgramFocus } from 'store/actions/session';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MainContainer = styled('div')(({ theme }) => ({
  '& .MuiTypography-body1': {
    fontWeight: '500 !important',
    fontFamily: 'Work Sans !important',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px !important',
  },
  '& .MuiTypography-body2': {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '10px !important',
    fontWeight: '400 !important',
    fontFamily: 'Work Sans !important',
    fontSize: '14px !important',
  },
  '& .MuiTypography-h6': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
    fontFamily: 'Work Sans',
    fontSize: '20px',
  },
  '& .MuiTypography-subtitle1': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600',
    fontFamily: 'Work Sans',
    fontSize: '16px',
  },
  '& .MuiSelect-root': {
    fontFamily: 'Work Sans',
  },
  '& .MuiSelect-select': {
    '& :focus': {
      backgroundColor: 'white',
    },
  },
  '& .Mui-selected': {
    // color: '#2D9F86',
    // backgroundColor: '#F4FAF8 !important',
    // '&:hover': {
    //   backgroundColor: 'green !important',
    // },
  },
  '& .MuiMenuItem-root': {
    fontFamily: 'Work Sans !important',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #484850',
  },
}));
const Program = (props) => {
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { t } = useTranslation();

  const [coachList, setCoachList] = useState([]);
  const [isCoachEditing, setIsCoachEditing] = useState(false);
  const [isRiskEditing, setIsRiskEditing] = useState(false);
  const [isStatusEditing, setIsStatusEditing] = useState(false);
  const [addressState, setAddressState] = useState(null);

  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const me = useSelector((state) => state.user.profile);
  const { programData: data, programLoading, error } = useSelector((state) => state.memberProfile);
  const { loading: progressLoading, data: progressData } = useSelector(
    (state) => state.memberProfile.programProgress,
  );

  const isAdmin = me.permissions ? me.permissions.isAdmin : false;
  const isCoach = me.permissions ? me.permissions.isPhysician : false;
  const isAdhocCallWindow = useSelector((state) => state.adhocCall.isFloatingWindow);

  const [value, setValue] = useState(0);
  const [memberStatus, setMemberStatus] = useState(null);
  const [risk, setRisk] = useState('');
  const [nextSession, setNextSession] = useState('');
  const [newCoach, setNewCoach] = useState(null);
  const isComponentMounted = useSelector((state) => state.user.isComponentMounted);
  const isregionupdated= useSelector((state) => state.user.isregionupdated)
  // === on each navigation to member profile memnberID in url param changes, and to fetch latest value with loader below APIs are triggered ==== //
  useEffect(() => {
    setAddressState(null); // empty prev member's address and fetch newer's, with loader.
    // dispatch(getMemberProfileDetails(memberId)); // fetches member's complete info
    getAddress(memberId);
    nextCoachingSessionAsync();
    dispatch(sessionProgramFocus(teamId, coachId, memberId));
    dispatch(sessionHeartHabitDetails(teamId, coachId, memberId, new Date().getTimezoneOffset()));
    return () => {
      // this reset vision statement's edit mode and action button text on new member trigger
      setVisionEdit(false);
      setvisionActionButtonText('ADD VISION STATEMENT');
    };
  }, [memberId]);

  //values stored in local state to send PUT requests
  useEffect(() => {
    if (data) {
      data.risk && setRisk(data.risk);
      data.status && setMemberStatus(data.status);
      data.coachId &&
        setNewCoach({ id: data.coachId, name: `${data.coachLastName}, ${data.coachFirstName}` });
      setVisionStatement(data?.visionStatement);
    }
    return () => {
      setNewCoach(null);
      setRisk('');
      setMemberStatus('');
    };
  }, [data]);

  async function getAddress(id) {
    let data = await fetchShippingDetails(id);
    setAddressState(data?.data);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchCoachList = async () => {
    KccGoAPI.get(`/i/v1/kcc/coachV2/${memberId}`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let resultData = res.data;
        // console.log(resultData);
        let options = resultData
          .filter((d) => d.coachID !== coachId)
          .map((d) => {
            return {
              value: d.coachID,
              label: `${d.coachLastName}, ${d.coachFirstName}`,
            };
          });
        setCoachList(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showAlert, setShowAlert] = useState({ data: '', error: false });
  const reassignCoachAsync = (val) => {
    const regionToken= getRegion()
    let apiInstance= (regionToken === "us" || isregionupdated === "us" )? KccGoAPI : KccGoAPIEU;
    apiInstance.post(
      '/i/v1/kcc/reassignMember',
      { coachID: val, participantID: memberId },
      { headers: { Authorization: `Bearer ${getToken()}` } },
    )
      .then((res) => {
        regionToken && regionToken==="us" && TeleKardiaAPI.put(
          `/api/v1/teams/${teamId}/admin/${coachId}/reassign`,
          { oldCoachId: data.coachId, coachId: val, memberId: memberId },
          { headers: { Authorization: `Bearer ${getToken()}` } },
        )
          .then((res) => {
            switch (res.status) {
              case 200: {
                setShowAlert({ data: 'Coach has been updated successfully', error: false });
                const cindex = coachList.findIndex((c) => c.value === val);
                if (cindex > -1) {
                  let c = coachList[cindex];
                  setNewCoach({ id: c.coachId, name: c.label });
                  setTimeout(() => {
                    setIsCoachEditing(false);
                    setShowAlert({ data: '', type: null });
                  }, 4000);
                } else {
                  throw Error('Coach not present in list');
                }
                break;
              }

              default: {
                setShowAlert({ data: 'Something went wrong', error: true });

                break;
              }
            }
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };

  //Edit Risk API
  const editRiskAsync = (val) => {
    setRisk(val);
    KccGoAPI.put(
      `/i/v1/kcc/${memberId}/risk/${val}`,
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        switch (res.status) {
          case 200: {
            setIsRiskEditing(false);
            setShowAlert({
              data: 'Member’s risk level has been updated successfully',
              error: false,
            });
            setTimeout(() => {
              setShowAlert({ data: '', type: null });
            }, 4000);
            break;
          }
          default:
            setShowAlert({ data: 'Something went wrong', error: true });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  //Edit Member Status API
  const editMemberStatusAsync = (val) => {
    setMemberStatus(val);
    KccGoAPI.put(
      `/i/v1/kcc/${memberId}/status/${val}`,
      {},
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        switch (res.status) {
          case 200: {
            setIsStatusEditing(false);
            setShowAlert({ data: 'Member’s status has been updated successfully', error: false });
            setTimeout(() => {
              setShowAlert({ data: '', type: null });
            }, 4000);
            break;
          }
          default:
            setShowAlert({ data: 'Something went wrong', error: true });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  //Next Session GET API
  const nextCoachingSessionAsync = async () => {
    const res = await callNextSessionAPI(teamId, coachId, [memberId]);
    setNextSession(res.data.result[memberId]);
  };

  //get coaches list on edit
  useEffect(() => {
    if (isCoachEditing) {
      fetchCoachList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCoachEditing]);

  //====== sms flow ======//
  const [adhocSMS, setAdHocSMS] = useState(false);
  const adhocSms = () => {
    setAdHocSMS(true);
  };

  const closeSMSNotes = () => {
    setAdHocSMS(false);
  };
  //=========sms ends ====//

  // adhoc phone call modal flow
  const adhocClick = () => {
    if (isComponentMounted || isAdhocCallWindow || data.ParticipantPhoneNumber.length < 1) {
      return;
    }
    dispatch(
      startAdhocCall(
        'Call Member',
        memberId,
        null, // appointmentid for audio session
        data?.userType === USER_TEAM_TYPE.SIMBA ? true : false, // is activity note required
        data?.userType, // consumer or simba member
      ),
    );
  };

  const phoneNumberFormat = (countryCode = null, phoneNumber = null) => {
    const formatedPhoneNumber =
      phoneNumber !== null
        ? `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
            6,
            10,
          )}`
        : '--';
    return `${countryCode !== null ? `${countryCode}-` : ''}${formatedPhoneNumber}`;
  };

  const checkCases = (data) => {
    if (!data) {
      return '';
    }
    return data;
  };

  //local states
  const [ppData, setPpData] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [weekInProgress, setWeekInProgress] = useState(0);

  useEffect(() => {
    weekInProgress > 0 && dispatch(getMemberProfilePPDetails(weekInProgress, memberId));
  }, [weekInProgress, memberId]);

  useEffect(() => {
    if (!progressLoading && progressData) {
      setPpData(progressData);
      //setting up the current week from program time
      let start = new Date(progressData.programStartTime);
      let diff = moment.duration(moment().diff(moment(start)));
      let d = Math.floor(diff.asDays() / 7) + 1;
      if (weekInProgress === 0) {
        //setting current week in redux to use it in the other sections of the program tab
        dispatch(setCurrentWeekDetails(d));
        setCurrentWeek(d);
        setWeekInProgress(d);
      }
    }
  }, [progressLoading, progressData]);

  const changeWeek = (v) => {
    if (currentWeek + v > 0) {
      setCurrentWeek((w) => w + v);
      dispatch(getMemberProfilePPDetails(currentWeek + v, memberId)); //memberId
    }
  };

  const computeDateRange = () => {
    let start = new Date(ppData.programStartTime);
    let noOfDays = 7 * (currentWeek - 1);
    let rangeStart = moment(start).add(noOfDays, 'days');
    let rangeEnd = moment(start).add(noOfDays + 7, 'days');
    return `${moment(rangeStart).format('L')} to ${moment(rangeEnd).format('L')}`;
  };

  // ========= VISION STATEMENT ==========//
  const [visionEdit, setVisionEdit] = useState(false);
  const [visionLoading, setVisionLoading] = useState(false);
  const [visionStatement, setVisionStatement] = useState('');
  const [visionTextLimitReached, setvisionTextLimit] = useState(false); //error state for > 150 char limit
  const [visionActionButtonText, setvisionActionButtonText] = useState('ADD VISION STATEMENT');
  const editVision = async () => {
    setVisionEdit(!visionEdit); //toggle between read and edit mode
    if (visionEdit) {
      setVisionLoading(true);
      const visionData = await updateVisionStatement(memberId, {
        visionStatement: visionStatement,
      });
      if (visionData.status == 200) {
        setVisionStatement(visionData.data.visionStatement);
        setvisionActionButtonText('EDIT VISION STATEMENT');
        setVisionLoading(false);
      }
    }
  };
  const cancelVision = () => {
    setvisionTextLimit(false); //unset error state once its in read mode
    setVisionLoading(true);
    let apiInstance= isregionupdated === "us" ? KccGoAPI : KccGoAPIEU;
    // triggered vision statement get req to fetch latest data
    apiInstance.get(`/i/v1/kcc/adminList/${memberId}`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        setVisionStatement(res.data.visionStatement);
        setVisionLoading(false);
      })
      .catch((err) => {
        setVisionStatement('Error in data fetching, please try again');
        setVisionLoading(false);
      });
    setVisionEdit(false);
  };
  const changeVision = (e) => {
    //toggle error vision statement's state
    if (e.target.value.length > 150) setvisionTextLimit(true);
    else setvisionTextLimit(false);

    setVisionStatement(e.target.value); //test limit set to 150 character
  };
  // =============end========//

  return (
    <MainContainer>
      <Box style={{ marginTop: '10px' }}>
        {showAlert.data !== '' && <TeamAlert title={showAlert.data} error={showAlert.error} />}
      </Box>
      {adhocSMS && (
        <Documentation
          onClose={closeSMSNotes}
          setopenNotesModal={adhocSMS}
          memberId={memberId}
          openNotesModal={adhocSMS}
          type={ADHOC_SMS}
        />
      )}

      <SectionMain
        headerElement={{
          headerText: 'Member Demographics',
          // helperText: '* Required',
        }}
        bodyElement={[
          {
            childElements: (
              <>
                <Grid item xs={12} md={6} lg={4}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <PhoneIcon
                      style={{
                        color: '#6387C5',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                      onClick={() => data?.ParticipantPhoneNumberVerified && adhocClick()}
                    />
                    {/* <SmsFailedRoundedIcon
                          style={{
                            color: '#6387C5',
                            cursor: 'pointer',
                            marginTop: '10px',
                          }}
                          onClick={() => data?.ParticipantPhoneNumberVerified && adhocSms()}
                        /> */}
                    <div className={classes.distanceFromIcon}>
                      <Typography variant="body2">Phone</Typography>
                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        <Typography
                          variant="body1"
                          // style={{ color: data?.ParticipantPhoneNumberVerified ? '#DCDCDC' : '#067F6F' }}
                          style={{ color: '#6387C5' }}
                        >
                          {data?.ParticipantPhoneNumberVerified
                            ? phoneNumberFormat(
                                data?.ParticipantCountryCode,
                                data?.ParticipantPhoneNumber,
                              )
                            : '--'}
                        </Typography>
                      </SkeletonLoader>
                    </div>
                  </div>
                </Grid>

                {/* Consumer user wont have access to view these sections */}
                {data?.userType === USER_TEAM_TYPE.SIMBA && (
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body2">Company name</Typography>
                    <SkeletonLoader isloading={progressLoading || programLoading || error !== null}>
                      <Typography variant="body1">{data?.sponsor}</Typography>
                    </SkeletonLoader>
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={4}>
                  <Typography variant="body2">Email</Typography>
                  <SkeletonLoader isloading={progressLoading || programLoading || error !== null}>
                    <Typography variant="body1" style={{ color: '#6387C5' }}>
                      {data?.participantEmail.length > 25 ? (
                        <SponsorPopover
                          item={data?.participantEmail}
                          length={25}
                          style={{ color: '#6387C5' }}
                        />
                      ) : (
                        data?.participantEmail
                      )}
                    </Typography>
                  </SkeletonLoader>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    Address
                  </Typography>
                  {data?.userType === USER_TEAM_TYPE.SIMBA ? (
                    <SkeletonLoader isloading={addressState == null}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.87)' }}
                      >
                        {`${addressState?.city === undefined ? '--' : ''} ${checkCases(
                          addressState?.primaryLine,
                        )} ${checkCases(addressState?.secondaryLine)}`}
                        <br />
                        {`${checkCases(addressState?.city)} ${
                          addressState?.city === undefined ? '' : ','
                        } ${checkCases(addressState?.state)} ${checkCases(addressState?.zipCode)}`}
                      </Typography>
                    </SkeletonLoader>
                  ) : (
                    <SkeletonLoader isloading={progressLoading || programLoading || error !== null}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.87)' }}
                      >
                        {data?.location || '--'}
                      </Typography>
                    </SkeletonLoader>
                  )}
                </Grid>
              </>
            ),
          },
        ]}
      />

      {/* Consumer user wont have access to view these sections */}
      {data?.userType === USER_TEAM_TYPE.SIMBA && (
        <>
          <SectionMain
            headerElement={{
              headerText: `Program Overview`,
            }}
            bodyElement={[
              {
                childElements: (
                  <>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Program</Typography>
                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        <Typography variant="body1">
                          {data?.program ? (
                            programMap[data.program]
                          ) : (
                            <span style={{ color: 'grey' }}>No data</span>
                          )}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    {isCoach && !isAdmin && (
                      <Grid item lg={4} md={6} xs={12}>
                        <Typography variant="body2">Current week</Typography>
                        <SkeletonLoader
                          isloading={progressLoading || programLoading || error !== null}
                        >
                          <Typography variant="body1">
                            {' '}
                            {data?.currentWeek ? `Week ${data?.currentWeek}` : '-'}
                          </Typography>
                        </SkeletonLoader>
                      </Grid>
                    )}
                    {isAdmin && (
                      <Grid item lg={4} md={6} xs={12}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2">Coach</Typography>
                          <EditIcon
                            style={{ marginLeft: '15px', fontSize: 15 }}
                            onClick={() => setIsCoachEditing(true)}
                          />
                        </Box>
                        <SkeletonLoader
                          isloading={progressLoading || programLoading || error !== null}
                        >
                          {isCoachEditing ? (
                            <FormControl className={classes.formControl} variant="outlined">
                              <MuiSelect
                                id="demo-simple-select2"
                                defaultValue={newCoach?.id}
                                // value={data?.coachId}
                                onChange={(e) => reassignCoachAsync(e.target.value)}
                                placeholder="Select Coach"
                              >
                                {coachList.map((c, index) => (
                                  <MenuItem key={index} value={c.value}>
                                    {c.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                            </FormControl>
                          ) : (
                            <Typography variant="body1">
                              {newCoach ? newCoach.name : 'No Coach assigned'}
                            </Typography>
                          )}
                        </SkeletonLoader>
                      </Grid>
                    )}
                    <Grid item lg={4} md={6} xs={12}>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">Risk</Typography>
                        <EditIcon
                          style={{ marginLeft: '15px', fontSize: 15 }}
                          onClick={() => setIsRiskEditing(true)}
                        />
                      </Box>

                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        {isRiskEditing ? (
                          <FormControl variant="outlined" className={classes.formControl}>
                            <MuiSelect
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={risk}
                              onChange={(e) => editRiskAsync(e.target.value)}
                              placeholder="Select"
                            >
                              <MenuItem value={'Low'}>Low</MenuItem>
                              <MenuItem value={'Medium'}>Medium</MenuItem>
                              <MenuItem value={'High'}>High</MenuItem>
                            </MuiSelect>
                          </FormControl>
                        ) : (
                          <Typography variant="body1">{risk ? risk : '-'}</Typography>
                        )}
                      </SkeletonLoader>
                    </Grid>
                    {isAdmin && (
                      <Grid item lg={4} md={6} xs={12}>
                        <Typography variant="body2">Current Week</Typography>
                        <SkeletonLoader
                          isloading={progressLoading || programLoading || error !== null}
                        >
                          <Typography variant="body1">
                            {data?.currentWeek ? `Week ${data?.currentWeek}` : '-'}
                          </Typography>
                        </SkeletonLoader>
                      </Grid>
                    )}
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Enrolled on</Typography>
                      <SkeletonLoader isloading={progressData == undefined || progressData == null}>
                        <Typography variant="body1">
                          {moment(progressData && progressData?.programStartTime).format(
                            'MM-DD-YYYY',
                          ) || '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    {isCoach && !isAdmin && (
                      <Grid item lg={4} md={6} xs={12}>
                        <Typography variant="body2">Last login</Typography>
                        <SkeletonLoader
                          isloading={progressLoading || programLoading || error !== null}
                        >
                          <Typography variant="body1">
                            {moment(data?.lastLogin).format('MM-DD-YYYY hh:mm A')}
                          </Typography>
                        </SkeletonLoader>
                      </Grid>
                    )}
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">
                        Next {userType == AUTH_TYPE.COACH ? `coaching ` : `cardiology `} session
                      </Typography>
                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        <Typography variant="body1">
                          {nextSession
                            ? moment(nextSession).format('MM-DD-YYYY, HH:mm A')
                            : 'Unscheduled'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Coaching Sessions</Typography>
                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        <Typography variant="body1">
                          {data?.coachRemainingCredits && data?.coachTotalCredits ? (
                            <>
                              {/* {' '}
                          {data?.coachRemainingCredits} of {data?.coachTotalCredits}{' '}
                          available{' '} */}
                              Unlimited
                            </>
                          ) : (
                            <b> -- </b>
                          )}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Cardiology Sessions</Typography>
                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        <Typography variant="body1">
                          {data?.doctorRemainingCredits && data?.doctorTotalCredits ? (
                            data?.doctorRemainingCredits > data?.doctorTotalCredits ||
                            data?.doctorTotalCredits > 100 ? (
                              <>Unlimited</>
                            ) : (
                              <>
                                {' '}
                                {data?.doctorRemainingCredits} of {data?.doctorTotalCredits}{' '}
                                Remaining{' '}
                              </>
                            )
                          ) : (
                            <b> -- </b>
                          )}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Clinician Review Credits</Typography>
                      <SkeletonLoader
                        isloading={progressLoading || programLoading || error !== null}
                      >
                        <Typography variant="body1">
                          {`${
                            data?.clinicianRemainingCredits >= 0
                              ? data?.clinicianRemainingCredits
                              : '--'
                          } of 
                      ${
                        data?.clinicianTotalCredits >= 0 ? data?.clinicianTotalCredits : '--'
                      } Credits Left`}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                  </>
                ),
              },
              {
                childElements: (
                  <>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="subtitle1">Member’s Vision</Typography>
                      {!visionEdit ? (
                        <div style={{ margin: '10px 0' }}>
                          <SkeletonLoader
                            isloading={visionLoading || progressLoading || error !== null}
                          >
                            <Typography variant="body2">Vision</Typography>
                            <Typography variant="body1">{visionStatement || '--'}</Typography>
                          </SkeletonLoader>
                        </div>
                      ) : (
                        <>
                          <TextField
                            sx={{ margin: '10px 0' }}
                            fullWidth
                            id="outlined-multiline-static"
                            label="Vision Statement"
                            name="visionStatement"
                            placeholder="Please restrict vision statement to 150 character."
                            value={visionStatement}
                            multiline
                            onChange={changeVision}
                            rows={2}
                            error={visionTextLimitReached}
                            // defaultValue="Add your notes for an appointment here. "
                          />
                          {visionTextLimitReached && (
                            <Typography variant="body2" sx={{ color: 'red !important' }}>
                              Please restrict vision statement to 150 character.
                            </Typography>
                          )}
                        </>
                      )}
                      {userType == AUTH_TYPE.COACH && (
                        <SectionFooter
                          buttonInputProps={
                            <>
                              {visionEdit && (
                                <Button
                                  variant="outlined"
                                  sx={{
                                    height: 'fit-content',
                                  }}
                                  onClick={cancelVision}
                                >
                                  CANCEL
                                </Button>
                              )}
                              <Button
                                variant={visionEdit ? 'contained' : 'outlined'}
                                sx={{
                                  height: 'fit-content',
                                }}
                                onClick={editVision}
                                //validations --> if entered text and db text same && error state is true
                                disabled={
                                  (visionEdit && data?.visionStatement === visionStatement) ||
                                  visionTextLimitReached
                                }
                              >
                                {/* action button text changes to "SAVE", "EDIT", "ADD" */}
                                {visionEdit
                                  ? 'SAVE'
                                  : data?.visionStatement == '' ||
                                    data?.visionStatement == undefined ||
                                    data?.visionStatement == null
                                  ? visionActionButtonText
                                  : 'EDIT VISION STATEMENT'}
                              </Button>
                            </>
                          }
                        />
                      )}
                    </Grid>
                  </>
                ),
              },
            ]}
          />

          <SectionMain
            headerElement={{
              // headerText: `Program Focus`,
              childElements: (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6" className={classes.headerText} style={{ width: '70%' }}>
                    Program Focus
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '35%',
                    }}
                  >
                    <Typography variant="h6" className={classes.headerText}>
                      Stage of Change
                    </Typography>
                    <Typography variant="h6" className={classes.headerText}>
                      Achievement
                    </Typography>
                  </div>
                </div>
              ),
            }}
            bodyElement={[
              {
                childElements: (
                  <ProgramFocusComponent
                    memberId={memberId}
                    appointmentId={''}
                    userType={userType}
                  />
                ),
              },
            ]}
          />
          <SectionMain
            headerElement={{
              headerText: `Heart Habits`,
            }}
            bodyElement={[
              {
                childElements: (
                  <HeartHabit memberId={memberId} appointmentId={''} userType={userType} />
                ),
              },
            ]}
          />

          <Box className={classes.flexWrapper}>
            <Tabs
              classes={{
                root: classes.customTabRoot,
                indicator: classes.customTabIndicator,
              }}
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              // centered
              variant="fullWidth"
            >
              <Tab label={t('Weekly')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
              <Tab label={t('Overall')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <SectionMain
            headerElement={{
              headerText: `Program Progress`,
              action: (
                <Grid container className={classes.weekController}>
                  {ppData && (
                    <Grid
                      item
                      lg={10}
                      md={10}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Typography variant="body1" style={{ textAlign: 'center' }}>
                        Viewing Week {currentWeek} : {computeDateRange()}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item lg={1} md={1}>
                    <Button onClick={() => changeWeek(-1)} disabled={currentWeek === 1}>
                      <ChevronLeft />
                    </Button>{' '}
                  </Grid>
                  <Grid item lg={1} md={1}>
                    <Button onClick={() => changeWeek(1)}>
                      <ChevronRight />
                    </Button>
                  </Grid>
                </Grid>
              ),
            }}
            bodyElement={[
              {
                sx: {
                  border: 'unset !important',
                  boxShadow: 'unset !important',
                  background: 'transperent !important',
                },
                childElements: (
                  <>
                    <TabPanel value={value} index={0}>
                      <ProgramProgress />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div style={{ position: 'relative' }}>
                        {/* <div className={commonClasses.overlay}>Coming Soon!</div> */}
                        <Typography>Coming Soon!</Typography>
                      </div>
                    </TabPanel>
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    </MainContainer>
  );
};

export default Program;
