export const ECG_review_page = {
  ERRORMESSAGE: 'This is requried.',
  MRNVALUE: 'MRN',
  DATEOFBIRTH: 'DOB',
  AGE: 'AGE',
  SEX: 'SEX',
  HEIGHT: 'HEIGHT',
  WEIGHT: 'WEIGHT',
  FIRST_DEGREE: '1st Degree',
  SECOND_DEGREE_ONE: '2nd Degree Mobitz I',
  THIRD_DEGREE: '3rd Degree',
  SECOND_DEGREE_TWO: '2nd Degree Mobitz II',
  NONE: 'None',
  FRENCH: 'French',
  SWEDISH: 'Swedish',
  ENGLISH: 'English',
  FINNISH: 'Finnish',
  VIETNAMESE: 'Vietnamese',
  IPREFERNOTTOSAY: 'I prefer not to say',
  M: 'MALE',
  F: 'FEMALE',
  ARABIC:'Arabic',
  GERMAN:'German',
  DUTCH:'Dutch',
  SOLVAK:'Slovak',
  DENMARK:'Denmark',
  NORWEGIAN:'Norwegian',
  HUNGARIAN:'Hungarian',
  CZECH:'Czech',
  SPANISH:'Spanish',
  GERMANIC:'Germanic',
 PORTUGUESE :'Portuguese'
};

export const ECG_review_confirmationmodal = {
  REVIEW_NOTSUBMITTED: 'Review Not Submitted',
  ARE_YOU_SURE_YOU_WANTTOLEAVE: 'Are you sure you want to leave?',
  PROGRESS_DESCRIPTION:
    'If you do, all progress will be lost and ECG will return to your worklist.',
  PENDING_REQUEST_SUBMITTED: 'Pending request has been submitted successfully',
  CHANGES_NOT_SAVED: 'Changes Have Not Been Saved',
  PENDING_DESCRIPTION: 'If you do , the current sections will not be saved',
  YOU_CANNOT_EDIT_ALERT: "Pending request's 5 mins window is over. You can not edit this request.",
  READ_THE_ECG:"Review the complete 6L Strip before proceeding"
};
