import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Paper, Grid } from "@mui/material";
import { useParams ,useHistory} from "react-router-dom";
import EkgRecording from "components/chart/EkgRecording";
import { emptyPatientData, getPatientData } from "store/actions";
import Patientdetailsdata from "screens/Ecgscreens/Patientdetailsdata";
import EcgHeartratedata from "screens/Ecgscreens/EcgHeartratedata";
import { fetchPatient } from "utilities/ApiUrls";
import EcgCompareModal from "./EcgCompareModal";
import { formatDate, timeFormat } from "utilities/Utilities";
import EcgLoader from "components/Shared/EcgLoader";
import { ECG_COMPARE_HEADER } from "./EcgCompareConstants";
import { calcRowSeconds } from "components/Utilities";
import * as Lib from "../../../constants/RecordingConstants"
import { getEKGAlgorithmDeterminationDisplayText } from "constants/recordingMaps";

const EcgCompare = () => {
  const { id, reviewStatus } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  let contentHeight = 650;
  const mainContentWidth = 730;
  const MIN_SECONDS = 1;
  let key = 1;
  const rowSeconds =
  calcRowSeconds(mainContentWidth) > 0
    ? calcRowSeconds(mainContentWidth)
    : MIN_SECONDS;
const ekgWidth = rowSeconds * Lib.PX_PER_SECOND;

  // Data from Redux
  const ecgvalues = useSelector((state) => state.ekgReview?.secondaryvalues);
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const recordingSample = useSelector(
    (state) => state.ekgReview.recordingSample,
  );
  const comparerecordingSample = useSelector(
    (state) => state.ekgReview.comparerecordingsSample,
  );
  const userCountry = useSelector((state) => state.user.profile.country);

  useEffect(() => {
    // Fetch patient data initially
    dispatch(getPatientData(id, reviewStatus));
    return () => {
      dispatch(emptyPatientData());
    };
  }, [id, reviewStatus, dispatch]); // Only runs when `id` or `reviewStatus` changes

  useEffect(() => {
    // Fetch additional patient data only if `userID` exists
    if (patientData.userID) {
      dispatch(fetchPatient(patientData.userID, "CONSUMER"));
    }
  }, [patientData.userID, dispatch]);

  

  // Extracting the top five recordings from comparerecordingsample
  const topFiveRecordings = comparerecordingSample?.recordings
    ?.filter((recordings) => recordings.id !== patientData?.recordingPublicId)
    .slice(0, 5);
  const aiDeterminationDetails = getEKGAlgorithmDeterminationDisplayText(
    patientData.interpretation,
    patientData.algorithmPackage,
    patientData.heartRate,
  );


  const handleNavigation = () => {
   
    history.push(`/clinician/ecglist/review/${patientData.id}/${reviewStatus}`);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        {recordingSample ? (
          <Grid spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                marginBottom: "10px",
                gap: "16px",
              }}
            >
              <Patientdetailsdata
                labledata={ECG_COMPARE_HEADER.REPORT_ID}
                keydata={patientData.reportId}
              />
              <Patientdetailsdata
                labledata={ECG_COMPARE_HEADER.AI_DETERMINATION}
                keydata={aiDeterminationDetails}
              />
              <EcgHeartratedata heartrate={patientData.heartRate} />
              <Patientdetailsdata
                labledata={ECG_COMPARE_HEADER.DATE_RECORDED}
                keydata={
                  patientData?.recordedAt
                    ? `${formatDate(patientData?.recordedAt, userCountry)} ${timeFormat(
                        patientData?.recordedAt,
                      )}`
                    : null
                }
              />
            </Box>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  position: "relative",
                }}
              >
                {recordingSample && (
                  <EkgRecording
                    key={key++}
                    height={400}
                    width={1500}
                    recording={recordingSample}
                    recordingSample={recordingSample}
                    rowSeconds={15}
                    calpulse={true}
                    inverted={recordingSample.inverted}
                    is6l={Object.keys(recordingSample.samples).length === 6}
                    duration={patientData?.duration}
                  />
                )}
              </Paper>
            </Box>
            <EcgCompareModal
              topFiveRecordings={topFiveRecordings}
              handleNavigation={handleNavigation}
              patientData={comparerecordingSample}
            />
          </Grid>
        ) : (
          <EcgLoader />
        )}
      </Box>
    </>
  );
};

export default EcgCompare;
