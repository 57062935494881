/* eslint-disable */
import moment from 'moment';
import { TeleKardiaAPI } from '../../Axios/axios';
import { getRegion, getToken } from 'auth/AuthUtilities';
import * as actionTypes from './actionTypes';
import { getAvailabilities } from './calendar';
import { LOCAL_STORAGE_UNIFIED_PORTAL_REGION } from 'constants/app';

export const getAppointmentsStart = () => {
  return {
    type: actionTypes.GET_APPOINTMENTS_START,
  };
};

export const getAppointmentsSuccess = (data) => {
  return { type: actionTypes.GET_APPOINTMENTS_SUCCESS, payload: data };
};

export const getAppointmentsFail = (error) => {
  return { type: actionTypes.GET_APPOINTMENTS_FAIL, error: error };
};
export const getAppointments = (teamId, clinicianId, params) => {
  const regionToken =  localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION) || "";
  return (dispatch) => {
    dispatch(getAppointmentsStart());

    if (regionToken === "us" ) {
      const token = getToken();
      if (!token) {
        console.error("Authorization token is missing");
        return dispatch(getAppointmentsFail("Authorization token is missing"));
      }

      TeleKardiaAPI.get(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments`, {
        headers: { Authorization: `Bearer ${token}` },
        params: params || {},
      })
        .then((res) => {
          if (res.status !== 200 || !res?.data?.result?.appointments) {
            return dispatch(getAppointmentsFail(res.data['detailed_error'] || "Invalid response structure"));
          }

          const fetchedAppointments = res.data.result.appointments.map((appointment) => ({
            id: appointment.appointmentId,
            patientId: appointment.patient?.patientId,
            title: appointment.patient,
            status: appointment.appointmentStatusId,
            start: moment(appointment.scheduledStartTime).toDate(),
            end: moment(appointment.scheduledEndTime).toDate(),
            statusName: appointment.appointmentStatusDisplayName,
            ekgId: appointment.ekgResults?.[0]?.ekgRecordId || null,
          }));

          dispatch(getAppointmentsSuccess(fetchedAppointments));
        })
        .catch((err) => {
          console.error("Error fetching appointments:", err);
          dispatch(getAppointmentsFail(err.message || "Unknown error occurred"));
        });
    } else {
      console.warn("Region token does not match 'us'");
      dispatch(getAppointmentsFail("Unsupported region"));
    }
  };
};

export const deleteAppointmentStart = () => {
  return {
    type: actionTypes.DELETE_APPOINTMENT_START,
  };
};

export const deleteAppointmentSuccess = (id) => {
  return {
    type: actionTypes.DELETE_APPOINTMENT_SUCCESS,
    payload: id,
  };
};

export const deleteAppointmentFail = (error) => {
  return {
    type: actionTypes.DELETE_APPOINTMENT_FAIL,
    error: error,
  };
};

export const deleteAppointment = (
  id,
  clinicianid,
  teamId,
  queryParams,
  appointmentqueryParams,
  component,
  dashboardmetricsParams,
  deleteparams,
) => {
  return (dispatch) => {
    const regionToken=getRegion()
    dispatch(deleteAppointmentStart());
    regionToken && regionToken==="us" && TeleKardiaAPI.delete(`/api/v1.1/teams/${teamId}/clinicians/${clinicianid}/appointment/${id}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: deleteparams,
    })
      .then((res) => {
        dispatch(deleteAppointmentSuccess(id));
        if ('detailed_error' in res.data)
          dispatch(deleteAppointmentFail(res.data['detailed_error']));

        if (component === 'dashboardmiddlesection') {
          dispatch(getAppointments(teamId, clinicianid, appointmentqueryParams));
          dispatch(getCliniciansAppointments(teamId, clinicianid, dashboardmetricsParams));
        } else dispatch(getAvailabilities(clinicianid, teamId, queryParams));
      })
      .catch((err) => {
        dispatch(deleteAppointmentFail(err));
      });
  };
};
export const getPatientDetailsStart = () => {
  return {
    type: actionTypes.GET_PATIENTDETAILS_START,
  };
};
export const getPatientDetailsSuccess = (data) => {
  return { type: actionTypes.GET_PATIENTDETAILS_SUCCESS, payload: data };
};

export const getPatientDetailsFail = (error) => {
  return { type: actionTypes.GET_PATIENTDETAILS_FAIL, error: error };
};

export const getPatientDetails = (teamId, clinicianid, appointmentId, params) => {
  return (dispatch) => {
    const regionToken=getRegion()
    dispatch(getPatientDetailsStart());
    regionToken && regionToken ==="us" && TeleKardiaAPI.get(
      `/api/v1.1/teams/${teamId}/clinicians/${clinicianid}/appointments/${appointmentId}/patient-detail`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data) {
          dispatch(getPatientDetailsFail(res.data.detailed_error));
        } else dispatch(getPatientDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getPatientDetailsFail(err));
      });
  };
};

export const getCliniciansAppointmentsStart = () => {
  return {
    type: actionTypes.GET_CLINICIANS_APPOINTMENTS_START,
  };
};
export const getCliniciansAppointmentsSuccess = (data) => {
  return { type: actionTypes.GET_CLINICIANS_APPOINTMENTS_SUCCESS, payload: data };
};

export const getCliniciansAppointmentsFail = (error) => {
  return { type: actionTypes.GET_CLINICIANS_APPOINTMENTS_FAIL, error: error };
};
export const getCliniciansAppointments = (teamId, clinicianId, params) => {
  return (dispatch) => {
    const regionToken= localStorage.getItem(LOCAL_STORAGE_UNIFIED_PORTAL_REGION) || "";
    dispatch(getCliniciansAppointmentsStart());
    regionToken && regionToken ==="us" && TeleKardiaAPI.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/dashboard-metrics`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: params,
    })
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(getCliniciansAppointmentsFail(res.data['detailed_error']));
        else {
          dispatch(getCliniciansAppointmentsSuccess(res.data.result));
        }
      })
      .catch((err) => {
        dispatch(getCliniciansAppointmentsFail(err));
      });
  };
};

export const getClinicianAvailabilityStart = () => {
  return {
    type: actionTypes.GET_CLINICIAN_AVAILABILITY_START,
  };
};
export const getClinicianAvailabilitySuccess = (data) => {
  return { type: actionTypes.GET_CLINICIAN_AVAILABILITY_SUCCESS, payload: data.result };
};

export const getCliniciansAvailabilityFail = (error) => {
  return { type: actionTypes.GET_CLINICIAN_AVAILABILITY_FAIL, error: error };
};
export const getClinicianAvailability = (clinicianId, teamId, params) => {
  return (dispatch) => {
    const regionToken= getRegion();
    dispatch(getClinicianAvailabilityStart());
    regionToken && regionToken ==="us" && TeleKardiaAPI.get(
      `/api/v1/teams/${teamId}/clinicians/${clinicianId}/dashboard-metrics/availability`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(getCliniciansAvailabilityFail(res.data['detailed_error']));
        else dispatch(getClinicianAvailabilitySuccess(res.data));
      })
      .catch((err) => {
        dispatch(getCliniciansAvailabilityFail(err));
      });
  };
};
