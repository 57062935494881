import SkeletonLoader from 'components/Loading/SkeletonLoader';
import SectionBody from 'layouts/ScreenLayout/sections/SectionBody';
import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material';
import RenderSelectedInputs from 'components/mui/formElements/RenderSelectedInputs';
import { useSelector } from 'react-redux';
import { AV_BLOCKS } from 'components/ekg/constants/EcgInboxConstants';
import RenderSelectedCheckBox from 'components/mui/formElements/RenderSelectedCheckBox';
import ConfirmationModalScreen from './ConfirmationModalScreen';
import { ECG_review_confirmationmodal } from './Ecgconstants';
import { LOCAL_STORAGE_SECONDARY_VALUES } from 'constants/app';

const Secondaryobervationscreen = ({
  selectedOptions,
  setSelectedOptions,
  errorMessage,
  handleAvBlocksChange,
  avblocks,
  setAvBlocks,
  disabled=false
}) => {
  const ecgReviewCompletedData = useSelector((state) => state.ekgReview?.reviewDetail);
  const storedSecondaryValues = useSelector((state) => state.ekgReview?.secondaryvalues);
  let filteredvalues = Object.values(avblocks).map(val=>val?true:false).includes(true)

const [open, setOpen] = useState(false);

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleOptionClick = (event) => {
    if (disabled) {
      event.preventDefault();
      handleOpenPopup();
    }
  };

  
  return (
    <>
    <SectionBody sx={{backgroundColor:"#FFF !important" , borderRadius:"12px !important"}}
      headerText="Secondary Observations"
      childElements={
        <Stack direction={'row'} columnGap={1}>
          <SkeletonLoader>
            <Box>
              <Box sx={{ paddingBottom: '16px' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="body1">PVCs</Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box onClick={handleOptionClick}>
                  <RenderSelectedInputs 
                    values={['None', '1 PVC', '2+ PVCs']}
                    setSelectedOptions={setSelectedOptions}
                    completedValue={ecgReviewCompletedData.pvcs ? ecgReviewCompletedData?.pvcs : storedSecondaryValues?.pvcData}
                    name="pvcData"
                    disabled={disabled}
                  />
                </Box>
                {selectedOptions.pvcData?.length > 0 ? null : (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>

              <Box sx={{ paddingBottom: '16px' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="body2">PACs </Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box onClick={handleOptionClick}>
                  <RenderSelectedInputs 
                    values={['None', '1 PAC', '2+ PACs']}
                    setSelectedOptions={setSelectedOptions}
                    completedValue={ecgReviewCompletedData.pacs ? ecgReviewCompletedData?.pacs : storedSecondaryValues?.pacData }
                    name="pacData"
                    disabled={disabled}
                  />
                </Box>
                {selectedOptions.pacData?.length > 0 ? null : (
                  <Typography
                    color="red"
                    variant="body2"
                    style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>
              <Box sx={{ paddingBottom: '16px' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="body2">QRS</Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box onClick={handleOptionClick}>
                  <RenderSelectedInputs
                    values={['Normal', 'Wide QRs']}
                    setSelectedOptions={setSelectedOptions}
                    completedValue={ecgReviewCompletedData.qrs ? ecgReviewCompletedData?.qrs : storedSecondaryValues?.qrsData  }
                    name="qrsData"
                    disabled={disabled}
                  />
                </Box>
                {selectedOptions.qrsData?.length > 0 ? null : (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>

              <Box>
                <Box style={{ display: 'flex' }}>
                  <Typography>Av Blocks</Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} onClick={handleOptionClick}>
                  <RenderSelectedCheckBox
                    completedReviewData={ecgReviewCompletedData.avBlocks ? ecgReviewCompletedData?.avBlocks : storedSecondaryValues?.avBlocks  }
                    handleChange={handleAvBlocksChange}
                    blocks={AV_BLOCKS}
                    disabled={disabled}
                  />
                </Box>
              </Box>

              {filteredvalues ? null : (
                <Typography
                  color="error"
                  variant="body2"
                  style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                >
                  {errorMessage}
                </Typography>
              ) }
            </Box>
          </SkeletonLoader>
        </Stack>
      }
    />

      {open && 
      <ConfirmationModalScreen
      sucessheading={ECG_review_confirmationmodal.READ_THE_ECG}
      handleClosePopup={handleClosePopup} 
      />
      }
    {/* <Dialog open={open} onClose={handleClosePopup}>
    <DialogTitle>Action Disabled</DialogTitle>
    <DialogContent>
      <DialogContentText>
        You need to scroll to the end of the page before interacting with these options.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClosePopup} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog> */}
  </>

    
  );
};

export default Secondaryobervationscreen;
